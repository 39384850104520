.mainScreen .tasks-widget {
  width: $doublewidth;
  align-items: center;
  //min-height: 626px;
  //min-height: 626px;
  height: fit-content;
  /*
  &.full{
    background: transparent;
    height: -webkit-fill-available;
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 25px;
    background: transparent;
    box-shadow: none;
    min-height: -webkit-fill-available;
    .header{
      background: $white;
      border-radius: 16px;
      box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.1);
      .whiteButton{
        padding: 0 30px 0 10px;
        color: $blackclassic;
        img{
          margin-right: 15px;
          width: 24px;
        }
      }
    }
    .body{
      overflow-y: auto;
      box-shadow: none;
      height: -webkit-fill-available;
      > .state{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 236px;
        width: -webkit-fill-available;
        &:first-child .stateName, &:first-child .whiteState, &:first-child .task{
          margin-left: 0;
        }
        &:last-child .stateName, &:last-child .whiteState, &:last-child .task{
          margin-right: 0;
        }
        .stateName{
          width: -webkit-fill-available;
          margin: 17px;
          height: 60px;
          background: $white;
          border-radius: 10px;
          display: flex;
          align-items: center;
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $black;
          text-transform: capitalize;
          position: relative;
          > img{
            margin-left: 9px;
            margin-top: 10px;
          }
          .number{
            font-style: normal;
            font-size: 15px;
            color: #b5b5b5;
            font-weight: 600;
            padding: 0 24px 0 10px;
            right: 0px;
            background: $white;
          }
          .state{
            font-style: normal;
            font-size: 15px;
            width: 100%;
            color: $black;
            font-weight: 600;
            padding: 0 24px 0 10px;
            right: 0px;
            background: $white;
          }
        }
        .whiteState{
          width: -webkit-fill-available;
          margin: 17px;
          height: -webkit-fill-available;
          background: $white;
          border-radius: 16px;
          box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.1);
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          span{
            font-weight: 500;
            font-style: normal;
            font-size: 14px;
            opacity: 0.5;
            line-height: 16px;
            color: $black;
            width: 123px;
            text-align: center;
          }
        }

      }
    }
  }
  */
  &.empty{
    min-height: 600px;
    max-height: 680px;
  }
  &.full{
    position: absolute;
    margin-bottom: 25px;
    min-height: calc(100% - 50px);
    height: fit-content;
    //height: calc(100% - 50px);
  //  min-height: calc(100% - 50px);
    //height: fit-content;
    //    height: 100%;
    .body{
      min-height: calc(100vh - 75px);
      > .state{
        padding-bottom: 10px;
        > .stateName{
          position: sticky;
          top: 25px;
        }
      }
    }
    &::after{
      content: '';
      position: absolute;
      bottom: -25px;
      right: 0;
      left: 0;
      height: 25px;
      background: transparent;
    }
    .header{
      min-height: 96px;
      height: 96px;
    }
    .header .whiteButton{
      padding: 0 30px 0 10px;
      color: $blackclassic;
      img {
        margin-right: 15px;
        width: 24px;
      }
    }
  }
  //&.preview{
  .body{
    min-height: 500px;
    height: calc(100% - 74px);
    //overflow-x: auto;
    //overflow-y: hidden;
    width: calc(100% - 30px);
    >.state{
      display: flex;
      position: relative;
      flex-direction: column;
      min-width: 256px;
      max-width: 256px;
      background: $gray;
      margin: 0 3px;
      margin-bottom: 15px;
      border-radius: 10px;
      height: auto;
      /*box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.15);*/
      &:first-child{
      }
      &:last-child{
      }
      .stateName{
        //width: -webkit-fill-available;
        z-index: 3;
        overflow: unset;
        margin: 17px 17px 12px 17px;
        min-height: 60px;
        max-height: 60px;
        background: $white;
        border-radius: 10px;
        box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.1);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $black;
        text-transform: capitalize;
        img{
          margin-left: 9px;
          margin-top: 10px;
        }
        .number{
          font-style: normal;
          margin-bottom: -1px;
          font-size: 15px;
          color: #b5b5b5;
          font-weight: 600;
          padding: 0 24px 0 10px;
          background: $white;
        }
        .state{
          margin-bottom: -1px;
          width: 100%;
          font-style: normal;
          font-size: 15px;
          font-weight: 600;
        }

      }
      .whiteState{
        width: -webkit-fill-available;
        margin: 17px;
        height: -webkit-fill-available;
        background: transparent;
        border-radius: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
        justify-content: flex-start;
        span{
          font-weight: 500;
          font-size: 14px;
          opacity: 0.5;
          line-height: 16px;
          color: $black;
          width: 123px;
          text-align: center;
        }
      }
    }
  }
  //}
  .task{
    background: $white;
    margin: 0px 18px 10px 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 18px;
    position: relative;
    width: -webkit-fill-available;
    box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.15);
    cursor: grab;
    &.archived{
      max-height: 56px;
      flex-direction: row;
      align-items: center;
      position: relative;
      margin-top: auto;
      margin-bottom: 20px;
      cursor: pointer;
      span{
        margin-left: 12px;
        font-weight: 600;
        font-size: 15px;
        color: #09323A;
        letter-spacing: 0;
      }
      .number{
        position: absolute;
        right: 20px;
        font-weight: 600;
        font-size: 15px;
        color: #B4C4C7;
        letter-spacing: 0;
      }
    }
    &:active{
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
    .top{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      min-height: 25px;
      justify-content: center;
      .tag{
        background: $taggreen;
        border-radius: 6px;
        color: $white;
        letter-spacing: 0;
        font-weight: bold;
        font-style: normal;
        font-size: 13px;
        width: fit-content;
        padding: 4px 7px 3px 7px;
        &.preview{
          position: absolute;
          width: 3px;
          height: 100%;
          margin: 0;
          left: -20px;
          padding: 0;
          color: transparent;
        }
      }
      .view{
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 30%;
      }

      .options{
        display: block;
        position: absolute;
        background: $white;
        border: 1px solid $standardborder;
        right: 0px;
        top: 30px;
        border-radius: 10px;
        width: 159px;
        z-index: 40;
        &:hover {
          display: block;
        }
        > div{
          padding: 17px 20px;
          color: $blackclassic;
          font-size: 15px;
          letter-spacing: 0.13px;
          font-weight: 600;
          font-style: normal;
          cursor: pointer;
          img{
            margin-right: 20px;
          }
          &:hover{
            background: #eef2f36b;
          }
        }
      }
    }
    .edit{
      position: absolute;
      right: 15px;
      top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 30px;
      width: 30px;
      z-index: 3;
      transition: 0.3s;
      border-radius: 6px;
      &:hover{
        background: #0000000a;

      }
    }
    .name{
      &.preview{
        margin-top: 0px;
      }
      width: 150px;
      margin-top: 20px;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      color: $black;
      letter-spacing: 0;
      line-height: 22px;
      max-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .picture {
      display: flex;
      justify-content: center;
      width: 188px;
      pointer-events: none;
      max-width: 100%;
      max-height: 86px;
      margin-top: 15px;
      background: $gray;
      border-radius: 6px;
      border: 1px solid $chatine;
      img{
        border-radius: 5px;
        width: 100%;
      }
    }
    .bottom{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .users{
        display: flex;
        img{
          pointer-events: none;
          height: 24px;
          width: 24px;
          border-radius: 5px;
          margin-right: 5px;
        }
      }
      .deadline{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          opacity: 0.6;
        }
        span{
          font-weight: 600;
          font-style: normal;
          color: $black;
          margin-left: 8px;
          opacity: 0.5;
          letter-spacing: 0;
          font-size: 13px;
        }

      }
    }
  }
}
.modal.double{
  overflow: hidden;
  cursor: default;
  div.doubleModal{
    overflow: auto;
    background: transparent;

    > .header{
      position: fixed;
      justify-content: center;
      right: 376px;
      left: 0;
      z-index: 8;
      background: $white;
      width: calc(100% - 376px);
      border-bottom: 1px solid $standardborder;
      &.unique{
        right: 0px;
        width: 100%;
      }
      .blueButton{
        position: absolute;
        top:0;
        bottom: 0;
        margin: auto;
        right: 14px;
      }
    }
    > .secondary{
      position: fixed;
      justify-content: center;
      right: 0;
      z-index: 5;
      height: 100%;
      background: $white;
      width: 375px;
      border-left: 1px solid $standardborder;
      .header{
        border-top: 0px;
      }
      .open.inline .body{
        flex-direction: column-reverse;
      }
      .body{
        height: 100%;
        max-height: 100%;
      }
    }
    /*
      img {
        background: $yellowclassic2;
        width: 20px;
        padding: 10px;
        border-radius: 20px;
        cursor: pointer;
      }
    */
    .alledworkers{
      width: 849px;
      position: absolute;
      background: $white;
      //padding: 40px 35px;
      margin: auto;
      left: 0;
      right: 375px;
      top: 125px;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid $standardborder;
      box-shadow: 5px 20px 35px 10px $shadowmodal;
      &.unique{
        right: 0;
      }
      .header{
        display: flex;
        justify-content: space-between;
        min-height: 96px;
        height: 96px;
        align-items: center;
      }
    }

  }
}
.newTask, .viewTask {
  &::after{
    content: '';
    position: absolute;
    bottom: -25px;
    height: 25px;
    width: 100%;
    background: transparent;
  }
  .marketplace{
    padding: 0;
  }
  .userslist .list{
    flex-wrap: wrap;
  }

}
.viewTask{
  display: flex;
  width: 621px;
  background: $white;
  padding: 40px 35px 0px 35px;
  margin: auto;
  left: 0;
  right: 375px;
  top: 125px;
  flex-direction: column;
  position: absolute;
  border-radius: 16px;
  border: 1px solid $standardborder;
  box-shadow: 5px 20px 35px 10px $shadowmodal;
  &.unique{
    right: 0px;
  }
  .cover{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: -40px -35px 0 -35px;
    height: 150px;
    background: #0000001a;
    width: calc(100% + 70px);
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    .close{
      position: absolute;
      top: 30px;
      right: 30px;
      padding: 9px;
      border-radius: 16px;
      background: #4C7077;
      cursor: pointer;
      z-index: 3;
      &:hover {
        background: #06242a40;
      }
    }
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
      background: #4E81891a;
      background-image: linear-gradient(180deg, rgba(78,129,137,0.10) 0%, rgba(14,38,42,0.35) 100%);
    }
    .image{
      width: inherit;
    }
  }
  .title{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 60px;
    textarea.name{
      width: 407px;
      resize: none;
      font-weight: bold;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 36px;
      color: $black;
      letter-spacing: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      outline: none;
      border: 0;
      height: 36px;
      margin-top: 10px;
      &::placeholder{
        color: $black;
        opacity: 0.4;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 36px;

      }
    }
    span.state{
      padding: 6px 8px;
      font-size: 13px;
      color: $white;
      margin-top: 15px;
      font-weight: bold;
      letter-spacing: 0;
      border-radius: 4px;
    }
  }
  .subtitle{
    display: flex;
    margin-top: 40px;
    .user{
      height: 40px;
      width: 183px;
      padding: 10px;
      display: flex;
      background: #F7F9FB;
      border-radius: 10px;
      flex-wrap: wrap;
      flex-direction: column;
      img{
        width: 40px;
        margin-right: 12px;
        height: 40px;
        border-radius: 10px;
      }
      span{
        width: -webkit-fill-available;
        margin-bottom: 1px;
        margin-top: 1px;
        color: $blackclassic;
        font-size: 15px;
        letter-spacing: 0.17px;
        font-weight: bold;
        font-style: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 130px;
        &:last-child{
          color: $black;
          margin-bottom: 2px;
          font-size: 13px;
          margin-top: 1px;
          letter-spacing: 0.13px;
          opacity: 0.45;
          font-weight: 500;
          font-style: normal;
        }
      }
    }
    .estimate {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: 40px;
      background: #F7F9FB;
      border-radius: 10px;
      width: 175px;
      padding: 10px;
      margin-left: 15px;
      position: relative;
      cursor: pointer;
      &:hover:before {
        position: absolute;
        pointer-events: none;
        content:"";
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        background: #09323A08;
      }
      .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #EDF1F3;
        border-radius: 10px;
        width: 40px;
        height: 40px;
        margin-right: 12px;
        img{
          height: 16px;
        }
      }
      .switch{
        cursor: pointer;
        position: absolute;
        right: 5px;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      span{
        width: -webkit-fill-available;
        margin-bottom: 1px;
        margin-top: 1px;
        color: $blackclassic;
        font-size: 15px;
        letter-spacing: 0.17px;
        font-weight: bold;
        font-style: normal;
        &:last-child{
          color: $black;
          margin-top: 0px;
          margin-bottom: 2px;
          font-size: 13px;
          letter-spacing: 0.13px;
          opacity: 0.45;
          font-weight: 500;
          font-style: normal;
        }
      }
      .calendar{
        display: none;
        background: white;
        z-index: 10;
        &.open{
          display: flex;
          .edcalendar{
            border: 1px solid $standardborder;
            border-radius: 10px;
            position: absolute;
            background: $white;
            top: 99px;
            z-index: 10;
            top: 70px;
            background: white;
            right: 0px;
            padding: 25px;
          }

        }
      }
      .newlength{
        position: absolute;
        background: $white;
        height: 220px;
        top: 70px;
        z-index: 10;
        left: -72px;
        flex-direction: column;
        align-items: center;
        margin-left: 5px;
        width: 350px;
        border: 1px solid $borderproject;
        box-shadow: 3px 6px 9px 5px #9aafb414;
        border-radius: 10px;
        display: none;
        justify-content: center;
        &.open {
          display: flex;
          height: fit-content;
          padding: 25px;
          .estimation{
            .value{
              font-size: 18px;
              margin-bottom: 15px;
            }
          }
          .times{
            margin-left: 0;
            width: 100%;
          }
        }
        .number{
          margin-top: 10px;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          color: $black;
          line-height: 14px;
          letter-spacing: 0;
          text-align: center;
        }
        .title{
          margin-top: 30px;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          color: $black;
          line-height: 14px;
          letter-spacing: 0;
          text-align: center;
        }
      }
    }
  }
  .ql-toolbar{
    width: calc(100% + 70px);
    margin-left: -35px;
    margin-top: 35px;
    border-top: 1px solid $standardborder;
    border-bottom: 1px solid $standardborder;
  }
  .description{
    min-height: 200px;
    .ql-editor{
      margin-top: 35px;
      padding: 0;
    }
  }
  .userslist{
    margin: 0;
    width: 100%;
    .user {
      margin: 0px 10px 10px 0;

    }
  }
  .namelogo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 69px;
    font-weight: 500;
    font-style: normal;
    font-family: 'Gilroy', Arial, sans-serif;
    position: relative;
    border-top: 1px solid $standardborder;
    border-bottom: 1px solid $standardborder;
    margin: 0 -35px;
    width: 100%;
    padding: 0 35px;
    > img{
      position: absolute;
      left: 20px;
    }
    > span{
      font-weight: 500;
      font-size: 14px;
      color: #09323a99;
      letter-spacing: 0.38px;
      display: flex;
      align-items: center;
      img{
        margin-right: 15px;

      }
    }
    .input{
      width: 300px;
      margin-left: 50px;
      border: none;
      &::placeholder{
        font-weight: 500;
        font-style: normal;
        opacity: 0.55;
        color: $blackclassic;
        letter-spacing: 0;
        font-size: 15px;
      }
    }
    .search{
      right: 15px;
      margin-left: auto;
      width: 340px;
      height: 38px;
      background: $gray;
      border-radius: 24px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0.38px;
      //opacity: 0.6;
      border: none;
      font-size: 14px;
      padding-left: 12px;

      &::placeholder{
      //  margin-left: 12px;
      }
      &:focus + .listsearch{
        display: block;
      }

    }
    .tasklogo{
      display: flex;
      align-items: center;
      .logo{
        position: absolute;
        right: 20px;
        height: 40px;
        width: 80px;
        opacity: 0.85;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
      }
      .close{
        cursor: pointer;
        position: absolute;
        right: 12px;
        border-radius: 20px;
        background: $footerline;
        box-shadow: 0 4px 7px 0 #47656b66;
        border-radius: 1px;
        padding: 5px;
        border-radius: 20px;
        margin-top: -35px;
      }
    }
    .listsearch{
      display: none;
      z-index: 2;
      top: 69px;
      left: 0px;
      position: absolute;
      border: 1px solid $borderproject;
      box-shadow: 4px 10px 15px 5px #9aafb426;
      background: #fff;
      border-radius: 0 0 10px 10px;
      &:hover{
        display: block;
      }
      .title{
        min-height: 0px;
        margin-left: 32px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        line-height: 14px;
        color: $blackclassic;
        letter-spacing: 0;
      }
      .pictures{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding: 30px;
        .picture{
          margin: 7px;
          height: 53px;
          width: 106px;
          opacity: 0.85;
          border-radius: 10px;
          object-fit: cover;
          object-position: center;
          &:hover:before {
            position: absolute;
            content:"";
            height: 100%;
            width: 100%;
            top:0;
            left:0;
            background: #09323A40;
          }
          &.selected{
            border: 2px solid $blueclassic;
            box-shadow: 3px 7px 15px 0 #0ac9ef4d;
          }
        }
      }

    }
  }

  .technologies{
    border-top: 0px solid transparent;
    margin-bottom: 30px;
    .newtechnology{
      margin-top: 90px;
      bottom: 90px;
    }
    .tech{
      border: 1px solid transparent;
      .inner{
        background: #F7F9FB;
        border-radius: 10px;
        .name{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

        }
      }
    }
  }
  .header.sub{
    justify-content: flex-start;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    margin-top: 40px;
    min-height: 60px;
    max-height: 60px;
    img{
      margin-left: 0;
      margin-right: 16px;
    }
    span{
      font-size: 15px;
      width: auto;
      color: $blackclassic;
      letter-spacing: 0;
    }
    .number{
      right: 0;
      position: absolute;
      opacity: 0.6;
      font-size: 14px;
      letter-spacing: 0;
    }
  }
  .files{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .image{
      width: 140px;
      height: 110px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f7f9fbd6;
      border-radius: 10px;
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      position: relative;
      &:after {
        position: absolute;
        pointer-events: none;
        content:"";
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        border-radius: 10px;
        background: #264a511a;
      }
      &:hover{
        &:after {
          position: absolute;
          pointer-events: none;
          content:"";
          height: 100%;
          width: 100%;
          top:0;
          left:0;
          border-radius: 14px;
          background: #264a5133;
        }
      }
      img{
        object-fit: cover;
        margin-left: 10px;
      }
      &.add{
        &:after {
          position: absolute;
          pointer-events: none;
          content:"";
          height: 100%;
          width: 100%;
          top:0;
          left:0;
          border-radius: 10px;
          background: transparent;
        }
        &:hover:after {
          position: absolute;
          pointer-events: none;
          content:"";
          height: 100%;
          width: 100%;
          top:0;
          left:0;
          border-radius: 14px;
          background: #09323A08;
        }
      }
      span{
        opacity: 0.8;
        margin-top: -10px;
        font-weight: 600;
        font-size: 14px;
        color: $black;
        letter-spacing: 0.16px;
        text-align: center;
        line-height: 14px;
      }
      &.empty{
        background: transparent;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{
          width: 120px;
        }
        span{
          margin-top: 10px;
          opacity: 0.4;
          font-weight: 600;
          font-style: normal;
          font-size: 14px;
        }
      }
    }
    .file{
      display: flex;
      position: relative;
      flex-direction: column;
      width: 142px;
      height: 130px;
      background: #f7f9fbd6;
      border: 1px solid $standardborder;
      border-radius: 10px;
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      &:hover:before {
        position: absolute;
        pointer-events: none;
        content:"";
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        background: #09323A08;
      }
      &.add{
        img{
          margin-left: 10px;
        }
        justify-content: center;
        align-items: center;
        position: relative;
        span{
          margin-left: 0px;
          opacity: 0.8;
          font-weight: 600;
          font-size: 14px;
          color: $black;
          letter-spacing: 0.16px;
          text-align: center;
          line-height: 14px;
        }
      }

      &.empty{
        border: 1px solid transparent;
        background: transparent;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{
          width: 120px;
        }
        span{
          margin-top: 15px;
          opacity: 0.4;
          font-weight: 600;
          font-style: normal;
          font-size: 14px;
          width: 100%;
        }
        &::after{
          display: none;
        }
      }
      svg{
        position: absolute;
        transform: rotateZ(-90deg);

      }
      &::after{
        content: '';
        border-bottom: 1px solid $standardborder;
        transform: rotateZ(45deg);
        z-index: 2;
        position: absolute;
        top: -6px;
        right: -13px;
        width: 35px;
        height: 20px;
        background: white;
      }
      align-items: flex-start;
      .filetype{
        height: 49px;
        margin-top: 18px;
        margin-left: 18px;
        //box-shadow: 2px 4px 3px 0 #ced5dc26;
      }
      .fileoptions{
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
        background: $white;
        cursor: pointer;
        &:hover + .list{
          display: flex;
        }
      }
      .list{
        position: absolute;
        display: none;
        width: 150px;
        background: $white;
        color: $black;
        border: 1px solid $borderproject;
        border-radius: 10px;
        box-shadow: 3px 6px 9px 5px rgba(154, 175, 180, 0.08);
        font-weight: 600;
        font-style: normal;
        right: 5px;
        top: 45px;
        z-index: 3;
        &:hover{
          display: flex;
        }
        &::after{
          content: '';
          z-index: -1;
          position: absolute;
          top: -20px;
          bottom: -20px;
          left: -20px;
          right: -20px;
        }
        span{
          overflow: hidden;
          padding: 15px;
          cursor: pointer;
          margin-left: 0;
          width: 100%;
          border-radius: 10px;
          &:hover{
            background: #0000000a;
          }
        }

      }
      span{
        margin-left: 22px;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        &.name{
          margin-top: 15px;
          font-weight: 600;
          font-style: normal;
          font-size: 14px;
          line-height: 14px;
          color: $black;
          letter-spacing: 0.16px;
          &.shadow{
            background: #cccccc9c;
            min-height: 10px;
            border-radius: 10px;
          }
        }
        &.date{
          margin-top: 7px;
          opacity: 0.5;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          line-height: 14px;
          color: $black;
          letter-spacing: 0.15px;
          &.shadow{
            background: #cccccc9c;
            min-height: 10px;
            border-radius: 10px;
          }
        }
      }

    }
    .rightSide{
      width: 375px;
      height: 100%;
      border-right: 1px solid $chatine;
      display: flex;
      flex-direction: column;
      align-items: center;
      .users{
        height: 200px;
      }
      .members{
        width: 100%;
        .header{
          border-top: 0;
          border-bottom: 1px solid $chatine;
          height: 80px;
          background: $white;
          div{
            display: flex;
            justify-content: center;
            align-items: center;
            right: 19px;
            width: 22px;
            height: 12px;
            padding: 5px;
            color: #fff;
            background: #0AC9EF;
            border-radius: 12px;
            font-weight: bold;
            font-style: normal;
            font-size: 13px;
            margin-right: 20px;
          }
          span{
            width: 100%;
            font-size: 14px;
          }
        }
      }

    }
  }
}

.modal div.newTask {
  /*
  display: flex;
  position: absolute;
  background: $white;
  padding: 40px 35px;
  margin: auto;
  left: 0;
  right: 375px;
  top: 125px;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid $standardborder;
  box-shadow: 5px 20px 35px 10px $shadowmodal;
*/

  width: fit-content;
  height: auto;
  position: relative;
  top: 50px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  .header{
    justify-content: flex-start;
    position: relative;
  }
  .modal{
    top: 0;
    left: 0;
    position: fixed;
  }
  .edconfirm{
    position: fixed;
    z-index: 3;
    width: 525px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    box-shadow: 5px 20px 35px 10px rgba(71, 101, 107, 0.15);
    border-radius: 16px;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >.title{
      width: 366px;
      color: $blackclassic;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      text-align: center;
      margin-bottom: 30px;
    }
    .requests{
      width: 98%;
      min-height: 25px;
      border-top: 1px solid $borderproject;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px;
      .edw{
        position: relative;
        margin: 10px;
        width: 114px;
        height: 150px;
        border-radius: 10px;
        border: 1px solid #E8ECED;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .profile{
          border-radius: 10px;
          width: 40px;
          height: 40px;
        }
        .name{
          opacity: 0.8;
          font-size: 14px;
          color: $black;
          letter-spacing: 0.16px;
          text-align: center;
          font-weight: 600;
          font-style: normal;
          line-height: 14px;
        }
        .rate{
          font-weight: bold;
          font-style: normal;
          font-size: 15px;
          margin: 8px;
          color: #05D677;
          letter-spacing: 0;
          text-align: center;
        }
        .close{
          cursor: pointer;
          position: absolute;
          top: -10px;
          right: -10px;
          background: #B4C9CE;
          border-radius: 20px;
          padding: 5px;
        }

      }
    }
    .footer{
      z-index: 6;
      border-top: 1px solid transparent;
      margin: 0 -35px;
      position: sticky;
      bottom: 0px;
      z-index: 5;
      background: #fff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      div{
        width: 145px;
        margin: 10px;
      }
    }
    .resume{
      display: flex;
      width: 90%;
      padding: 20px;
      border-top: 1px solid $borderproject;
      border-bottom: 1px solid $borderproject;

      > div{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        &.cost{
          border-left: 1px solid $borderproject;
        }
        img{
          height: 20px;
        }
        .title{
          margin-top: 10px;
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $blackclassic;
          letter-spacing: 0;
        }
        .description{
          margin-top: 10px;
          font-weight: 600;
          opacity: 0.45;
          font-style: normal;
          font-size: 10px;
          color: $blackclassic;
          letter-spacing: 0;
        }

      }
    }


  }
  img.activefilter{
    position: absolute;
    right: 45px;
    height: 20px;
    width: 20px;
    border: 1px solid $borderproject;
    border-radius: 25px;
    padding: 10px;
    cursor: pointer;
  }
  .body {
    display: flex;
    .leftside{
      width: 645px;
      height: 100%;
      border-right: 1px solid $chatine;
      .namelogo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 69px;
        font-weight: 500;
        font-style: normal;
        font-family: 'Gilroy', Arial, sans-serif;
        position: relative;
        > img{
          position: absolute;
          left: 20px;
        }
        .input{
          width: 300px;
          margin-left: 50px;
          border: none;
          &::placeholder{
            font-weight: 500;
            font-style: normal;
            opacity: 0.55;
            color: $blackclassic;
            letter-spacing: 0;
            font-size: 15px;
          }
        }
        .search{
          right: 15px;
          width: 230px;
          height: 38px;
          background: $gray;
          border-radius: 24px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0.38px;
          font-size: 14px;
          color: #09323a99;
          letter-spacing: 0.38px;
          //opacity: 0.6;
          border: none;
          font-size: 14px;
          padding-left: 20px;

          &::placeholder{
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.38px;
            font-size: 14px;
            color: #09323a99;
            letter-spacing: 0.38px;
          }
          &:focus + .listsearch{
            display: block;
          }

        }
        .tasklogo{
          display: flex;
          align-items: center;
          .logo{
            position: absolute;
            right: 20px;
            height: 40px;
            width: 80px;
            opacity: 0.85;
            border-radius: 10px;
            object-fit: cover;
            object-position: center;
          }
          .close{
            cursor: pointer;
            position: absolute;
            right: 12px;
            border-radius: 20px;
            background: $footerline;
            box-shadow: 0 4px 7px 0 #47656b66;
            border-radius: 1px;
            padding: 5px;
            border-radius: 20px;
            margin-top: -35px;
          }
        }
        .listsearch{
          display: none;
          z-index: 2;
          top: 69px;
          position: absolute;
          border: 1px solid $borderproject;
          box-shadow: 4px 10px 15px 5px #9aafb426;
          width: 645px;
          height: 155px;
          background: #fff;
          border-radius: 0 0 10px 10px;
          &:hover{
            display: block;
          }
          .title{
            margin-left: 32px;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            line-height: 14px;
            color: $blackclassic;
            letter-spacing: 0;
          }
          .pictures{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 30px;
            .picture{
              margin: 7px;
              height: 53px;
              width: 106px;
              opacity: 0.85;
              border-radius: 10px;
              object-fit: cover;
              object-position: center;
              &.selected{
                border: 2px solid $blueclassic;
                box-shadow: 3px 7px 15px 0 #0ac9ef4d;
              }
            }
          }

        }
      }
      .information {
        display: flex;
        border-top: 1px solid $borderproject;
        flex-direction: column-reverse;
        height: 450px;
        .ql-toolbar.ql-snow{
          padding: 8px;
          border-top: 1px solid $borderproject;
        }
        span{
          font-size: 18px;
          color: $blackclassic;
          line-height: 24px;
          letter-spacing: 0;
          font-weight: bold;
          font-style: normal;
        }
        .description{
          margin: 25px;
        }
      }
      .userslist{
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        margin-left: 0;
        margin-bottom: 15px;
        .userslistheader{
          height: 80px;
          .title{
            //font-size: 15px;
          }
        }
        .search{
          width: 212px;
        }
      }

    }
    .rightSide{
      width: 420px;
      height: 100%;
      border-right: 1px solid $chatine;
      display: flex;
      flex-direction: column;
      align-items: center;
      .halfwidth{
        width: 359px;
        padding-bottom: 10px;
        margin-top: 27px;
        display: flex;
        border-bottom: 1px solid #E8ECED;
        .calendar{
          position: absolute;
          display: none;
          padding: 5px;
          background: $white;
          z-index: 2;
          left: -26px;
          top: 114px;
          border-radius: 10px;
          border: 1px solid $borderproject;
          &.open{
            display: flex;
            .edcalendar{
              position: initial;
            }
          }
        }
      }
    }
  }
  .footer{
    position: sticky;
    min-height: 100px;
    bottom: 0px;
    background: $white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .edws{
      position: absolute;
      left: 25px;
      width: 266px;
      height: 46px;
      border: 1px solid $borderproject;
      border-radius: 24px;
      display: flex;
      align-items: center;
      img{
        margin-right: -12px;
        height: 28px;
        border: 3px solid #fff;
        border-radius: 17px;
        &:first-child{
          margin-left: 7px;
        }
      }
      span{
        margin-left: 25px;
        font-size: 15px;
        color: $blackclassic;
        letter-spacing: 0;
        font-weight: 600;
        font-style: normal;

      }
    }
  }
}

.marketplace{
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $white;
  margin: auto;
  flex-direction: column;
  .filter{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .technologies {
      margin: 30px;
      margin-right: 0;
      width: calc(37% - 30px);
      border-top: 0px solid;
      .title{
        margin-bottom: 20px;
        img{
          margin-top: -2px;
        }

      }
      .tech .inner span{
        display: none;
      }
      .newtechnology{
        top: 90px;
      }

    }
    .budget {
      width: calc(63% - 60px);
      margin: 30px;
      margin-left: 0;
      .title{
        margin-bottom: 19px;
        img{
          margin-top: -2px;
        }
      }
      .sliderbox{
        margin-top: 0;

        input[type='range']::-ms-ticks {
          width: 2px;
          -webkit-appearance: none;
          height: 19px;
          background: white;
        }

        input[type='range']::-webkit-slider-thumb {
          width: 19px;
          -webkit-appearance: none;
          height: 19px;
          background: #6EF495;
          box-shadow: 4px 6px 10px 2px rgba(110,244,183,0.40);
        }

      }
    }
    .categories {
      padding: 0 30px 0px 30px;
      border-bottom: 1px solid $standardborder;
      height: 96px;
      .subcategories{
        top: 96px;
        background: $white;
        z-index: 2;
        width: calc(100% - 32px);
      }
    }
  }
  .edheader{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    min-height: fit-content;
    margin: 40px 40px 28px 40px;
    height: 70px;
    width: calc(100% - 80px);
    position: relative;
    .subcat{
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 276px;
      height: fit-content;
      border-radius: 25px;
      width: fit-content;
      padding: 10px;
      border: 1px solid #1EE088;
      background: #E7FFEF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 0;
        color: $black;
        margin: 0 10px;
      }
      img{
        cursor: pointer;

        background: #05D677;
        padding: 5px;
        border-radius: 12px;
      }
    }

    .title{
      width: 45%;
      color: $black;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
    }
    .description{
      margin-top: 6px;
      width: 45%;
      opacity: 0.4;
      color: $black;
      letter-spacing: 0;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
    }
    .search{
      position: absolute;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      height: 42px;
      width: 260px;
      border-radius: 25px;
      border: 1px solid $borderproject;
      display: flex;
      align-items: center;
      &:hover{
        background: #FFFFFF;
        border-radius: 25px;
        border: 1px solid #BECDD0;
        box-shadow: 0 0 0 4px rgba(213,225,228,0.40);
      }
      img{
        margin-left: 16px;
        margin-right: 11px;
        height: 14px;
      }
      input{
        width: 100%;
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        color: $blackclassic;

      }
      .search input:focus + .search {
        background: #FFFFFF;
        outline: 0;
        border-radius: 25px;
        border: 1px solid #0AC9EF;
        box-shadow: 0 0 0 4px rgba(10,201,239,0.20);
        &::placeholder{
          opacity: 0.3;
        }
      }

    }

  }
  .edworkers{
    display: flex;
    overflow: auto;
    width: calc(100% - 36px);
    margin: 0 23px;
    flex-wrap: wrap;
    .edworker{
      width: 232px;
      height: 247px;
      margin: 17px;
      border: 1px solid $borderproject;
      border-radius: 10px;
    }
  }
}
