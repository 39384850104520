.mainScreen div.workspace.widget{
  display: flex;
  width: $doublewidth;
  background: $white;
  border-radius: 16px;
  box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.10);
  flex-direction: column;
  position: relative;
  height: 385px;
  .ql-snow .ql-editor h1 {
    font-size: 34px;
    color: #09323A;
    margin-bottom: 10px;
  }

  .ql-snow .ql-editor h4 {
    font-size: 20px;
    color: #09323A;
  }
  .ql-toolbar{
    //z-index: 0;
  }

  &.preview{
    margin-top: 0px;
    background-image: url('/images/background/Workspace.svg');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: 95% 5%;
    .saved{
      right: 20px;
      top: 20px;
    }
    .header{
      display: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .ql-container:after{
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 65px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 75%);
    }
    .ql-editor{
      padding: 30px 34% 30px 30px;
      width: calc(100% - 16px);
      max-height: 300px;
      overflow-y: auto;

    }
  }
  &.full{
    background: $white;
    height: fit-content;
    position: absolute;
    min-height: calc(100vh - 128px);
    &::after{
      content: '';
      position: absolute;
      bottom: -25px;
      right: 0;
      left: 0;
      height: 25px;
      background: transparent;
    }
    .ql-snow .ql-editor::-webkit-scrollbar-track {
      border-radius: 3px;
      margin: 59px;
      height: calc(100% - 100px)
    }

    .ql-toolbar.ql-snow {
      min-height: 51px;
      max-height: 51px;
      justify-content: center;
      position: sticky;
      top: 0px;
      background: white;
      padding: 0px;
    }

    .ql-editor{
      height: fit-content;
      min-height: 500px;
      padding-right: 82px;
      padding-left: 82px;
      padding-top: 60px;
      padding-bottom: 400px;
    }
    .blueButton{
      display: none;
    }
  }
  .header{
    display: flex;
    justify-content: space-between;
    height: 89px;
    align-items: center;
    border-bottom: 1px solid #dfe4e599;
    width: 100%;
    min-height: 96px;
    .title{
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      img{
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
  .ql-container{
    width: 100%;
  }
  .ql-editor{
    padding: 30px;
  }
  .ql-toolbar.ql-snow{
    width: 100%;
    padding-left: 29px;
    min-height: 86px;
    max-height: 86px;
    border-bottom: 1px solid $standardborder;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .saved{
    position: absolute;
    right: 30px;
    width: max-content;
    padding: 0 20px 0 12px;
    color: #fff;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    height: 36px;
    background: #09323a8c;
    border-radius: 22.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      margin-right: 12px;
      width: 16px;
      height: 16px;
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
    }
  }

  .blueButton{
    position: absolute;
    bottom: 45px;
    right: 45px;
    img{
      margin-left: 20px;
    }
  }
}
