.newInquiry{
  width: 475px;
  height: 684px;
  position: relative;
  align-items: center;
  &.congs{
    background-image: url(/images/background/StarsTwoGreen.svg);
    background-position: 50% 28%;
    background-repeat: no-repeat;
  }
  .slider{
    &::-webkit-slider-thumb {
      background: #6EF4B7;
      box-shadow: 4px 6px 10px 2px rgba(5,214,119,0.20);
    }
    &::-moz-range-thumb {
      background: #05D677;
    }
  }
  .top{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    width: 75%;
    img{
      margin-right: 20px;
    }
    span{
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      text-align: center;
      color: $blackclassic;
      letter-spacing: 0;
      max-width: 300px;
    }
  }
  .estimation{
    margin-bottom: 20px;
    .value{
      margin-bottom: 10px;
    }
  }
  .info{
    opacity: 0.8;
    background: #F7F9FB;
    border-radius: 12px;
    width: 319px;
    padding: 20px;
    margin: 29px;
    opacity: 0.8;
    font-weight: 500;
    font-size: 14px;
    color: #47656B;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
  }
  .hired{
    background: #dffff0a6;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 158px;
    margin-top: 80px;
    margin-bottom: 40px;
    img{
      border-radius: 80px;
      width: 106px;
      height: 106px;
      border: 4px solid #FFFFFF;
      box-shadow: 5px 10px 25px 0 rgba(5,214,119,0.25);
    }
  }
  .congrats{
    font-weight: 600;
    opacity: 0.8;
    font-size: 14px;
    color: #47656B;
    letter-spacing: 0;
    text-align: center;
    line-height: 22px;
    width: 359px;
  }
  .greenButton.finish{
    margin-bottom: 80px;
    margin-top: auto;
  }
  .user{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
    img{
      width: 100px;
      height: 100px;
      border-radius: 14px;
      margin-bottom: 25px;
    }
    .rate{
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      color: $greenclassic;
      letter-spacing: 0;

    }
    .title{
      font-weight: bold;
      font-style: normal;
      font-size: 15px;
      color: $black;
      letter-spacing: 0;
      margin-top: 15px;
      margin-bottom: 8px;
    }
    .description{
      font-weight: 500;
      font-style: normal;
      opacity: 0.64;
      color: $black;
      letter-spacing: 0;
      font-size: 13px;

    }
  }
  .edcalendar{
    margin-bottom: auto;
  }
  .resume{
    display: flex;
    width: 90%;
    margin: 20px;
    margin-top: auto;
    > div{
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      position: relative;
      &.cost{
        &::before{
          width: 1px;
          height: 85%;
          background: $standardborder;
          content: '';
          position: absolute;
          bottom: -20px;
          left: 0;
        }
      }
      img{
        height: 20px;
      }
      .title{
        margin-top: 10px;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $blackclassic;
        letter-spacing: 0;
      }
      .description{
        margin-top: 10px;
        font-weight: 600;
        opacity: 0.45;
        font-style: normal;
        font-size: 10px;
        color: $blackclassic;
        letter-spacing: 0;
      }

    }
  }
  .bottom{
    bottom: 0px;
    height: 106px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $borderproject;
    .greenButton{
      width: -webkit-fill-available;
      margin-right: 30px;
      padding: 0 20px;
      margin-left: 30px;
    }
    .whiteButton{
      width: -webkit-fill-available;
      margin-left: 30px;
      padding: 0 20px;
    }
  }
}
