.gallery{
  display: flex;
  justify-content: center;
  .close{
    position: absolute;
    top: 50px;
    right: 50px;
    padding: 18px 18px;
    background: #06242ad9;
    border-radius: 50px;
    cursor: pointer;
    &:hover {
      background: #06242a40;
    }

  }
  &.empty{
    .header{
      .author{
        .info{
          justify-content: center;
        }
        div.pic{
          border-radius: 10px;
          margin: 0 15px;
          height: 40px;
          width: 40px;
          background: #ffffff61;
        }
        span{
          margin: 5px 5px;
          height: 7px;
          width: 200px;
          border-radius: 4px;
          background: #ffffff61;
          &:last-child{
            width: 150px;
            background: #ffffff90;
          }
        }

      }
      .files{
        span{
          margin: 5px 5px;
          height: 7px;
          width: 50px;
          border-radius: 4px;
          background: #ffffff61;
          &:last-child{
            width: 40px;
            background: #ffffff90;
          }
        }
      }
      .options{
        background: #000000;
      }
    }
    .next, .previous{
      cursor: default;

    }
    .imagetoload{
      width: 50%;
      height: 50%;
      background: #ffffff3b;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 25px;

    }
  }
  .header{
    position: absolute;
    width: 75vw;
    display: flex;
    max-height: 50px;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;
    top: 50px;
    .author{
      display: flex;
      height: 50px;
      img{
        border-radius: 10px;
        height: 100%;
      }
      .info{
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        .name{
          color: $white;
          font-size: 15px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: 0.17px;
          margin-bottom: 5px;
        }
        .date{
          font-weight: 500;
          font-style: normal;
          color: $white;
          opacity: 0.5;
          font-size: 13px;
          letter-spacing: 0.13px;
        }

      }
    }
    .files{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      .number{
        color: $white;
        font-size: 15px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 0.17px;
        margin-bottom: 5px;
      }
      .type{
        font-weight: 500;
        font-style: normal;
        color: $white;
        opacity: 0.5;
        font-size: 13px;
        letter-spacing: 0.13px;
      }
    }
    .options{
      position: relative;
      background: #06242ad9;
      border-radius: 50px;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #06242a40;
      }
      cursor: pointer;
      .options{
        background: #06242ad9;
        padding: 12px 18px;
        border-radius: 50px;
        cursor: pointer;
        &:hover + .list{
          display: flex;
        }
      }
      .list{
        position: absolute;
        display: none;
        width: 190px;
        background: #000000b3;
        color: $white;
        border-radius: 10px;
        font-weight: 600;
        font-style: normal;
        right: 0;
        z-index: 1;
        &:hover{
          display: flex;
        }

        &::after{
          content: '';
          z-index: -1;
          position: absolute;
          top: -20px;
          bottom: -20px;
          left: -20px;
          right: -20px;
        }
        span{
          overflow: hidden;
          padding: 15px;
          cursor: pointer;
          width: 100%;
          border-radius: 10px;
          &:hover{
            background: #ffffff1a;
          }
        }

      }
    }
  }
  .image, .video {
    background: $white;
    max-width: 75%;
    max-height: 50%;
    width: auto;
    height: auto;
    box-shadow: 5px 20px 35px 0 rgba(71, 101, 107, 0.10);
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    margin: auto;
    &.nopreview{
      background: #06242af2;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .desc{
        max-width: 349px;
        font-size: 14px;
        color: #ffffff80;
        letter-spacing: 0.16px;
        text-align: center;
        line-height: 20px;
        margin-bottom: 40px;
      }
      img{
        width: 60px;
        margin-bottom: 20px;
      }
      .name{
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.18px;
        line-height: 14px;
      }
      .bytes{
        max-width: 349px;
        font-size: 14px;
        color: #ffffff80;
        letter-spacing: 0.16px;
        text-align: center;
        line-height: 20px;
        margin-bottom: 30px;
        margin-top: 5px;
      }
      .download{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 145px;
        height: 46px;
        background: rgba(255,255,255,0.20);
        border-radius: 25px;
        color: $white;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        cursor: pointer;
      }

    }
  }
  .doc {
    position: absolute;
    bottom: 0;
    height: 85vh;
    width: 75vw;
    embed{
      height: inherit;
      width: inherit;
    }

  }
  .previous{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 40px;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #06242ad9;
    cursor: pointer;
    &:hover {
      background: #06242a40;
    }

  }
  .next{
    width: 60px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    right: 40px;
    margin: auto;
    top: 0;
    bottom: 0;
    background: #06242ad9;
    border-radius: 30px;
    cursor: pointer;
    &:hover {
      background: #06242a40;
    }

  }
}
