/* General Tags */
a { color: $blueclassic2; }
body {
  margin: 0;
  overflow: hidden;
  font-family: 'Gilroy', Arial, sans-serif;
  font-size: 15px;
  font-weight:normal;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::placeholder, input, textarea{
  font-family: 'Gilroy';
  font-weight:normal;
  font-style:normal;
}
h2 {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  color: $black;
  letter-spacing: 0;
  margin: 35px 35px 20px 35px;
}

/* Scroll */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-track {
    //background: $standardborder;
    border-radius: 3px;
    margin: 8px;
}
::-webkit-scrollbar-thumb {
  background: #E1E7E9;
  border-radius: 4px;
}
.budget{
  margin-top: 19px;
  margin-bottom: 30px;
  width: calc(65% - 130px);//566px;
  display: flex;
  flex-direction: column;
  .title{
    display: flex;
    width: 100%;
    span{
      margin-left: 12px;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      color: $blackclassic;
      letter-spacing: 0;
      width: 100%;
    }
  }
  .sliderbox {
    position: relative;
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 74px;
    border-radius: 10px;
    border: 1px solid $borderproject;
    span {
      margin: 0 20px;
      font-weight: bold;
      font-style: normal;
      font-size: 15px;
      color: $blackclassic;
      &:first-child{
        left: 20px;
        top: 30px;
      }
      &:last-child{
        right: 10px;
        top: 30px;
      }
    }
    /*
    input[type='range'] {
      overflow: hidden;
      -webkit-appearance: none;
      background-color: #9a905d;
    }

    input[type='range']::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: #13bba4;
      margin-top: -1px;
    }

    input[type='range']::-webkit-slider-thumb {
      width: 10px;
      -webkit-appearance: none;
      height: 10px;
      background: #434343;
      box-shadow: -80px 0 0 80px #43e5f7;
    }
*/
    .input{
      position: relative;
      width: 100%;

    }
    input {
      pointer-events: none;
      //width: 413px;
      width: 100%;
      position: absolute;
      outline: none;
      height: 4px;
      padding: 0;
      &::-webkit-slider-thumb {
        pointer-events: all;
        position: relative;
        z-index: 1;
        outline: 0;
        background: #6EF495;
        box-shadow: 4px 6px 10px 2px rgba(110,244,183,0.40);
      }
      &::-moz-range-thumb {
        pointer-events: all;
        position: relative;
        z-index: 10;
        -moz-appearance: none;
        width: 9px;
        background: #6EF495;
        box-shadow: 4px 6px 10px 2px rgba(110,244,183,0.40);
      }
      &::-moz-range-track {
        position: relative;
        z-index: -1;
        background-color: rgba(0, 0, 0, 1);
        border: 0;
      }
      &:last-of-type::-moz-range-track {
        -moz-appearance: none;
        background: none transparent;
        border: 0;
      }
    }
    input[type=range]::-moz-focus-outer {
      border: 0;
    }
  }
}
.categories{
  height: 126px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  &.basic {
    .category{
      border-radius: 0px;
      margin: 0px 0;
    }
  }
  .category{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    border-radius: 10px;
    margin: 10px 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    &:first-child::before{
      width: 0px;
    }
    &::before {
      width: 1px;
      height: 50%;
      background: #dfe4e5a8;
      content: '';
      margin-bottom: -77px;
      margin-left: -144px;
    }
     > span{
      font-weight: 500;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0;
      color: $blackclassic;
      margin-top: 16px;
      margin-bottom: 17px;
    }
    &:hover{
      background-image: linear-gradient(0deg, #F7F9FB 0%, #FFFFFF 100%);
      border-bottom: 2px solid #16DAf4;
    }
  }
}
.subcategories{
  display: none;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  padding: 15px;
  height: auto;
  left: 0;
  border: 0.5px solid #DBE3E5;
  position: absolute;
  background: white;
  top: 369px;
  width: calc(100% - 30px);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  &.basic .subcategory{
    &:hover {
      background: transparent;
      span{
        text-decoration: underline;
      }
    }
  }
  .subcategory{
    display: flex;
    width: 33.3%;
    min-width: 230px;
    height: 50px;
    margin: 0 -4px 0 4px;
    border-radius: 8px;
    align-items: center;
    text-align: left;
    cursor: pointer;

    &:hover{
      background: #F7F9FB;
    }

    > span{
      font-weight: 500;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0;
      color: $blackclassic;
      margin-left: 15px;
      .newtag{
        background: $blueclassic;
        color: $white;
        border-radius: 5px;
        font-size: 11px;
        font-weight: bold;
        margin-left: 5px;
        font-style: normal;
        padding: 3px 7px;
      }

    }
    .checkbox{
      width: 20px;
      height: 20px;
      border-radius: 25px;
      &.active{
        background: #05D677;
        background-image: url(/images/basic/VerifiedWhite.svg);
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid transparent;
      }
      &.inactive{
        border: 1px solid #DAE1E3;
      }
    }

  }
}

/*General Components*/
.technologies{
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid $chatine;
  .title{
    display: flex;
    width: 100%;
    margin-bottom: 18px;
    span{
      margin-left: 12px;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      color: $blackclassic;
      letter-spacing: 0;
      width: 100%;
    }
  }
  .techs{
    display: flex;
    .tech{
      height: 74px;
      //background: #F7F9FB;
      background: $white;
      border: 1px solid $standardborder;
      border-radius: 10px;
      margin-right: 10px;
      width: 33.33%;
      position: relative;
      .inner{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        &:last-child{
          margin-right: 0px;
        }
        img:first-child{
          margin: 0 15px 0 20px;
        }
        img.switch{
          margin: 0 20px 0 15px;
        }
        &:hover:before {
          position: absolute;
          pointer-events: none;
          content:"";
          border-radius: 10px;
          height: 100%;
          width: 100%;
          top:0;
          left:0;
          background: #09323A08;
        }
        img{
          z-index: 1;
        }
        .name{
          margin-bottom: -2px;
          z-index: 1;
          width: 100%;
          font-weight: 600;
          opacity: 0.8;
          font-size: 14px;
          color: #09323A;
          letter-spacing: 0.16px;
          text-align: left;
          line-height: 14px;
        }
        img:first-child{
          margin-right: 16px;
          max-width: 30px;
          width: auto;
          max-height: 30px;
          height: auto;
        }
      }
    }
  }


  .newtechnology{
    position: absolute;
    background: $white;
    bottom: 90px;
    z-index: 2;
    //margin-top: 130px;
    flex-direction: column;
    align-items: center;
    //margin-left: 5px;
    width: 350px;
    height: 424px;
    border: 1px solid $borderproject;
    box-shadow: 3px 6px 9px 5px #9aafb414;
    border-radius: 10px;
    display: none;
    &.open {
      display: flex;
    }
    .searchtech{
      margin: 25px 0 20px 0;
      width: 285px;
      min-height: 44px;
      max-height: 44px;
      border-radius: 25px;
      border: 1px solid $inputborder;
      padding-left: 25px;
      font-weight: 600;
      font-style: normal;
      font-size: 14px;
      color: $black;
      letter-spacing: 0;
      &:hover{
        background: #FFFFFF;
        border-radius: 25px;
        border: 1px solid #BECDD0;
        box-shadow: 0 0 0 4px rgba(213,225,228,0.40);
      }
      &:focus{
        background: #FFFFFF;
        outline: 0;
        border-radius: 25px;
        border: 1px solid #0AC9EF;
        box-shadow: 0 0 0 4px rgba(10,201,239,0.20);
        &::placeholder{
          opacity: 0.3;
        }
      }

    }

  }
}

.listtechnologies{
  overflow: auto;
  width: 100%;
  margin-right: 15px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  .technology{
    cursor: pointer;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: $black;
    letter-spacing: 0;
    text-transform:capitalize;
    display: flex;
    align-items: center;
    min-height: 42px;
    max-height: 42px;
    border-radius: 8px;
    margin-left: 25px;
    margin-right: 15px;
    //width: calc(100% - 60px);
    &:hover{
      background: $gray;
    }
    img{
      max-height: 20px;
      max-width: 20px;
      min-width: 20px;
      height: auto;
      width: auto;
      margin: 0 15px 0 25px;
    }
  }
  .break{
      margin: 20px;
      height: 1px;
      opacity: 0.25;
      background: $footerline;
      width: calc(100% - 40px);
  }

}

.textInput {
  position: relative;
  width: 100%;
  input {
    width: calc(100% - 25px);
    padding-left: 25px;
    min-height: 44px;
    max-height: 44px;
    background: $white;
    color: $black;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    border-radius: 25px;
    border: 1px solid $inputborder;
    font-family: 'Gilroy', Arial, sans-serif;
    &:hover{
      background: #FFFFFF;
      border-radius: 25px;
      border: 1px solid #BECDD0;
      box-shadow: 0 0 0 4px rgba(213,225,228,0.40);
    }
    &:focus{
      background: #FFFFFF;
      outline: 0;
      border-radius: 25px;
      border: 1px solid #0AC9EF;
      box-shadow: 0 0 0 4px rgba(10,201,239,0.20);
      &::placeholder{
        opacity: 0.3;
      }
    }
  }
  .error{
    position: absolute;
    /*background: #FFF6F8;
    border: 1px solid #FFC4D0;
    box-shadow: 0 6px 10px 0 rgba(255, 148, 171, 0.25);*/
    border-radius: 16px;
    padding: 15px 25px;
    color: $white;
    letter-spacing: 0;
    text-align: center;
    font-size: 14px;
    max-width: 320px;
    right: 0;
    top: -53px;

    background: rgba(9,50,58,0.85);
    box-shadow: 4px 15px 20px 10px rgba(206,213,220,0.50);
    border-radius: 16px 16px 16px 0 0 0 16px;
  }
}
.dark .errortag{
  background: rgba(3,24,29,0.85);
  box-shadow: 2px 10px 15px 5px rgba(3,18,22,0.25);
  border-radius: 10px 10px 10px 0 0 0 10px;
  top: -52px;
  right: 0;
}
.errortag{
  position: absolute;
  border-radius: 16px;
  padding: 15px 25px;
  color: $white;
  letter-spacing: 0;
  text-align: center;
  font-size: 14px;
  max-width: 320px;
  left: 0;
  top: -60px;
  box-shadow: 2px 10px 15px 5px rgba(3, 18, 22, 0.25);
  background: #03181dd9;
  &:after {
    position: absolute;
    content: "";
    height: 15px;
    transform: rotate(45deg);
    width: 15px;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
    background: #03181dd9;
  }
}

.selectlabel{
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  position: relative;
  .title{
    display: flex;
    align-items: center;
    img{
      margin-right: 14px;
    }
    span{
      color: $blackclassic;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0;
    }
  }
  .label{
    display: flex;
    flex-direction: column;
    margin: 20px;
    width: 150px;
    flex-wrap: wrap;
    height: 64px;
    border-radius: 10px;
    border: 1px solid $borderproject;
    .name{
      width: 65px;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      color: $blackclassic;
      letter-spacing: 0;
      margin-top: 12px;
      margin-left: 20px;
      text-transform:capitalize;
      font-family: 'Gilroy', Arial, sans-serif;

    }
    .info{
      margin-top: 4px;
      margin-bottom: 10px;
      margin-left: 20px;
      font-weight: 500;
      font-style: normal;
      opacity: 0.6;
      font-size: 13px;
      color: $blackclassic;
      letter-spacing: 0;

    }
    img{
      margin: 25px 0px;
    }
  }
  .labels{
    display: none;
    position: absolute;
    overflow: auto;
    width: 178px;
    top: 110px;
    left: 7px;
    z-index: 2;
    flex-direction: column;
    background: $white;
    border: 1px solid #E8ECED;
    box-shadow: 3px 6px 9px 5px #9aafb414;
    border-radius: 10px;
    span{
      cursor: pointer;
      font-weight: 600;
      font-style: normal;
      font-size: 14px;
      color: $black;
      letter-spacing: 0;
      text-transform:capitalize;
      display: flex;
      align-items: center;
      min-height: 42px;
      max-height: 42px;
      border-radius: 8px;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - 30px);
      svg{
        margin-right: 10px;
      }
      &:hover{
        background: $gray;
      }
    }
    &.open{
      display: flex;
    }
  }
}
.selectdate{
  display: flex;
  flex-direction: column;
  position: relative;
  .title{
    display: flex;
    align-items: center;
    img{
      margin-right: 14px;
    }
    span{
      color: $blackclassic;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0;
    }
  }
  .date{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 20px;
    width: 150px;
    flex-wrap: wrap;
    height: 64px;
    border-radius: 10px;
    border: 1px solid #E8ECED;
    cursor: pointer;
    div{
      display: flex;
      align-items: center;
      margin: 12px;
      margin-bottom: 5px;
      font-size: 15px;
      width: 120px;
      font-weight: 600;
      color: $blackclassic;
      font-style: normal;
    }
    span{
      margin-left: 12px;
      font-size: 13px;
      opacity: 0.6;
      letter-spacing: 0;
      font-weight: 500;
      color: $blackclassic;
      font-style: normal;
    }
    img{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 15px;
    }
  }
  .calendar{
    display: none;
    &.open{
      display: flex;
      .edcalendar{
        position: absolute;
        top: 109px;
        z-index: 5;
        background: white;
        left: -207px;
        padding: 30px 0;
        border-bottom: 1px solid $standardborder;
      }
    }
  }

}
.basicfiles{
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  .title{
    display: flex;
    align-items: center;
    img{
      margin-right: 14px;
    }
    span{
      color: $blackclassic;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0;
    }
  }
  .dragdrop{
    border: 1px dashed $dragline;
    margin: 20px 0;
    display: flex;
    height: 94px;
    border-radius: 10px;
    background: #F8FBFC;
    align-items: center;
    justify-content: space-around;
    img{
      width: 92px;
    }
    span{
      color: $blackclassic;
      font-weight: 500;
      font-style: normal;
      font-size: 13px;
      opacity: 0.5;
      letter-spacing: 0;

    }
  }
  .listfiles{
    display: flex;
    flex-wrap: wrap;
    max-height: 100px;
    overflow: auto;
    .file{
      border-radius: 10px;
      padding: 5px 15px;
      border: 1px solid $borderproject;
      margin: 5px;
      display: flex;
      align-items: center;
      span{
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        max-width: 50px;
        overflow: hidden;
        color: $blackclassic;
        font-weight: 500;
        font-style: normal;
        font-size: 13px;
        opacity: 0.5;
        letter-spacing: 0;
      }
      img{
        height: 10px;
        margin-left: 5px;
        cursor: pointer;
      }

    }
  }

}

.blueButton{
  display: flex;
  padding: 0 25px;
  min-height: 44px;
  max-height: 44px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  background: $blueclassic;
  color: $white;
  cursor: pointer;
  z-index: 0;
  transition: 0.3s;
  .go{
    width: 12px;
  }
  &:hover{
    background: #02BBE2;
  }

}

.whiteButton{
  display: flex;
  transform-origin: top left;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  min-height: 44px;
  max-height: 44px;
  font-size: 15px;
  color: $black;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  border-radius: 22px;
  border: 1px solid $borderproject;
  cursor: pointer;
  background: $white;
  transition: 0.3s;
  >.go{
    margin-left: 10px;
  }
  &:hover{
    background: $hover;
  }
}

.violetButton{
  //position: absolute;
  //left: 37px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  max-height: 44px;
  font-size: 15px;
  color: $white;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  border-radius: 22px;
  border: 1px solid $borderproject;
  cursor: pointer;
  background: $violetteam;
  &:hover{
      background: #B561CF;
      //background: #0000000d;
    //display: flex;

  }

}
.greenButton{
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  max-height: 44px;
  font-size: 15px;
  color: $white;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  border-radius: 22px;
  //border: 1px solid $borderproject;
  cursor: pointer;
  background: $greenclassic3;
  transition: 0.3s;
  &:hover{
    background: #00BD5E;
  }
}
.redButton{
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  max-height: 44px;
  font-size: 15px;
  color: $white;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  border-radius: 22px;
  border: 1px solid $borderproject;
  cursor: pointer;
  background: $redclassic;
  transition: 0.3s;
  &:hover{
    background: #ff1248;
  }

}
.darkButton{
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  max-height: 44px;
  font-size: 15px;
  color: $white;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  border-radius: 22px;
  border: 1px solid $borderproject;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.3s;
  &:hover{
    background: rgba(255, 255, 255, 0.4);
  }

}


.slider {
  -webkit-appearance: none;
  width: 100%;
  margin-top: -3px;
  height: 4px;
  border-radius: 2px;
  background: #d3d3d333;
  outline: none;
  transition: opacity .2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #6EF4B7;
    box-shadow: 4px 6px 10px 2px rgba(5,214,119,0.20);
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $blueclassic;
    cursor: pointer;
  }

}
.invitationList{
  border-top: 1px solid $borderproject;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  .text{
    width: 364px;
    font-weight: 600;
    font-style: normal;
    font-size: 15px;
    text-align: left;
    display: flex;
    color: $blackclassic;
    img{
      margin-right: 12px;
    }
  }
  .invite{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    .search{
      width: 100%;
      min-height: 44px;
      max-height: 44px;
      border-radius: 25px;
      border: 1px solid $inputborder;
      font-family: 'Gilroy', Arial, sans-serif;
      padding-left: 25px;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: $blackclassic;
      letter-spacing: 0;
      &::placeholder{
        opacity: 0.56;
      }
      &:focus{
        border: 1px solid $blueclassic;
      }
    }
    .whiteButton{
      margin: 8px;
      width: 89px;
      height: 44px;
    }
  }
  .invitedBox{
    width: 100%;
    margin: 10px 0 40px 0;
    height: 142px;
    border-radius: 14px;
    background: $gray;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    .emptystate{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      span{
        width: 200px;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        color: $black;
        opacity: 0.4;
      }

    }
    .email{
      height: 14px;
      margin: 8px 5px;
      padding: 15px 20px 15px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      letter-spacing: 0;
      border-radius: 25px;
      border: 1px solid $borderproject;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: $black;
      .img{
        cursor: pointer;
        width: 12px;
        height: 12px;
        padding: 6px;
        border-radius: 15px;
        margin-left: 10px;
        margin-right: -8px;
        background: $greenclassic;
        background-image: url(/images/basic/CheckWhite.svg);
        background-repeat: no-repeat;
        background-position: center;
        &:hover{
          background: $graymenu;
          background-image: url(/images/basic/CloseWhite.svg);
          background-size: 40%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

}
.selectproject{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 45px;
  background: $white;
  border-top: 1px solid $borderproject;
  .title{
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    color: $blackclassic;
    margin: 35px 0;
    width: 100%;
  }
  .project{
    display: flex;
    flex-direction: column;
    width: 199px;
    height: 202px;
    border: 1px solid $borderproject;
    border-radius: 10px;
    box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.15);
    background: $white;
    margin: 15px;
    cursor: pointer;
    &.selected{
      border: 1px solid #0AC9EF;
      box-shadow: 2px 4px 7px 0 #0ac9ef26;
    }
    .top{
      min-height: 111px;
      max-height: 111px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      img{
        width: 48px;
        height: 48px;
        border-radius: 12px;
      }
      .logo{
        background: rgb(0, 188, 235);
        width: 48px;
        height: 48px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 33px;
        color: white;
        font-weight: 800;
      }
      .name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        margin-top: 19px;
        margin-bottom: 6px;
        font-size: 16px;
        color: $blackclassic;
        letter-spacing: 0;
        font-weight: bold;
        font-style: normal;
        line-height: 22px;
      }
      .info{
        font-size: 16px;
        opacity: 0.5;
        color: $blackclassic;
        letter-spacing: 0;
        font-weight: 500;
        font-style: normal;
        line-height: 16px;
      }

    }
    .bottom{
      height: 21px;
      width: calc(100% - 40px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      border-top: 1px solid $borderproject;
      img{
        margin: 5px;
        width: 30px;
        height: 30px;
        border-radius: 8px;
      }
      div{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-style: normal;
        font-size: 15px;
        border-radius: 8px;
        color: #47656b9e;
        margin: 5px;
        width: 30px;
        height: 30px;
        border: 1px solid $borderproject;
      }
    }
  }

}
.userslist{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 45px;
  background: $white;
  border-top: 1px solid $borderproject;
  .list{
    display: flex;
    width: 100%;
    overflow: auto;
  }
  .empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    img{

    }
    span{
      margin-top: 20px;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      width: 238px;
      color: $blackclassic;
      text-align: center;
      line-height: 20px;
      letter-spacing: 0;
    }
  }
  .userslistheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    width: 100%;
    border-bottom: 0px solid transparent;
    .title{
      display: flex;
      font-weight: 600;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0;
      color: $blackclassic;
    }
    .search{
      margin: 25px 0 20px 0;
      width: 285px;
      min-height: 44px;
      max-height: 44px;
      border-radius: 25px;
      border: 1px solid $inputborder;
      font-family: 'Gilroy', Arial, sans-serif;
      padding-left: 25px;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      color: $blackclassic;
      letter-spacing: 0;
      &::placeholder{
        opacity: 0.56;
      }
      &:hover{
        background: #FFFFFF;
        border-radius: 25px;
        border: 1px solid #BECDD0;
        box-shadow: 0 0 0 4px rgba(213,225,228,0.40);
      }
      &:focus{
        background: #FFFFFF;
        outline: 0;
        border-radius: 25px;
        border: 1px solid #0AC9EF;
        box-shadow: 0 0 0 4px rgba(10,201,239,0.20);
        &::placeholder{
          opacity: 0.3;
        }
      }
    }
    .more{
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255,255,255,0.10);
      border-radius: 8px;
      width: 50px;
      height: 34px;
      position: relative;
      cursor: pointer;
      &:hover:before {
        position: absolute;
        content:"";
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        background: #09323A40;
      }
    }
  }
  .user{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    width: 100px;
    margin: 5px;
    border: 1px solid $popupborder;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    &:hover{
      background: $shadow;
    }
    &:first-child{
      margin-left: 25px;
    }
    &.included{
      border: 1px solid $blueclassic;
      box-shadow: 2px 4px 7px 0 #0ac9ef26;
    }
    &.invite{
      filter: grayscale(1);
      img{
        width: 66px;
      }
      span{
        width: 65px;
      }
    }
    img{
      margin: 20px 30px 10px 30px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
    span{
      text-align: center;
      font-weight: 600;
      font-style: normal;
      opacity: 0.8;
      max-width: 78px;
      letter-spacing: 0.16px;
      line-height: 14px;
      font-size: 14px;
      color: $black;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.members{
  .header{
    border-bottom: 1px solid $chatine;
    border-top: 1px solid $chatine;
    height: 38px;
    background: #EAF3F54D;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      right: 19px;
      width: 22px;
      height: 12px;
      padding: 5px;
      color: #fff;
      background: #0AC9EF;
      border-radius: 12px;
      font-weight: bold;
      font-style: normal;
      font-size: 13px;
      margin-right: 20px;
    }
    span{
      width: 100%;
      font-size: 14px;
    }
  }
}
.estimation{
  width: 359px;
  .slider{
    width: 80%;
    margin-left: 10%;
    background: #d3d3d333;
    margin-bottom: 20px;
    &::-webkit-slider-thumb {
      background: #6EF4B7;
      box-shadow: 4px 6px 10px 2px rgba(5,214,119,0.20);
    }
    &::-moz-range-thumb {
      background: #05D677;
    }
  }
  .value{
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    color: $blackclassic;
  }
  .times{
    margin-top: 18px;
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
    .time{
      cursor: pointer;
      display: flex;
      justify-content: center;
      border: 1px solid $inputborder;
      border-radius: 10px;
      opacity: 0.85;
      width: 103px;
      margin: 0;
      margin-right: 18px;
      height: 46px;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.10);
      border-radius: 10px;
      font-weight: 600;
      font-size: 15px;
      color: #47656B;
      letter-spacing: 0;
      &:last-child{
        margin-right: 0px;
      }
      &.selected{
        border: 1px solid #05D677;
      }
      &:hover{
        background: $hover;
      }

    }
  }
}

.cardpay {
  width: 295px;
  height: 176px;
  background: #47656B;
  box-shadow: 5px 15px 25px 0 rgba(71,101,107,0.15);
  border-radius: 14px;
  background-image: url(/images/background/CardBackground.svg);
  background-repeat: no-repeat;
  background-size: 300px;
  display: flex;
  align-items: flex-start;
  padding: 30px;
  flex-direction: column;
  justify-content: flex-end;
  label{
    font-weight: 500;
    opacity: 0.85;
    font-size: 10px;
    color: #FFFFFF;
    letter-spacing: 0.83px;
    margin-bottom: 10px;
  }
  .StripeElement{
    width: 100%;
    .input{
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: right;
    }
  }
}

.length{
  border-top: 1px solid $chatine;
  width: 359px;
  margin-bottom: 30px;
  .title{
    margin-top: 19px;
    display: flex;
    span{
      margin-left: 12px;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      color: $blackclassic;
      letter-spacing: 0;
    }
  }
  .times{
    margin-top: 18px;
    display: flex;
    .time{
      display: flex;
      justify-content: center;
      border: 1px solid $inputborder;
      border-radius: 10px;
      opacity: 0.85;
      width: 113px;
      height: 74px;
      align-items: center;
      margin: 5px;
      &.selected{
        border: 2px solid $blueclassic;
      }
      >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 16px;
        width: 48px;
        font-weight: 500;
        font-style: normal;
        input{
          width: 100%;
          border: none;
          font-size: 24px;
          color: #47656B40;
          height: 24px;
          &::placeholder{
            color: #47656B40;
          }
          &:focus{
            font-weight: 600;
            font-style: normal;
            color: $blueclassic2;
            letter-spacing: 0;
          }
        }
        span{
          font-size: 13px;
          color: $blackclassic;
        }
      }
    }
  }
  .newlength{
    position: absolute;
    background: white;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
    width: 350px;
    height: 147px;
    border: 1px solid $borderproject;
    box-shadow: 3px 6px 9px 5px #9aafb414;
    border-radius: 10px;
    display: none;
    justify-content: center;
    &.open {
      display: flex;
    }
    .title{
      margin-top: 30px;
      font-weight: 500;
      font-style: normal;
      font-size: 13px;
      color: $black;
      line-height: 14px;
      letter-spacing: 0;
      text-align: center;
    }
  }

}
.shortlength{
  width: 192px;
  height: 106px;
  display: flex;
  position: relative;
  .time{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 20px;
    width: 150px;
    flex-wrap: wrap;
    height: 64px;
    border-radius: 10px;
    border: 1px solid #E8ECED;
    cursor: pointer;
    div{
      display: flex;
      align-items: center;
      margin: 12px;
      margin-bottom: 5px;
      font-size: 15px;
      width: 120px;
      font-weight: 600;
      color: $blackclassic;
      font-style: normal;
    }
    span{
      margin-left: 12px;
      font-size: 13px;
      opacity: 0.6;
      letter-spacing: 0;
      font-weight: 500;
      color: $blackclassic;
      font-style: normal;
    }
    img{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 15px;
    }
  }
  .newlength{
    display: none;
    .estimation{
      position: absolute;
      top: 109px;
      z-index: 6;
      background: white;
      left: -15px;
      /* width: 100%; */
      padding: 30px 0;
      width: 414px;
      margin-bottom: 30px;
      border-bottom: 1px solid $standardborder;
    }
    &.open {
      display: flex;
    }
    .title{
      margin-top: 30px;
      font-weight: 600;
      font-style: normal;
      font-size: 13px;
      color: $black;
      line-height: 14px;
      letter-spacing: 0;
      text-align: center;
    }
    .footer{
      display: flex;
      margin: 28px;
      div{
        width: 95px;
        margin-top: 10px;
        &:first-child{
          margin-right: 15px;
        }
      }
    }
  }
}

.cropimage{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  input[type="range"] {
     transform: rotateZ(270deg);
     right: -120px;
      position: absolute;
      width: 183px;
      margin-top: 0;
  }

  .whiteButton{
    margin: 20px 0;
    width: 144px;
  }
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 146px;
    background: $gray;
    border-radius: 16px;
    margin: 43px 25px 28px 25px;
    position: relative;
    cursor: pointer;
    &.next{
      width: 80px;
      height: 80px;
      margin: 0;
      border-radius: 40px;
      opacity: 0.5;
      img{
        width: 55px;
        height: 55px;
      }
    }
    &.tight{
      border-radius: 73px;
      img{
        width: 77px;
        height: 77px;
      }
    }
    &.unique{
      width: 200px;
      height: 200px;
      margin: 35px 40px 10px 40px;
      >div{
            border-radius: 25px;
      }
    }
  }
}


.offers{
  margin-top: auto;
  height: 197px;
  width: 290px;
  .offer{
    display: flex;
    flex-direction: column;
    .bottom{
      display: flex;
      background: #09323ae6;
      height: 40px;
      padding: 25px;
      div.logo{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;
        border-radius: 10px;
        width: 40px;
        height: 40px;
        object-fit: cover;
        font-size: 18px;
        font-weight: 800;
        font-style: normal;
        color: $white;
      }
      img.customized{
        border-radius: 10px;
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
      .center{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: auto;
        .fullname{
          opacity: 0.8;
          font-weight: 600;
          font-size: 15px;
          color: #FFFFFF;
          letter-spacing: 0.17px;
          margin-top: 2px;
        }
        .message{
          opacity: 0.45;
          font-size: 12px;
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 0.12px;
          margin-bottom: 2px;
        }
      }
      .action{
        cursor: pointer;
        width: 64px;
        height: 40px;
        border-radius: 10px;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0.16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

    }
    .top{
      display: flex;
      div{
        display: flex;
        flex-direction: column;
        width: 73px;
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 73px;
          font-size: 26px;
          background: #09323ae6;
          color: #ffffffb0;
          letter-spacing: 0.3px;
          font-weight: 600;
          border: 1px solid #ffffff10;
        }
        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 34px;
          background: #09323acc;
          font-size: 10px;
          font-weight: bold;
          color: #ffffffb0;
          letter-spacing: 0.12px;
        }
      }
    }
  }

}
