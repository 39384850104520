.screen{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url(/images/landing/LeftBack.svg), url(/images/landing/RightBack.svg);
  background-position: left, right;
  background-repeat: no-repeat, no-repeat;
  .textInput .errortag{
    right: 0;
    top: -50px;
  }

  .title{
    margin-top: 0px;
    color: $black;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    line-height: 44px;
    text-align: center;
  }
  .description{
    margin-top: 0px;
    opacity: 0.45;
    color: $black;
    line-height: 24px;
    letter-spacing: 0;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    font-style: normal;
  }
  > .whiteButton{
    top: 36px;
    left: 60px;
    position: absolute;
    img{
      width: 12px;
      margin-right: 18px;
    }
  }
  .headerOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo{
      width: 50px;
    }
    .title{
      margin-top: 10px;
      font-size: 24px;
      line-height: 48px;
    }
    .description{
      margin-top: 10px;
      font-size: 15px;
      width: 380px;
    }
  }
  .headerTwo {
    display: flex;
    justify-content: flex-start;
    width: 840px;
    margin-bottom: 20px;
    .logo{
      width: 36px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .title{
      margin-top: 10px;
      font-size: 24px;
      line-height: 48px;
    }
  }
  .body{
    display: flex;
    max-height: 651px;
    min-height: 651px;
    width: 885px;
    justify-content: center;
    border-radius: 16px;
    &.marked{
      flex-wrap: wrap;
      background: $white;
      box-shadow: 4px 15px 35px 2px $shadowsign;
      .letter{
        display: flex;
        justify-content: center;
        margin: 43px 25px 28px;
        align-items: center;
        border-radius: 24px;
        width: 100px;
        height: 100px;
        font-size: 48px;
        font-weight: 800;
        font-style: normal;
        color: #fff;
      }
    }
    > .type {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $white;
      width: 400px;
      height: 416px;
      margin: 20px;
      border: 1px solid $borderproject;
      border-radius: 16px;
      cursor: pointer;
      box-shadow: 4px 15px 35px 2px $shadowsign;
      border: 2px solid transparent;
      &:hover{
        border: 2px solid $blueclassic;
      }
      img{
        width: 200px;
      }
      .title{
        font-size: 24px;
      }
      .description{
        width: 280px;
        opacity: 0.6;
        font-size: 15px;
        line-height: 18px;
      }
    }
    &.column{
      flex-direction: column;
      align-items: center;
      .logo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        background-image: url(/images/landing/PolygonFinish.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        img {
          box-shadow: 10px 20px 35px 10px #9aafb426;
          width: 90px;
          height: 90px;
          padding: 5px;
          border-radius: 50px;
          background: $white;
        }
      }
      .categories{
        height: 126px;
        width: 100%;
        display: flex;
        flex-direction: row;
        .category{
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          cursor: pointer;
          span{
            font-weight: 500;
            font-style: normal;
            font-size: 15px;
            letter-spacing: 0;
            color: $blackclassic;
            margin-top: 16px;
            margin-bottom: 17px;
          }
        }
      }
      .subcategories{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        padding: 5%;
        height: 90%;
        width: 90%;
        border-top: 1px solid $chatine;
        position: inherit;
        .subcategory {
          display: flex;
          min-width: 33.3%;
          max-width: 33.3%;
          height: 60px;
          > span{
            font-weight: 500;
            font-style: normal;
            font-size: 15px;
            letter-spacing: 0;
            color: $blackclassic;
            margin-left: 15px;
            .newtag{
              background: $blueclassic;
              color: $white;
              border-radius: 5px;
              font-size: 11px;
              font-weight: bold;
              margin-left: 5px;
              font-style: normal;
              padding: 3px 7px;
            }

          }
          .checkbox{
            margin-left: 15px;
            min-width: 20px;
            max-width: 20px;
            height: 20px;
            border-radius: 25px;
            &.active{
              background: #05D677;
              background-image: url(/images/basic/VerifiedWhite.svg);
              background-repeat: no-repeat;
              background-position: center;
              border: 1px solid transparent;
            }
            &.inactive{
              border: 1px solid #DAE1E3;
            }
          }

        }
      }
      .title{
        font-size: 20px;
      }
      .description{
        font-size: 14px;
        width: 380px;
        margin-bottom: 25px;
      }
      .textInput{
        width: 330px;
        margin-bottom: 20px;
      }
      .bottom{
        border-top: 1px solid #9AAFB440;
        flex-wrap: wrap;
        flex-direction: row;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        span{
          margin-top: 40px;
          margin-bottom: 30px;
          width: 100%;
          text-align: center;
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $blueclassic2;
        }
        img{
          background: black;
          height: 50px;
          padding: 0px 20px;
          margin: 0 6px;
          border-radius: 25px;
        }

      }
    }
    > div {
      > .text{
        margin-bottom: 12px;
        color: $black;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        text-align: left;
        width: 275px;
      }
      .textInput{
        margin-bottom: 25px;
        width: 364px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
      .description{
        margin-top: 25px;
        opacity: 0.4;
        font-size: 14px;
        width: 218px;
      }
      &.left{
        height: 555px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $white;
        width: 47.14%;//440px;//395px;
        border-right: 0.5px solid #9AAFB45c;
        .titleBlue{
          font-weight: bold;
          font-style: normal;
          color: $blueclassic;
          letter-spacing: 0;
          font-size: 20px;
        }
        display: flex;
        .projectInput {
          border-bottom: 1px solid #98aeb340;
          padding-bottom: 20px;
          width: 80%;
          text-align: center;
          position: relative;
          input{
            width: 100%;
            font-weight: 600;
            font-style: normal;
            color: $black;
            letter-spacing: 0;
            font-size: 20px;
            outline: none;
            border: 0;
            text-align: center;
            &::placeholder{
              opacity: 1;
            }
          }
          .error{
            position: absolute;
            /*background: #FFF6F8;
            border: 1px solid #FFC4D0;
            box-shadow: 0 6px 10px 0 rgba(255, 148, 171, 0.25);*/
            border-radius: 16px;
            padding: 15px 25px;
            color: $white;
            letter-spacing: 0;
            text-align: center;
            font-size: 14px;
            max-width: 320px;
            right: 0;
            top: -53px;

            background: rgba(9,50,58,0.85);
            box-shadow: 4px 15px 20px 10px rgba(206,213,220,0.50);
            border-radius: 16px 16px 16px 0 0 0 16px;
          }

        }
        .logo{
          margin: 35px;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          height: 100px;
          width: 100px;
          background: $gray;
          border-radius: 16px;
          margin: 43px 25px 28px 25px;
          position: relative;
          &.next{
            width: 80px;
            height: 80px;
            margin: 0;
            border-radius: 40px;
            opacity: 0.5;
            img{
              width: 55px;
              height: 55px;
            }
          }
          &.tight{
            border-radius: 73px;
            img{
              width: 77px;
              height: 77px;
            }
          }
          &.unique{
            width: 150px;
            height: 150px;
            margin: 35px 40px 10px 40px;
            > div{
              border-radius: 25px;
            }
          }
        }

        .resumelogo{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 146px;
          height: 146px;
          border-radius: 73px;
          background: $gray;
          img{
            height: 70px;
          }
          span{
            margin-top: 2px;
            font-weight: bold;
            font-style: normal;

          }
        }
        .resumename{
          font-weight: 600;
          font-style: normal;
          font-size: 16px;
          text-align: center;
          color: $black;
          margin-top: 28px;
          margin-bottom: 25px;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .resumedelete{
          width: 144px;
          height: 40px;
        }
        &.background{
        /*  background-image: url(/images/landing/LeftBack2.svg), url(/images/landing/RightBack2.svg);
          background-position: 100px 130px, 270px 120px;
          background-repeat: no-repeat, no-repeat;*/
        }
        > .text{
          font-weight: 600;
          font-style: normal;
          font-size: 14px;
          text-align: center;
          opacity: 0.4;
          color: $black;
          line-height: 18px;
        }
        .crop{
          position: relative;
          width: 300px;
          height: 300px;
          background: $white;
          border-radius: 25px;
          overflow: hidden;
        }
        .photo{
          border-radius: 8px;
          box-shadow: 10px 20px 35px 10px #9aafb41f;
          background: $white;
          img {
            width: 75px;
            height: 75px;
            margin: 7px 7px 21px 7px;
          }
        }
        .blueButton{
          margin-top: 35px;
          width: 144px;
        }
        .or{
          text-align: center;
          z-index: 1;
          background: $white;
          width: 76px;
          margin-top: 10px;
          font-weight: 800;
          font-style: normal;
          font-size: 14px;
          letter-spacing: 0;
        }
        .ownlogo{
          border-top: 1px solid #9AAFB440;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 396px;
          position: relative;
          &::after{
            content: 'OR';
            z-index: 0;
            position: absolute;
            text-align: center;
            color: $blackclassic;
            margin-top: -10px;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            width: 35px;
            height: 20px;
            background: white;
            font-weight: 800;
            font-style: normal;
            font-size: 14px;
          }
          .blueButton{
            width: 276px;
          }
        }
      }
      &.right{
        height: 555px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $white;
        width: 444px;
        &.invited{
          .textInput{
            width: 300px;
          }
        }
        > span{
          width: 300px;
        }
        .breakline{
          height: 1px;
          width: 364px;
          background: $borderproject;
          margin-bottom: 20px;
          opacity: 0.25;
        }
        .logo{
          width: 36px;
          border: 1px solid $borderproject;
          border-radius: 16px;
          padding: 18px 12px;
          margin-bottom: 20px;
        }
        .sublogo{
          font-size: 18px;
          letter-spacing: 0;
          color: $black;
          font-weight: bold;
          font-style: normal;
          margin-bottom: 35px;

        }
        > .text{
          width: 364px;
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          text-align: left;
          &.bordertop{
            border-top: 1px solid $borderproject;
          }
        }
        .invite{
          width: 364px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;
          .textInput{
            margin-bottom: 0px;
          }
          .whiteButton{
            margin: 8px;
            width: 89px;
            height: 44px;
          }
        }
        .technologies{
          border-top: 1px solid $borderproject;
          padding-top: 32px;
          width: 364px;
          .title span{
            text-align: left;
          }
          .name{
            display: none;
          }
        }
        .invitationList{
          border-top: none;
          margin-left: 0;
          width: calc(100% - 80px);
          .invite{
            margin-bottom: 0;
          }
          .invitedBox{
            margin: 10px 0;
          }
        }
        .invitationLink{
          margin-top: 25px;
          border-top: 1px solid #9AAFB440;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 183px;
          width: 396px;
          .violetButton{
            padding: 0;
            margin-top: 35px;
            width: 100%;
            height: 44px;
            cursor: pointer;
          }

          .description{
            width: 328px;
          }
        }
        .ratetitle{
          display: flex;
          font-weight: 600;
          font-style: normal;
          letter-spacing: 0;
          width: 364px;
          align-items: center;
          margin: 40px 0 65px 0;
          span{
            font-size: 15px;
            width: 160px;
          }
          div{
            padding-top: 1px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 62px;
            height: 37px;
            border: 1px solid $borderproject;
            border-radius: 25px;
            margin-left: 8px;
            cursor: pointer;
            &:hover{
              background: $hover;
            }
            &.selected {
              border: 0px solid $borderproject;
              background: $green;
              color: $white;
            }
          }
        }
        .rate{
          margin-bottom: 45px;
          .value{
            font-weight: 600;
            font-style: normal;
            opacity: 0.85;
            color: $black;
            letter-spacing: 0;
            font-size: 34px;
          }
          .freq{
            font-weight: 500;
            font-style: normal;
            opacity: 0.35;
            color: $black;
            letter-spacing: 0;
            font-size: 15px;
            margin-left: 5px;
          }
        }
        .slider {
          margin-bottom: 36px;
          width: 60%;
          background: #d3d3d333;
          &::-webkit-slider-thumb {
            background: #6EF4B7;
            box-shadow: 4px 6px 10px 2px rgba(5,214,119,0.20);
          }
          &::-moz-range-thumb {
            background: #05D677;
          }
        }
      }
    }
  }
  .footer{
    display: flex;
    align-items: center;
    bottom: 36px;
    height: 95px;
    min-height: 95px;
    width: calc(100% - 50px);
    padding: 0 25px;
    justify-content: space-between;
    border-top: 0.5px solid rgba(154,175,180,0.30);
    &.noback{
      justify-content: flex-end;
      .steps{
        position: absolute;
        margin: 0;
        left: 0;
        right: 0;
      }
      .blueButton{
        //width: 174px;
      }
    }
    .whiteButton{
      width: 124px;
      height: 50px;
    }

    .steps{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span{
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        letter-spacing: 0;
        margin-bottom: 9px;
      }
      .boxes{
        display: flex;
        justify-content: space-between;
        div{
          margin: 5px;
          width: 30px;
          height: 5px;
          border-radius: 3.5px;
          &.boxblue{
            background: $blueclassic;
          }
          &.boxgray{
            opacity: 0.20;
            background: $footerline;
          }
        }
      }
    }

    .blueButton {
      width: 124px;
      height: 50px;
    }
  }
}
