input[type="file"] {
  display: none;

}
input[type="text"] {
  &.activate {
    background: $gray;
  }
  &.deactivate {
    background: $green;
  }
}
.confirmation{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  background: $white;
  margin: auto;
  right: 0;
  left: 0;
  top: calc(50% - 140px);
  bottom: 0;
  height: 281px;
  width: 369px;
  border-radius: 14px;
  z-index: 6;
  box-shadow: 4px 15px 20px 10px rgba(71, 101, 107, 0.25);
  justify-content: center;
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px 0 0 0;
    border-radius: 10px;
    width: 54px;
    height: 64px;
    object-fit: cover;
    font-size: 18px;
    font-weight: 800;
    font-style: normal;
    color: $white;
  }
  .name{
    margin: 20px 0 0 0;
    max-width: 186px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    text-align: center;
  }
  .description{
    margin: 16px 0 0 0;
    font-weight: 500;
    font-size: 14px;
    color: #09323A80;
    letter-spacing: 0;
    text-align: center;
    line-height: 18px;
    text-align: center;
  }

  //img{filter: drop-shadow(1px 2px 1px #000000a1);}
  .buttons{
    margin: 28px 0 30px 0;
    display: flex;
    > div{
      img{margin-right: 15px;}
      margin: 0 10px;
      padding: 0 15px;
      min-width: 91px;
    }
  }

}

.files-widget{
  .header{
    border-bottom: 1px solid $standardborder;
  }
  .options{
    display: flex;
    position: fixed;
    backdrop-filter: blur(2px);
    background: rgba(9,50,58,0.8);
    box-shadow: 4px 15px 20px 0 rgba(71, 101, 107, 0.25);
    border-radius: 10px;
    width: 300px;
    height: 65px;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: 600;
    font-style: normal;
    color: $white;
    letter-spacing: 0;
    font-size: 15px;
    > div{
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span{
        margin-left: 15px;
      }
      &:hover{
        background: #ffffff1a;
      }
    }
  }
  .checkbox{
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    border-radius: 3px;
    margin: 0 35px 0 45px;
    z-index: 4;
    &.active{
      background: #0AC9EF;
      background-image: url(/images/basic/VerifiedWhite.svg);
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid transparent;
    }
    &.inactive{
      border: 1px solid #DAE1E3;
      background: #ffffffa8;
    }
  }
  &.full{
    width: $doublewidth;
    display: flex;
    margin-bottom: 25px;
    min-height: calc(100vh - 128px);
    height: fit-content;
    .body{
      min-height: calc(100vh - 224px);
    }
    .mediagrid{
      height: fit-content;
      width: 100%;
      grid-template-columns: repeat(auto-fill, 113px);
      grid-template-rows: repeat(3, 113px);
      min-width: 1040px;
      .file{
        &:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
          .checkbox{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .duration{
            right: 14px;
            bottom: 14px;
          }
          .camera{
            top: 14px;
            left: 14px;
            border-radius: 8px;
          }
        }
        &:nth-child(6) {
          grid-column-start: 7;
          grid-column-end: 9;
          grid-row-start: 1;
          grid-row-end: 3;
          .checkbox{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .duration{
            right: 14px;
            bottom: 14px;
          }
          .camera{
            top: 14px;
            left: 14px;
            border-radius: 8px;
          }
        }

        &:nth-child(9) {
          grid-column-start: 4;
          grid-column-end: 6;
          grid-row-start: 2;
          grid-row-end: 4;
          .checkbox{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .duration{
            right: 14px;
            bottom: 14px;
          }
          .camera{
            top: 14px;
            left: 14px;
            border-radius: 8px;
          }
        }
        &:nth-child(25) {
          grid-column-start: 2;
          grid-column-end: 4;
          grid-row-start: 5;
          grid-row-end: 7;
          .checkbox{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .duration{
            right: 14px;
            bottom: 14px;
          }
          .camera{
            top: 14px;
            left: 14px;
            border-radius: 8px;
          }
        }
        &:nth-child(29) {
          grid-column-start: 6;
          grid-column-end: 8;
          grid-row-start: 5;
          grid-row-end: 7;
          .checkbox{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .duration{
            right: 14px;
            bottom: 14px;
          }
          .camera{
            top: 14px;
            left: 14px;
            border-radius: 8px;
          }
        }
        &:nth-child(42) {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 8;
          grid-row-end: 10;
          .checkbox{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .duration{
            right: 14px;
            bottom: 14px;
          }
          .camera{
            top: 14px;
            left: 14px;
            border-radius: 8px;
          }
        }
        &:nth-child(47) {
          grid-column-start: 7;
          grid-column-end: 9;
          grid-row-start: 8;
          grid-row-end: 10;
          .checkbox{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .duration{
            right: 14px;
            bottom: 14px;
          }
          .camera{
            top: 14px;
            left: 14px;
            border-radius: 8px;
          }
        }
      }
    }
    .header .selector{
      width: 340px;
      height: 46px;
      background: $gray;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .top{
        width: 165px;
        box-shadow: 0 3px 6px 0 rgba(71, 101, 107, 0.12);
        border-radius: 25px;
        background: #fff;
        height: 80%;
        top: 10%;
        left: 5px;
        position: absolute;
        bottom: 0;
        transition: all 0.5s ease;
      }
      .tab{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        cursor: pointer;
        z-index: 2;
        img{
          display: none;
        }
        span{
          padding: 0 27px;
          margin-left: 12px;
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $blackclassic;
          letter-spacing: 0;
        }
      }
    }
    .body{
      width: 100%;
      .docsgrid{
        display: flex;
        flex-direction: column;
        width: calc(100% - 2px);
        .listempty{
          border: 1px solid transparent;
          background: transparent;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 400px;
          justify-content: center;
          align-items: center;
          text-align: center;
          img{
            width: 220px;
          }
          span{
            margin-top: 15px;
            opacity: 0.4;
            font-weight: 600;
            font-style: normal;
            font-size: 14px;
            width: 100%;
          }
          &::after{
            display: none;
          }
        }
        .file {
          position: relative;
          height: 75px;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0.16px;
          color: $black;
          justify-content: space-between;
          border-bottom: 1px solid $standardborder;
          cursor: pointer;
          &::after{
            content: '';
            background: $white;
            position: absolute;
            left: 0px;
            width: 108px;
            bottom: -1px;
            height: 1px;
          }
          &:hover{
            background: $shadow;
            .checkbox.inactive{
                background: #FFFFFF;
                border: 1px solid #A2B5B9;
                box-shadow: 0 0 0 4px rgba(213,225,228,0.40);
            }
          }
          &:first-child{
            cursor: default;
            span{
              color: #09323A80;
            }
            &:hover{
              background: transparent;
            }
            span.filetype {
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                margin: 0 32px 0 15px;
              }
            }

          }
          img.filetype{
            width: 38px;
            display: flex;
            align-items: center;
            margin: 0 25px 0 10px;
            justify-content: center;
          }
          .name{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: inherit;
            display: flex;
            align-items: center;
            height: 100%;
            font-weight: 600;
            font-style: normal;
            text-transform: capitalize;
            &:hover{
              text-decoration: underline;
            }

          }
          .date{
            min-width: 100px;
            max-width: 100px;
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0 10px;
            color: #09323A80;

          }
          .uploaded{
            display: flex;
            align-items: center;
            min-width: 150px;
            max-width: 150px;
            span{
              color: #09323A80;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            img {
              opacity: 1;
              border-radius: 8px;
              width: 30px;
              height: 30px;
              margin-right: 15px;
            }
            margin: 0 10px;
          }
          .fileoptions{
            width: 18px;
            margin: 0 20px;
            display: flex;
            align-items: center;
            border: 1px solid $standardborder;
            padding: 12px 7px;
            border-radius: 6px;
            cursor: pointer;
            &.noborder{
              border: 0 solid transparent;
            }
            cursor: pointer;
            &:hover {
              background: $white;
            }
          }
          .list{
            display: block;
            position: absolute;
            background: $white;
            border: 1px solid $standardborder;
            right: 0px;
            top: 30px;
            border-radius: 10px;
            width: 159px;
            z-index: 40;
            &:hover {
              display: block;
            }
            > div{
              padding: 17px 20px;
              color: $blackclassic;
              font-size: 15px;
              letter-spacing: 0.13px;
              font-weight: 600;
              font-style: normal;
              cursor: pointer;
              img{
                margin-right: 20px;
              }
              &:hover{
                background: #eef2f36b;
              }
            }
          }
/*
          .{
            position: absolute;
            display: flex;
            width: 150px;
            background: $white;
            color: $black;
            border: 1px solid $standardborder;
            border-radius: 10px;
            box-shadow: 3px 6px 9px 5px rgba(154, 175, 180, 0.08);
            font-weight: 600;
            font-style: normal;
            right: 18px;
            top: 56px;
            z-index: 3;
            flex-direction: column;
            &:hover{
              display: flex;
            }
            &::after{
              content: '';
              z-index: -1;
              position: absolute;
              top: -20px;
              bottom: -20px;
              left: -20px;
              right: -20px;
            }
            span{
              overflow: hidden;
              padding: 15px;
              cursor: pointer;
              margin-left: 0;
              border-radius: 10px;
              &:hover{
                background: #0000000a;
              }
            }
          }
          */

        }
      }
    }
  }
  .mediagrid{
    display: grid;
    grid-template-columns: repeat(auto-fill, 96px);
    grid-template-rows: repeat(3, 96px);
    grid-gap: 16px;
    margin: 25px;
    -webkit-animation: slide 2s 2;
    animation: slide 2s 2;

    .progressRing{
      position: absolute;
      div{
        border: 7px solid #F7F9FB;
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        background: $white;
        border-radius: 30px;
        position: absolute;
        font-size: 16px;
        font-weight: bold;
        color: $black;

      }
    }

    .file{
      width: 100%;
      height: 100%;
      min-width: 90px;
      min-height: 90px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &.empty{
        background: #f2f6f799;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        &:after{
          background: transparent;
        }
        &:hover{
          &:after {
            background: transparent;
          }
        }
        img{
          width: 34px;
          height: 39px;
          border-radius: 0;
          cursor: default;
        }
      }
      &:after {
        position: absolute;
        pointer-events: none;
        content:"";
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        top: 2px;
        left: 2px;
        border-radius: 12px;
        background: #264a511a;
      }
      &:hover, &.active{
        .checkbox{
          opacity: 1;
        }
        &:after {
          position: absolute;
          pointer-events: none;
          content:"";
          height: calc(100% - 4px);
          width: calc(100% - 4px);
          top: 2px;
          left: 2px;
          border-radius: 12px;
          background: #264a5133;
        }
      }
      .checkbox{
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
        opacity: 0;
      }

      svg{
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        top: 0;
      }

      &.active .image{
        border: 2px solid #0AC9EF;
      }
      .image{
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 2px solid transparent;
        object-fit: cover;
        border-radius: 14px;
        cursor: pointer;

      }
      .duration{
        position: absolute;
        right: 8px;
        bottom: 8px;
        font-size: 10px;
        color: white;
        letter-spacing: 0.4px;
        background: #09323ab3;
        border-radius: 8px;
        padding: 6px;
        font-weight: 500;
        font-style: normal;
      }
      .camera{
        position: absolute;
        top: 8px;
        left: 8px;
        background: #09323ab3;
        border-radius: 6px;
        padding: 6px;
      }
    }
  }
  &.preview{
    width: $doublewidth;
    .headerclassic{
      display: flex;
      height: 90px;
      align-items: center;
      border-bottom: 1px solid $standardborder;
      .title{
        display: none;
      }
      .whiteButton{
        min-width: 117px;
        margin-right: 20px;
      }
      .selector{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .bottom{
          width: 220px;
          height: 2px;
          background: $blueclassic;
          position: absolute;
          bottom: -1px;
          transition: all 0.5s ease 0s;
        }
        .tab{
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 100%;
          width: 208px;
          img{
            margin-right: 18px;
          }
          span{
            font-weight: 600;
            font-style: normal;
            font-size: 16px;
            color: $blackclassic;
            letter-spacing: 0;
          }
        }
      }
    }
    .body{
      max-height: 366px;
      min-height: 366px;
      @keyframes slideright {
        0% {  opacity: 0;margin-left: calc(-55% + 25px); }
        25%{opacity: 0;}
        100% { opacity: 1;margin-left: 25px; }
      }
      @keyframes slideright2 {
        0% {  opacity: 0; }
        25%{ opacity: 0; }
        100% { opacity: 1; }
      }
      @keyframes slideleft {
        0% {  opacity: 1;margin-left: 25px; }
        100% { opacity: 0;margin-left: calc(-55% + 25px); }
      }
      @keyframes slideleft2 {
        0% {  opacity: 1; }
        75% { opacity: 0;}
      }
      .docsgrid.visible {
        animation: slideright 0.5s;
        opacity: 1;
      }
      .mediagrid.visible {
        animation: slideright2 0.5s;
        opacity: 1;
      }
      .docsgrid.hidden {
        opacity: 0;
        animation: slideleft 0.5s;
        margin-left: calc(-55% + 25px);
      }
      .mediagrid.hidden {
        animation: slideleft2 0.5s;
        opacity: 0;
      }

      .mediagrid{
        min-width: calc(55% - 50px);
        max-width: calc(55% - 50px);
        .file{
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
            .checkbox{
              position: absolute;
              top: 20px;
              right: 20px;
            }
            .duration{
              right: 14px;
              bottom: 14px;
            }
            .camera{
              top: 14px;
              left: 14px;
              border-radius: 8px;
            }
          }
          &:nth-child(6) {
            grid-column-start: 4;
            grid-column-end: 6;
            grid-row-start: 2;
            grid-row-end: 4;
            .checkbox{
              position: absolute;
              top: 20px;
              right: 20px;
            }
            .duration{
              right: 14px;
              bottom: 14px;
            }
            .camera{
              top: 14px;
              left: 14px;
              border-radius: 8px;
            }
          }
        }
        .fileoptions{
          cursor: pointer;
          padding: 3px 7px;
          border-radius: 8px;
          &:hover {
            background: #E8EAEC90;
          }

        }
        .list{
          display: block;
          position: absolute;
          background: $white;
          border: 1px solid $standardborder;
          right: 0px;
          top: 30px;
          border-radius: 10px;
          width: 159px;
          z-index: 1;
          &:hover {
            display: block;
          }
          > div{
            padding: 17px 20px;
            color: $blackclassic;
            font-size: 15px;
            letter-spacing: 0.13px;
            font-weight: 600;
            font-style: normal;
            cursor: pointer;
            img{
              margin-right: 20px;
            }
            &:hover{
              background: #eef2f36b;
            }
          }
        }

      }
      .docsgrid{
        display: flex;
        margin: 25px 10px 25px 25px;
        min-width: calc(55% - 35px);
        max-width: calc(55% - 35px);
        height: 321px;
        flex-wrap: wrap;
        .progressRing{
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;
          div{
            border: 7px solid #F7F9FB;
            width: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            background: $white;
            border-radius: 30px;
            position: absolute;
            font-size: 16px;
            font-weight: bold;
            color: $black;

          }
        }

        > img {
          height: 65px;
          min-width: 130px;
          max-width: 130px;
          border-radius: 10px;
          margin: 21px 7px;
        }

        .checkbox{
          display: none;
        }

        .file{
          display: flex;
          position: relative;
          flex-direction: column;
          min-width: 168px;
          max-width: 168px;
          height: 149px;
          background: $white;
          border: 1px solid $standardborder;
          border-radius: 10px;
          margin-right: 15px;
          margin-bottom: 15px;
          cursor: pointer;
          &:hover{
            background: $shadow;
          }
          &.empty{
            border: 1px solid #ccd6d94a;
            background: $white;
            cursor: default;
            &::after{
              content: '';
              border-bottom: 1px solid #ccd6d94a;
              transform: rotateZ(45deg);
              z-index: 2;
              position: absolute;
              top: -6px;
              right: -13px;
              width: 35px;
              height: 20px;
              background: $white;
            }
            span{
              background: #F4F6F9;
              border-radius: 4px;
              height: 10px;
              width: 60%;
              &:last-child{
                opacity: 1;
                width: 40%;
              }
            }

          }
          //border-top-right-radius: 20%;
          svg{
            position: absolute;
            transform: rotateZ(-90deg);

          }
          &::after{
            content: '';
            border-bottom: 1px solid $standardborder;
            transform: rotateZ(45deg);
            z-index: 2;
            position: absolute;
            top: -6px;
            right: -13px;
            width: 35px;
            height: 20px;
            background: white;
          }
          align-items: flex-start;
          .filetype{
            height: 49px;
            margin-top: 25px;
            margin-left: 25px;

          }
          .fileoptions{
            padding: 10px 5px;
            border-radius: 8px;
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            &:hover {
              background: $white;
            }
          }

          .list{
            display: block;
            position: absolute;
            background: $white;
            border: 1px solid $standardborder;
            top: 45px;
            right: 15px;
            border-radius: 10px;
            width: 159px;
            z-index: 40;
            &:hover {
              display: block;
            }
            > div{
              padding: 17px 20px;
              color: $blackclassic;
              font-size: 15px;
              letter-spacing: 0.13px;
              font-weight: 600;
              font-style: normal;
              cursor: pointer;
              img{
                margin-right: 20px;
              }
              &:hover{
                background: #eef2f36b;
              }
            }
          }

          > span{
            margin-left: 25px;
            width: 115px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            &.name{
              margin-top: 15px;
              font-weight: 600;
              font-style: normal;
              font-size: 14px;
              line-height: 14px;
              color: $black;
              letter-spacing: 0.16px;
              text-transform: capitalize;

            }
            &.date{
              margin-top: 7px;
              opacity: 0.5;
              font-weight: 500;
            	font-style: normal;
              font-size: 13px;
              line-height: 14px;
              color: $black;
              letter-spacing: 0.15px;
            }
          }
        }
      }

      .dragdrop{
        position: absolute;
        right: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        flex-direction: column;
        width: calc(45% - 25px);
        background: $white;
        height: 314px;
        margin: 25px 25px 25px 0;
        border-radius: 10px;
        border: 1px dashed $dragline;
        &.empty{
          div{
            width: 90px;
            height: 90px;
            background: $loading;
            margin-bottom: 50px;
            opacity: 0.05;
            border-radius: 20px;
          }
          span{
            width: 200px;
            height: 15px;
            margin: 0 15px;
            background: $loading;
            opacity: 0.08;
            border-radius: 20px;
          }

        }
        > span{
          line-height: 18px;
          letter-spacing: 0;
          font-size: 14px;
          color: #09323A80;
          max-width: 240px;
          line-height: 25px;
          width: 80%;
          margin-bottom: 15px;
        }
        > img{
          height: 100px;
          margin-top: 25px;
          margin-bottom: 15px;
        }
        .buttons{
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          margin-bottom: 36px;
          div{
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 187px;
            height: 46px;
            border-radius: 25px;
            margin: 5px;
            color: $white;
            font-weight: 600;
            font-style: normal;
            font-size: 15px;
            letter-spacing: 0;
            img{
              position: absolute;
              left: 18px;
            }
            span{
            }
            &.paste{
              background: $violetclassic;
            }
            &.blueButton{
              width: 100px;

            }
          }
        }
        &.activate {
          background: $gray;
        }
        &.deactivate {
          //border:  $green;
        }
      }
    }
  }
}
