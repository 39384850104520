.notificationList {
  position: absolute;
  right: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  top: 80px;
  max-height: 545px;
  min-height: 340px;
  width: 395px;
  background: $white;
  border-radius: 16px;
  box-shadow: 4px 15px 35px 10px #47656b1a;
  border: 1px solid $standardborder;
  overflow: auto;
  .list{
    overflow: auto;
    width: 380px;
    margin-bottom: 29px;
    &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #E1E7E9;
      border-radius: 4px;
    }

  }
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 65px;
    max-height: 65px;
    width: 333px;
    border-bottom: 0.5px solid $standardborder;
    span{
      font-weight: bold;
      font-style: normal;
      font-size: 16px;
      margin-bottom: -2px;
      letter-spacing: 0;
      color: $blackclassic;
    }
    div{
      font-weight: bold;
      font-style: normal;
      font-size: 13px;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 32px;
      background: $blueclassic;
      color: $white;
      border-radius: 12px;
      &.off{
        background: #E9F1F3;
        color: $blackclassic;

      }
    }
  }
  .empty{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    height: -webkit-fill-available;
    justify-content: center;
    margin-top: 35px;
    span{
      width: 200px;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      color: $blackclassic;
      line-height: 18px;
      letter-spacing: 0;
      margin-top: 30px;
      text-align: center;
    }

  }
  .notification {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 355px;
    margin: 4px 12px;
    cursor: pointer;
    border-radius: 12px;
    &:hover{
      background: #EEF2F380;
    }
    > img{
      width: 40px;
      margin: 12px 15px 12px 12px;
      border-radius: 10px;
    }
    .center{
      width: 255px;
      margin-top: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      .inner{
        min-height: 14px;
        max-height: 90px;
        overflow: hidden;
      }
      span{
        opacity: 0.8;
        color: $black;
        letter-spacing: 0.16px;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        &.bold{
          font-weight: bold;
          font-style: normal;
          &:hover{
            text-decoration: underline;
          }

        }
      }
      .date{
        margin-top: 8px;
        font-weight: 500;
        font-style: normal;
        font-size: 13px;
        color: $black;
        letter-spacing: 0.13px;
        opacity: 0.4;
        margin-bottom: 12px;
      }
    }
    .unread{
      position: absolute;
      right: 15px;
      top: 17px;
      background: #FB8C72;
      border-radius: 4px;
      width: 8px;
      height: 8px;
    }
    .icon{
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $chatline;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      img{
        width: 24px;
      }
    }
  }
}
