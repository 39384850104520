.modal {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.70);
  backdrop-filter: blur(2px);
  &.dark { background: #0f2529cc; }
  &.hidden { display: none; }
  > div {
    display: flex;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    flex-direction: column;
    position: absolute;
    border-radius: 16px;
    background: $white;
    border: 1px solid $standardborder;
    box-shadow: 5px 20px 35px 10px $shadowmodal;
    .header{
      min-height: 70px;
      max-height: 70px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid $standardborder;
      .tab{
        min-height: 70px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span{
          margin-right: 25px;
        }
        &.selected{
          border-bottom: 2px solid $blueclassic;
        }
      }
      img{
        width: 22px;
        margin-left: 27px;
        margin-right: 17px;
      }
      span{
        width: 100%;
        margin-bottom: -2px;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        letter-spacing: 0;
        color: $blackclassic;
      }
      .close{
        cursor: pointer;
        position: absolute;
        right: 0px;
        margin: 22px;
        min-width: 26px;
        min-height: 26px;
        background: rgba(154,175,180,0.60);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          margin: 0;
          width: 10px;
          height: 10px;
        }
      }
    }
    > .input{
      display: flex;
      align-items: center;
      min-height: 57px;
      border-bottom: 1px solid $chatine;
      font-family: 'Gilroy', Arial, sans-serif;
      >img{
        margin-left: 27px;
        margin-right: 17px;
        min-width: 22px;
        max-width: 22px;
      }
      >input{
        border: none;
        background: transparent;
        font-size: 15px;
        letter-spacing: 0;
        color: $blackclassic;
        font-weight: 500;
        font-style: normal;
        &::placeholder{
          opacity: 0.8;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
    .description{
      font-family: 'Gilroy', Arial, sans-serif;

    }
    .footer{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100px;
      z-index: 4;
      border-top: 1px solid $chatine;
      .whiteButton{
        font-size: 15px;
        width: 124px;
      }
      .blueButton{
        font-size: 15px;
        width: 154px;
        margin: 10px 25px 10px 12px;
      }
    }
  }
  .inviteUser{
    width: 777px;
    height: auto;
    position: relative;
    align-items: center;
    top: 50px;
    margin-bottom: 20px;
    .header{
      justify-content: flex-start;
    }
    .footer{
      position: sticky;
      width: 100%;
      bottom: 0px;
      background: $white;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

  }
}
.widget-newproject.newProject{
  border-radius: 16px;
  background: $white;
  border: 1px solid $standardborder;
  top: 25px;
  .header{
    min-height: 70px;
    max-height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $chatine;
    .tab{
      min-height: 70px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span{
        margin-right: 25px;
      }
      &.selected{
        border-bottom: 2px solid $blueclassic;
      }
    }
    img{
      margin-left: 25px;
      margin-right: 15px;
    }
    span{
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0;
      color: $blackclassic;
    }
  }
  .footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100px;
    z-index: 7;
    border-top: 1px solid $chatine;
    .whiteButton{
      font-size: 15px;
      width: 124px;
    }
    .blueButton{
      font-size: 15px;
      width: 154px;
      margin: 10px 25px 10px 12px;
    }
  }

}
div.newProject{
  width: 777px;
  height: auto;
  position: relative;
  top: 50px;
  margin-bottom: 20px;
  &::after{
    content: '';
    position: absolute;
    bottom: -25px;
    height: 25px;
    width: 100%;
    background: transparent;
  }
  .userslist .list{
    flex-wrap: wrap;
    .user{
      margin: 0px 10px 10px 0;
    }
  }
  .header{
    //justify-content: flex-start;
  }
  > .name{
    display: flex;
    flex-direction: column;
    align-items: center;
    .letter{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      margin-top: 60px;
      margin-bottom: 53px;
      width: 90px;
      height: 90px;
      font-size: 48px;
      font-weight: 800;
      font-style: normal;
      color: $white;
    }
    .input{
      display: flex;
      margin-left: 20px;
      min-width: 280px;
      border: 0;
      border: none;
      background: transparent;
      font-size: 20px;
      letter-spacing: 0;
      color: $blackclassic;
      font-weight: 600;
      font-style: normal;
      &::placeholder{
        //opacity: 0.5;
        font-weight: 500;
        font-style: normal;
      }

    }
  }
  .uploadlogo{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    width: calc(90% - 60px);
    align-items: center;
    margin-left: 5%;
    border-bottom: 1px solid $borderproject;
    .blueButton{
      width: 276px;
      z-index: 0;
    }
    .description{
      width: 252px;
      opacity: 0.4;
      font-size: 14px;
      color: $black;
    }

  }
  .information{
    width: 90%;
    margin-left: 5%;
    margin-top: 25px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column-reverse;
    span{
      font-size: 18px;
      color: $blackclassic;
      line-height: 24px;
      letter-spacing: 0;
      font-weight: bold;
      font-style: normal;
    }
    .description{
      min-height: 210px;
      margin: 10px 0;
    }
  }
  .invitationList{
    &::after{
      content: 'OR';
      z-index: 0;
      position: absolute;
      text-align: center;
      color: $blackclassic;
      margin-top: -44px;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      width: 35px;
      height: 20px;
      background: white;
      font-weight: 800;
      font-style: normal;
      font-size: 14px;
    }
  }
  .footer{
    position: sticky;
    bottom: 0px;
    z-index: 5;
    background: $white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .redButton{
      margin: 10px;
    }
  }
}
