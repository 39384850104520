.mainScreen div.profile-widget.widget{
  display: flex;
  width: $doublewidth;
  height: -webkit-fill-available;
  background: $white;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 4px 10px 15px 0 $shadow;
  margin-top: 25px;
  margin-bottom: 25px;
  flex-direction: row;
  .footer{
    position: absolute;
    bottom: 0;
    width: calc(100% - 80px);
    height: 87px;
    background: white;
    border-top: 1px solid $chatline;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 40px;
    div{
      margin: 8px;
      width: 150px;
    }
  }

  .leftpanel{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 87px;
    border-right: 1px solid $standardborder;
    .crop{
      width: 100%;
       > div:first-child{
        height: 300px;
        position: relative;
        width: 100%;
      }
    }
    .photo{
      margin-top: 70px;
      margin-bottom: 45px;
      width: 110px;
      height: 110px;
      border-radius: 18px;
    }
    .fullname{
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      color: $black;
      letter-spacing: 0;
      margin-bottom: 10px;
      text-align: center;
    }
    .role{
      opacity: 0.64;
      font-size: 14px;
      font-weight: normal;
    	font-style: normal;
      color: $black;
      padding-bottom: 40px;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $chatline;
    }
    .blueButton{
      margin-top: 30px;
      font-size: 14px;
    }
    .description{
      margin-top: 20px;
      text-align: center;
      color: $black;
      max-width: 80%;
      letter-spacing: 0;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
    }
  }
  .rightpanel{
    overflow-y: auto;
    overflow-x: hidden;
    width: 774px;
    margin-bottom: 87px;
    margin-top: 86px;
    .header{
      position: absolute;
      top: 0;
      background: white;
      z-index: 2;
      justify-content: flex-start;
      border-bottom: 1px solid $chatline;
      div{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        cursor: pointer;
        //border-bottom: 2px solid $blueclassic;
        span{
          margin-left: 15px;
          font-weight: bold;
          font-style: normal;
          font-size: 15px;
          color: $black;
          letter-spacing: 0;

        }
      }
    }
    .portfolio{
      margin: 30px 40px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $chatline;
      padding: 20px 0;
      .title{
        font-size: 20px;
        color: $black;
        letter-spacing: 0;
        font-weight: 600;
        font-style: normal;
      }
      .description{
        margin-top: 12px;
        opacity: 0.4;
        font-size: 14px;
        color: $black;
        letter-spacing: 0;
        font-weight: 500;
        font-style: normal;
      }

    }
    .curriculum{
      height: 416px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $white;
      background-image: url(/images/landing/LeftBack2.svg), url(/images/landing/RightBack2.svg);
      background-position: 25% 130px, 75% 118px;
      background-repeat: no-repeat, no-repeat;
      .description{
        margin-top: 20px;
        text-align: center;
        color: $black;
        letter-spacing: 0;
        opacity: 0.4;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
      }
      .resumelogo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 146px;
        height: 146px;
        border-radius: 73px;
        background: $gray;
        img{
          height: 70px;
        }
        span{
          margin-top: 2px;
          font-weight: bold;
          font-style: normal;
        }
      }
      .resumename{
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        text-align: center;
        color: $black;
        margin-top: 28px;
        margin-bottom: 25px;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .resumedelete{
        width: 144px;
        height: 40px;
      }
      > .text{
        font-weight: 600;
        font-style: normal;
        font-size: 14px;
        text-align: center;
        opacity: 0.4;
        color: $black;
        line-height: 18px;
      }
      .crop{
        position: relative;
        width: 300px;
        height: 300px;
        background: $white;
        border-radius: 25px;
        overflow: hidden;
      }
      .photo{
        border-radius: 8px;
        box-shadow: 10px 20px 35px 10px #9aafb41f;
        background: $white;
        img {
          width: 75px;
          height: 75px;
          margin: 7px 7px 21px 7px;
        }
      }
      .blueButton{
        margin-top: 35px;
        width: 144px;
      }
      .or{
        text-align: center;
        z-index: 1;
        background: $white;
        width: 76px;
        margin-top: 10px;
        font-weight: 800;
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0;
      }
      .ownlogo{
        border-top: 1px solid #9AAFB440;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 396px;
        position: relative;
        &::after{
          content: 'OR';
          z-index: 0;
          position: absolute;
          text-align: center;
          color: $blackclassic;
          margin-top: -10px;
          right: 0;
          left: 0;
          margin-left: auto;
          margin-right: auto;
          width: 35px;
          height: 20px;
          background: white;
          font-weight: 800;
          font-style: normal;
          font-size: 14px;
        }
        .blueButton{
          width: 276px;
        }
      }
    }
    .yourinput{
      opacity: 0.7;
      font-size: 15px;
      color: $blackclassic;
      font-weight: 500;
      font-style: normal;
      font-family: 'Gilroy', Arial, sans-serif;
      letter-spacing: 0;
      line-height: 20px;
      width: calc(100% - 80px);
      min-height: 100px;
      margin: 0 40px;
      border: none;
      border-bottom: 1px solid $chatline;
      &::placeholder{
        font-weight: 500;
        font-style: normal;
        opacity: 0.6;
        color: $black;
        letter-spacing: 0.38px;
        font-size: 14px;
      }
    }
    .basicinfo{
      display: flex;
      flex-wrap: wrap;
      margin: 20px;
      > div{
        width: calc(50% - 40px);
        padding: 20px;
        > span{
          font-weight: 500;
          font-style: normal;
          opacity: 0.4;
          color: $black;
          letter-spacing: 0;
          margin-left: 25px;
        }
        > div{
          margin-top: 12px;
        }
      }
    }
    .technologies{
      border-top: 1px solid  #dfe4e5;
      flex-direction: row;
      margin: 10px 0;
      padding: 0 20px;
      align-items: center;
      width: calc(100% - 40px);
      position: relative;
      .newtechnology.open{
        display: flex;
        bottom: 95px;
        right: 30px;
        height: 300px;
      }
    }
    .ratetitle{
      width: calc(100% - 40px);
      border-top: 1px solid  #dfe4e5;
      padding: 10px 20px;
      margin: 10px 0;
      display: flex;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0;
      align-items: center;
      img{
        height: 18px;
      }
      span{
        margin-left: 12px;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $blackclassic;
        letter-spacing: 0;
        width: 100%;
      }

      div{
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 62px;
        height: 38px;
        border: 1px solid $borderproject;
        border-radius: 25px;
        margin-left: 8px;
        cursor: pointer;
        &.selected {
          border: 0px solid $borderproject;
          background: $green;
          color: $white;
        }
      }
    }
    .ratenumber{
      margin: 10px 0;
      border-top: 1px solid  #dfe4e5;
      padding: 0 20px;
      align-items: center;
      width: calc(100% - 40px);
      display: flex;
      img{
        height: 18px;
      }
      span{
        margin-left: 12px;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $blackclassic;
        letter-spacing: 0;
        width: -webkit-fill-available;
      }

      .rate{
        margin-top: 35px;
        margin-bottom: 20px;
        width: 100%;
        .value{
          font-weight: 600;
          font-style: normal;
          opacity: 0.85;
          color: $black;
          letter-spacing: 0;
          font-size: 34px;
        }
        .freq{
          font-weight: 500;
          font-style: normal;
          opacity: 0.35;
          color: $black;
          letter-spacing: 0;
          font-size: 15px;
          margin-left: 5px;
        }
      }
    }
    .slider {
      width: 100%;
      margin-bottom: 36px;
      background: #d3d3d333;
      &::-webkit-slider-thumb {
        background: #6EF4B7;
        box-shadow: 4px 6px 10px 2px #0ac9ef33;
      }
      &::-moz-range-thumb {
        background: #05D677;
      }
    }

    .payment {
      border-top: 1px solid  #dfe4e5;
      .subheader{
        margin: 40px 0 40px 45px;
        display: flex;
        align-items: center;
        span{
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $blackclassic;
          letter-spacing: 0;
          margin-left: 15px;
        }
      }
    }
  }
}

.newcard{
  z-index: 5;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  padding: 25px;
  width: fit-content;
  height: fit-content;
  position: absolute;
  border-radius: 16px;
  background: $white;
  border: 0.5px solid $standardborder;
  box-shadow: 5px 20px 35px 10px $shadowmodal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width: 50%;
  }
  span{
    font-weight: bold;
    font-size: 24px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    padding-bottom: 35px;
    width: 100%;
    border-bottom: 1px solid $standardborder;
    &.name{
      color:#05D677 ;
      margin-top: 15px;
      margin-bottom: 10px;
      padding-bottom: 0px;
      border-bottom: 0px solid $standardborder;
    }
  }
  .buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    padding-bottom: 0;
    height: 46px;
    margin: 0;
    border-top: 1px solid $standardborder;
    width: 100%;
    div{
      width: 85px;
    }

  }
  .description{
    width: 310px;
    line-height: 25px;
    opacity: 0.8;
    font-weight: 500;
    font-size: 14px;
    color: #47656B;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
  }
  .cardpay {
    margin-top: 25px;
    margin-bottom: 35px;

  }
  .information{
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    top: 10px;
    position: absolute;
    right: 10px;
    color: $chatline;
    border: 1px solid $chatline;
    padding: 3px;
    border-radius: 17px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover  .errortag{
      display: flex;
    }
    .errortag{
      color: $white;
      background: #09323AD9;
      position: absolute;
      top: -160px;
      left: -125px;
      width: 256px;
      font-size: 14px;
      letter-spacing: 0.14px;
      line-height: 20px;
      top: 25px;
      text-align: center;
      display: none;
      position: absolute;
      border: 0.5px solid $standardborder;
      box-shadow: 4px 15px 20px 10px rgba(206,213,220,0.50);
      border-radius: 16px 16px 16px 0 0 0 16px;
      padding: 10px;
      font-weight: 500;
      &::after{
        background: #09323AD9;
      }
    }


  }
  &.integrated{
    padding: 10px;
    display: flex;
    position: relative;
    border: 1px solid #DBE3E5;
    box-shadow: 2px 3px 8px 0 rgba(71, 101, 107, 0.06);
    margin: 0 20px;
    img{
      height: 60px;
    }
  }
  >label{
    width: 100%;
    margin-bottom: 25px;
    font-style: normal;
    font-weight: 600;
    color: $blackclassic;
    font-size: 14px;
    .StripeElement{
      margin-top: 12px;
      font-style: normal;
      font-weight: 500;
    }
  }
  >div, >button{
    margin: 5px;
  }
  >button{
    border-width: 0;
  }



}

.cards{
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  .card{
    position: relative;
    width: 210px;
    height: 152px;
    margin: 16px;
    border-radius: 12px;
    border: 1px solid $chatline;
    cursor: pointer;
    &:hover:after {
      position: absolute;
      pointer-events: none;
      content:"";
      height: 100%;
      width: 100%;
      top:0;
      left:0;
      border-radius: 14px;
      background: #09323A08;
    }
    &.create{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span{
        font-weight: 600;
        font-style: normal;
        color: $blackclassic;
        letter-spacing: 0;
        font-size: 15px;
      }
    }
    &.mine{
      box-shadow: 3px 10px 25px 0 rgba(131, 148, 153, 0.40);
      &:hover .closecard{
        display: block;
      }
      .closecard{
        cursor: pointer;
        display: none;
        position: absolute;
        padding: 5px;
        top: -10px;
        right: -10px;
        background: #B4C9CE;
        border-radius: 12px;
      }
      span{
        color: $white;
        &.exp{
          position: absolute;
          right: 25px;
          bottom: 25px;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          letter-spacing: 1.5px;
          opacity: 0.8;
        }
        &.last4{
          position: absolute;
          right: 25px;
          bottom: 50px;
          font-weight: 600;
          font-style: normal;
          font-size: 18px;
          letter-spacing: 1.5px;
        }
        &.selected{
          position: absolute;
          right: 10px;
          top: 10px;
          font-weight: 600;
          font-style: normal;
          font-size: 11px;
          letter-spacing: 1.5px;

        }
      }
    }
  }
}
