.classList{
  height: calc(100% - 79px);
  display: flex;
  border-top: 1px solid $standardborder;
  flex-direction: column;
  background: $white;
  pointer-events: auto;
  &.empty{
    .menu{
      div{
        margin: 0 15px;
        height: 10px;
        width: 50px;
        opacity: 0.08;
        border-radius: 4px;
        background: $loading;
      }
    }
  }
  .onprogress{
    width: 220px;
    background: #09323ae6;
    border-radius: 16px;
    margin: 20px 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: fit-content;

    .right{
      position: absolute;
      cursor: pointer;
      top: 0;
      bottom: 0;
      height: 25px;
      right: -25px;
      margin: auto;
    }
    .left{
      position: absolute;
      cursor: pointer;
      top: 0;
      height: 25px;
      bottom: 0;
      left: -25px;
      margin: auto;
    }
    span{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      font-weight: 500;
      font-style: normal;
      color: $white;
      font-size: 14px;
      letter-spacing: 0.23px;
      border-bottom: 1px solid #ffffff40;
      img{
        margin-right: 14px;
      }
    }
    .users{
      display: flex;
      align-items: center;
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px 0;
      img{
        width: 50px;
        height: 50px;
        border: 4px solid $dark;
        border-radius: 30px;
        margin: 3px;

      }
      .number{
        width: 50px;
        height: 50px;
        border: 4px solid $dark;
        border-radius: 30px;
        background: #4D6A70;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;

      }
    }
    .buttons{
      display: flex;
      margin-bottom: 10px;
      div{
        padding: 0 25px;
        margin: 5px;
        border: 1px solid transparent;
      }
    }

  }
  .menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    padding: 0 22px;
    align-items: center;
    position: relative;
    border-bottom: 1px solid $standardborder;
    .bottom{
      width: ($rightbar - 44)/3;
      height: 2px;
      margin-bottom: -1px;
      background: $blueclassic;
      position: absolute;
      bottom: 0;
      transition: all 0.5s ease 0s;
    }
    div{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: $rightbar/3;
      color: $graymenu;
      cursor: pointer;
      &:hover{
        font-weight: 600;
        font-style: normal;
        color: $black;
      }
      .notification{
        background: $redclassic2;
        height: 6px;
        width: 6px;
        border-radius: 3px;
        position: absolute;
        right: 0;
        top: 15px;
      }
    }
    div.select{
      font-weight: 600;
      font-style: normal;
      color: $black;
    }
  }
}
.chatList{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
      display: none;
  }
  .search{
    margin-top: 26px;
    margin-bottom: 26px;
    min-height: 46px;
    max-height: 46px;
    width: $rightbar - 44px;
    background: #F7F9FB;
    border-radius: 24px;
    display: flex;
    align-items: center;
    input{
      font-size: 14px;
      letter-spacing: 0.38px;
      color: #09323a99;
      border: 0;
      opacity: inherit;
      font-weight: 500;
    	font-style: normal;
      background: transparent;
      &::placeholder{
        color: #09323a99;
        font-size: 14px;
        letter-spacing: 0.38px;
        font-weight: 500;
      	font-style: normal;
      }

    }
    >img{
      margin-left: 20px;
      margin-right: 15px;
      opacity: 0.4;
    }
  }
  .head.empty{
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 80px);
    margin: 32px 40px;
    align-items: center;
    div{
      width: 20px;
      height: 20px;
      background: $loading;
      opacity: 0.1;
      border-radius: 20px;
    }
    span{
      width: 200px;
      height: 15px;
      margin: 0 15px;
      background: $loading;
      opacity: 0.08;
      border-radius: 20px;
    }

  }

  .list{
    display: flex;
    flex-direction: column;
    width: 290px;
    height: 100%;
    overflow: auto;
    //margin-left: 25px;
    //height: calc(100vh - 210px);
    /*
    &::-webkit-scrollbar {
        display: none;
    }
    */
    .head{
      display: flex;
      align-items: center;
      font-weight: bold;
      font-style: normal;
      margin-left: 25px;
      font-size: 15px;
      letter-spacing: 0;
      color: $black;
      padding: 50px 20px 20px 0;
      border-bottom: 0.5px solid $borderproject;
      &:first-child{
        padding-top: 30px;
      }
      img{
        width: 20px;
        padding: 0 10px;
        margin-right: 15px;
      }
    }
    >.empty{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      letter-spacing: 0;
      padding: 20px 20px 60% 20px;
      height: 100%;
      img{
        margin: 20px;
      }
      span{
        width: 152px;
        font-size: 13px;
        min-height: fit-content;
        color: $blackclassic;
        letter-spacing: 0;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        line-height: 20px;
      }
    }

  }

  .chat{
    min-height: 80px;
    width: 265px;
    padding-left: 25px;
    border-bottom: 1px solid $standardborder;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    &::before {
      content: "";
      background: white;
      position: absolute;
      bottom: -1px;
      left: 0px;
      height: 2px;
      width: 25px;
      background-color: $white;
    }
    &:hover{
      background: $hover;
    }
    &.shadow{
      .customized{
        background: $loading;
        opacity: 0.1;
        width: 40px;
        border-radius: 20px;
      }
      .center{
        justify-content: center;
        span.fullname{
          height: 12px;
          opacity: 0.1;
          border-radius: 6px;
          background: $loading;
        }
        span.message{
          height: 7px;
          opacity: 0.08;
          border-radius: 4px;
          background: $loading;
        }
      }
    }
    .online {
      background: $greenclassic;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      border: solid $white;
      position: absolute;
      left: 63px;
      margin-top: 20px;
    }
    div.logop{
      position: absolute;
      left: 48px;
      bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      border-radius: 10px;
      width: 18px;
      height: 18px;
      object-fit: cover;
      font-size: 10px;
      font-weight: 800;
      font-style: normal;
      color: $white;
      border: 2px solid #FFFFFF;
      border-radius: 4px;

    }
    img.customizedp{
      position: absolute;
      left: 48px;
      bottom: 12px;
      border-radius: 10px;
      width: 18px;
      height: 18px;
      object-fit: cover;
      border: 2px solid #FFFFFF;
      border-radius: 4px;
    }

    div.logo{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      object-fit: cover;
      font-size: 18px;
      font-weight: 800;
      font-style: normal;
      color: $white;

    }
    img.customized{
      border-radius: 10px;
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
    .chatIcon{
      margin: 15px;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 80px;
        height: 40px;
      }
    }
    .taskIcon{
      overflow: hidden;
      margin: 15px;
      border-radius: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 80px;
        height: 40px;
      }
    }
    div {
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 15px;
      width: $rightbar*0.9 - 97px;
      .fullname{
        font-size: 15px;
        opacity: 0.8;
        color: $black;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0.17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        text-transform: capitalize;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .message{
        opacity: 0.45;
        color: $black;
        font-size: 12px;
        letter-spacing: 0.12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .notification{
      background: $violet;
      font-size: 12px;
      letter-spacing: 0;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      color: $white;
      text-align: center;
      justify-content: center;
      border-radius: 10px;
      font-weight: bold;
      font-style: normal;
    }
  }
}
@keyframes slideup {
  0%{max-height: 0}
  100%{max-height: 100%}
}
@keyframes slidedown {
  0%{max-height: 100%}
  100%{max-height: 0}
}

.chatbox {
  max-height: 80vh;
  height: 800px;
  position: absolute;
  right: $rightbar + 20px;
  bottom: 10px;
  z-index: 3;
  background: $white;
  width: 415px;
  border-radius: 16px;
  border: 1px solid $standardborder;
  box-shadow: 4px 10px 15px 0 $shadow;
  display: flex;
  flex-direction: column;
  animation: 0.5s slideup;
  animation-timing-function: ease-out;
  @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
    40% {-webkit-transform: translateY(-15px) }
    60% {-webkit-transform: translateY(-10px);}
  }
  @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-15px);}
      60% {transform: translateY(-10px);}
  }
  .edcalendar{
    width: 414px;
    height: 376px;
    border-radius: 18px;
  }
  &.bounce{
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: bounce;
    animation-name: bounce;
  }
  .header{
    order: 1;
    z-index: 2;
    min-height: fit-content;
    min-height: auto;
    width: 100%;
    height: 70px;
    background: $white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $standardborder;
    .profileImg{
      width: 40px;
      height: 40px;
      margin: 15px;
      border-radius: 10px;
    }
    .letter{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 15px;
      border-radius: 10px;
      font-size: 18px;
      font-weight: 800;
      font-style: normal;
      color: $white;
    }

    .title{
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      margin-top: 2px;
      font-size: 15px;
      color: $blackclassic;
      letter-spacing: 0;
      font-weight: 600;
      font-style: normal;

    }
    .camera{
      position: absolute;
      width: 18px;
      right: 54px;
      padding: 10px;
      height: 18px;
      margin-top: 1px;
      cursor: pointer;
    }
    .gallery{
      position: absolute;
      right: 93px;
      width: 18px;
      padding: 10px;
      height: 18px;
      margin-top: 4px;
      cursor: pointer;
    }
    .close{
      position: absolute;
      right: 15px;
      padding: 10px;
      width: 14px;
      height: 14px;
      cursor: pointer;
      margin-top: 1px;
    }
  }
  .subheader{
    height: 49px;
    order: 1;
    width: 100%;
    background: $white;
    border-bottom: 1px solid $standardborder;
    display: flex;
    align-items: center;
    position: relative;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-style: normal;
      font-size: 15px;
      border-radius: 8px;
      color: #47656b9e;
      margin: 5px;
      height: 34px;
      width: 34px;
      border: 1px solid $standardborder;
    }
    img {
      height: 34px;
      width: 34px;
      border-radius: 10px;
      padding: 3px;
      &:first-child{
        margin-left: 15px;
      }
      &.users{
        width: 19px;
        padding: 3px 15px;
        border-left: 1px solid $standardborder;
        border-radius: 0px;
        position: absolute;
        right: 0;
        cursor: pointer;
      }
    }
  }
  &.inline{
    position: relative;
    bottom: 0;
    width: 100%;
    right: 0;
    border: 0;
    border-radius: 16px;
    height: -webkit-fill-available;
    max-height: 100%;
    .header{
      height: 62px;
      border-radius: 0;
      border-top: 1px solid $standardborder;
      img{
        width: 22px;
      }
    }
    .body{
      height: calc(100% - 129px);
      background: $gray;
      flex-direction: column;
      .message.sent div.text {
        background: $white;
      }
      .message.sent .image .bubble {
        background: $white;
      }
      height: 100%;
    }
    textarea{
      margin: 25px;
      width: 100%;
    }
    .input .file{
      display: none;
    }
    .user{
      display: none;

    }
    .camera{
      right: 25px;
    }
    .close{
      display: none;
    }
  }

  .options{
    background: $white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    border-radius: 16px;
    .top{
      height: 40%;
      border-bottom: 1px solid $chatine;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .close{
        height: 12px;
        position: absolute;
        padding: 25px;
        top: 0px;
        right: 0px;
        cursor: pointer;
        z-index: 10;
      }
      .back{
        position: absolute;
        padding: 27px;
        top: 0px;
        left: 0px;
        cursor: pointer;
      }
      .logo{
        width: 64px;
        height: 64px;
        border-radius: 18px;
        margin-bottom: 25px;
      }
      .letter{
        display: flex;
        margin-top: 70px;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 18px;
        margin-bottom: 25px;
        font-size: 25px;
        font-weight: 800;
        font-style: normal;
        color: $white;
      }

      .title{
        font-size: 18px;
        margin-bottom: 10px;
        letter-spacing: 0;
        color: $blackclassic;
        font-weight: bold;
        font-style: normal;
      }
      .description{
        display: flex;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        letter-spacing: 0;
        color: #47656B80;
        img{
          margin-right: 10px;
        }
      }
    }
    .list{
      height: 60%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 15px 0;
      .user{
        display: flex;
        flex-direction: column;
        height: 75px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
        &:hover{
          background: $shadow;
        }
        img{
          height: 40px;
          width: 40px;
          margin: 15px 30px;
          border-radius: 10px;
        }
        .title{
          width: 100%;
          font-size: 15px;
          margin-left: -5px;
          margin-bottom: 4px;
          margin-top: 6px;
          letter-spacing: 0.17px;
          color: $black;
          opacity: 0.8;
          font-weight: 600;
          font-style: normal;
        }
        .description{
          display: flex;
          margin-left: -5px;
          font-weight: 500;
          margin-bottom: 4px;
          font-style: normal;
          font-size: 14px;
          letter-spacing: 0.13px;
          color: $black;
          opacity: 0.45;
        }
      }
    }
  }
  .currentinq{
    background: #F9FBFC;
    position: relative;
    height: 49px;
    order: 2;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    .progress{
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 3px;
      background: $greenclassic;
    }
    img{
      height: 21px;
      margin: 15px;
    }
    .greenButton{
      min-height: 34px;
      max-height: 34px;
      font-size: 13px;
      padding: 0 20px;
      margin: 0 10px;
    }
    span{
      display: flex;
      align-items: center;
      min-width: 130px;
      font-weight: 500;
      font-style: normal;

    }
  }
  .inquiry{
    min-height: 433px;
    max-height: 433px;
    background: $white;
    order: 0;
    width: 100%;
    background-position: 50% 25%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    border-radius: 16px;
    .technologies .name{
      display: none;
    }
    &.payment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      z-index: 10;
      min-height: 805px;
      max-height: 805px;
      bottom: 0px;
      width: 415px;
      border-radius: 16px;
      border: 1px solid $standardborder;
      box-shadow: 4px 10px 15px 0 $shadow;
      .close{
        position: absolute;
        right: 0;
        top: 0;
        padding: 20px;
        width: 14px;
        height: 14px;
        cursor: pointer;
        margin-top: 1px;
        z-index: 10;
      }
      .info{
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: 84%;
        &.green{
          background-image: url(/images/background/InquiryGreen.svg);
          background-position: 50% 25%;
          background-repeat: no-repeat;
          width: 100%;
          div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 114px;
            width: 114px;
            border-radius: 67px;
            background: #DFFFF0;
            img{
              height: 100px;
              border-radius: 50px;
            }
          }
        }
      }
      .savecard{
        opacity: 0.85;
        background: #F4F9F9;
        border-radius: 12px;
        display: flex;
        width: 309px;
        height: 38px;
        padding: 23px;
        align-items: center;
        margin-top: 25px;
        img{
          background: #CAD9DC;
          box-shadow: 3px 6px 15px 0 rgba(180,196,199,0.25);
          border-radius: 6px;
          margin-right: 23px;
        }
        .pulse{
          display: flex;
          flex-direction: column;
          span:first-child{
            font-weight: 600;
            font-size: 15px;
            color: #47656B;
            letter-spacing: 0;
          }
          span:last-child{
            margin-top: 6px;
            font-weight: 500;
            opacity: 0.5;
            font-size: 13px;
            color: #47656B;
            letter-spacing: 0;
          }
        }
        .switch {
          position: relative;
          display: inline-block;
          width: 40px;
          height: 10px;
          margin-left: auto;
          input {
            opacity: 0;
            width: 0;
            height: 0;
          }
        }


        .toggle {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #B3ECD2;
          -webkit-transition: .4s;
          transition: .4s;
        }

        .toggle:before {
          position: absolute;
          content: "";
          height: 24px;
          width: 24px;
          left: -5px;
          bottom: -8px;
          background-color: $grayclassic;
          -webkit-transition: .4s;
          transition: .4s;
        }

        input:checked + .toggle:before {
          background: #05D677;
        }

        input:focus + .toggle {
          box-shadow: 0 0 1px #B3ECD2;
        }

        input:checked + .toggle:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }

        /* Rounded sliders */
        .toggle.round {
          border-radius: 34px;
        }

        .toggle.round:before {
          border-radius: 50%;
        }

      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        top: 15px;
        position: absolute;
        .info{
          width: 70%;
        }
        &.processing{
          height: calc(100% - 117px);
          justify-content: center;
          > div img{
            border-radius: 0;
          }
          .processingbar{
            margin-top: 30px;
          }
        }
        .description{
          width: 336px;
          font-weight: bold;
          font-style: normal;
          font-size: 15px;
          color: #47656B;
          letter-spacing: 0;
          text-align: center;
          line-height: 25px;
          margin-top: 0;
          margin-bottom: 20px;
        }
        .description2 {
          width: 336px;
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          color: #839499;
          letter-spacing: 0;
          text-align: center;
          line-height: 14px;
          text-align: center;
          margin-top: 20px;
          margin-bottom: 0;
        }
        .rate{
          margin-bottom: 20px;
        }
        .technologies{
          width: 336px;
          margin-bottom: 0;
          margin-top: 0;
        }
        .slider {
          width: 336px;
          background: #d3d3d333;
          &::-webkit-slider-thumb {
            background: #EBA7FF;
            box-shadow: 4px 6px 10px 2px #0ac9ef33;
          }
          &::-moz-range-thumb {
            background: #EBA7FF;
          }
        }

        .values{
          position: relative;
          width: 336px;
          display: flex;
          margin-top: 10px;
          margin-bottom: 20px;
          justify-content: space-between;
          .default{
            font-weight: bold;
            font-style: normal;
            opacity: 0.2;
            font-family: Helvetica;
            font-size: 18px;
            color: $blackclassic;
            letter-spacing: 0;
          }
          .efficacy{
            position: absolute;
            font-weight: bold;
            font-style: normal;
            font-size: 18px;
            color: $blackclassic;
            letter-spacing: 0;
          }
        }
        .review {
          height: 180px;
          width: 336px;
          display: flex;
          flex-direction: column;
          .title {
            display: flex;
            img{
              margin-right: 15px;
            }
            span{
              margin-top: 8px;
              font-weight: bold;
              font-style: normal;
              font-size: 16px;
              color: #47656B;
              letter-spacing: 0;
              line-height: 15px;
              width: 100%;
              padding-bottom: 15px;
              border-bottom: 2px solid #fcb504;
            }
          }
          textarea{
            padding: 12px;
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            border: none;
            margin: 15px 0;
          }
        }
        .experiences{
          display: flex;
          width: 336px;
          justify-content: space-around;
          .experience{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            width: 48px;
            font-weight: 500;
            font-style: normal;
            margin-right: 0px;
            input{
              text-align: center;
              width: 100%;
              border: none;
              padding: 0;
              font-size: 24px;
              color: #47656B40;
              height: 24px;
              &::placeholder{
                color: #47656B40;
              }
              &:focus{
                font-weight: 600;
                font-style: normal;
                color: $blueclassic2;
                letter-spacing: 0;
              }
            }
            span{
              font-size: 13px;
              color: $blackclassic;
            }
          }

        }
        .user{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
          width: calc(100% - 80px);
          border-radius: 12px;
          background: #FFFFFF;
          border: 1px solid #DBE3E5;
          box-shadow: 2px 3px 8px 0 rgba(71,101,107,0.06);
        }
        .info {
          height: 140px;
        }
        .resume .description{
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .cost{
          border-left: 1px solid transparent;
        }
        .cards{
          margin: 20px;
          overflow-x: scroll;
          width: calc(100% - 40px);
          overflow-y: hidden;
          flex-wrap: nowrap;
          max-height: 160px;
          .card{
            min-width: 150px;
            height: 115px;
            span{
              &.exp{
                right: 15px;
                bottom: 15px;
                font-weight: 500;
                font-style: normal;
                font-size: 8px;
                letter-spacing: 1.5px;
                opacity: 0.8;
              }
              &.last4{
                font-size: 11px;
              }
            }
            &.create img{
              height: 60px;
            }
          }
        }
        .resume{
          border-bottom: 1px solid #DBE3E5;
        }
        .total{
          display: flex;
          width: calc(100% - 40px);
          margin: 20px;
          div{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            width: 80%;
            &:first-child{
              align-items: flex-start;
              span:first-child{
                font-weight: 600;
                font-style: normal;
                font-size: 15px;
                color: $blackclassic;
                letter-spacing: 0;
              }
              span:last-child{
                opacity: 0.5;
                font-size: 13px;
                font-weight: 600;
                font-style: normal;
                color: $blackclassic;
                letter-spacing: 0;
              }
            }
            &:last-child{
              font-family: Helvetica;
              font-size: 28px;
              font-weight: bold;
              font-style: normal;
              color: $greenclassic;
              letter-spacing: 0;
            }
          }
        }
      }
      .savecard{

      }
      .title {
        margin-top: 25px;
        font-weight: bold;
        font-style: normal;
        font-size: 18px;
        line-height: 15px;
        color: $blackclassic;
        //margin-bottom: 30px;
        &.titlecard{
          margin-bottom: 30px;
          border-bottom: 1px solid $standardborder;
          width: 100%;
          text-align: center;
          padding-bottom: 35px;
        }
        div{
          font-family: Helvetica;
          font-size: 24px;
          color: #05D677;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          margin-bottom: 15px;
          &:after{
            display: block;
            content: ' ';
           }
        }
      }
      .subtitle{
        font-weight: bold;
        font-style: normal;
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 10px;
        line-height: 15px;
        color: $blackclassic;
        padding-bottom: 15px;
        border-bottom: 2px solid $greenclassic;
      }
      .description{
        font-size: 15px;
        color: $blackclassic;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 500;
        font-style: normal;
        width: 313px;
        margin-top: 30px;
      }
      .footer{
        position: absolute;
        width: calc(100% - 40px);
        border-top: 1px solid $borderproject;
        height: 86px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        bottom: 0;
        .steps{
          margin-left: 5px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 75px;
          span{
            font-weight: 600;
            font-style: normal;
            font-size: 15px;
            color: $blackclassic;
            letter-spacing: 0;
            margin-bottom: 15px;
          }
          .step{
            margin: 2.5px;
            width: 10px;
            height: 5px;
            border-radius: 3.5px;
            background: #DAE2E3;
            &.active{
              background: $greenclassic;
            }
          }
        }
      }
      .design{
        margin-top: 20px;
        height: 130px;
        width: 315px;
        position: relative;
        background-image: url(/images/background/PaymentConnection.svg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        div{
          position: absolute;
          display: flex;
          flex-direction: column;
          img:first-child{
            height: 50px;
            width: 50px;
            border-radius: 27px;
            border: 2px solid $white;
            box-shadow: 5px 10px 25px 0 rgba(5, 214, 119, 0.25);
          }
          img:last-child{
            right: -5px;
            bottom: -5px;
            position: absolute;
            width: 17px;
            height: 12px;
            padding: 6.5px 4px;
            border-radius: 14px;
            background: $greenclassic;
          }

          span{
            position: absolute;
            bottom: -25px;
            font-weight: 600;
            font-style: normal;
            font-size: 15px;
            color: $blackclassic;
            text-align: center;
            line-height: 15px;
            left: 0;
            right: 0;
          }
          &.myself{
            bottom: 15px;
            left: 15px;

          }
          &.edworking{
            margin: auto;
            width: fit-content;
            left: 0;
            right: 0;
            top: 15px;
          }
          &.freelancer{
            bottom: 15px;
            right: 15px;
            img:last-child{
              width: 17px;
              height: 12px;
              padding: 6.5px 4px;
              background: $yellowclassic;
            }
          }
        }

      }

    }
    .settings{
      background: white;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .time:hover, .date:hover{
        background: $hover;
      }
      .selectdate{
        &::before{
          width: 1px;
          height: 50%;
          background: $standardborder;
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
        }
      }

      .length{
        margin-bottom: 0;
      }
    }
    >.info{
      height: 230px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.yellow{
        background-image: url(/images/background/InquiryYellow.svg);
        background-position: 50% 25%;
        background-repeat: no-repeat;
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 114px;
          width: 114px;
          background: #FFF7E2;
          border-radius: 67px;
          img{
            width: 50px;
          /*  height: 60px;
            width: 60px;
            border-radius: 32px;
            border: 2px solid $white;
            */
          }
        }
      }
      &.green{
        background-image: url(/images/background/InquiryGreen.svg);
        background-position: 50% 25%;
        background-repeat: no-repeat;
        width: 100%;
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 114px;
          width: 114px;
          border-radius: 67px;
          background: #DFFFF0;
        }
      }
      &.red{
        background-image: url(/images/background/InquiryRed.svg);
        background-position: 50% 25%;
        background-repeat: no-repeat;
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 114px;
          width: 114px;
          border-radius: 67px;
          background: #FEDBE3;
        }
      }
      span{
        width: 290px;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $blackclassic;
        text-align: center;
        line-height: 22px;
        margin-top: 25px;
      }

    }
    .action{
      position: absolute;
      bottom: 0;
      height: 93px;
      width: 100%;
      padding: 0 25px;
      width: calc(100% - 50px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid $borderproject;
      border-bottom: 1px solid $borderproject;
      div{
        width: -webkit-fill-available;
        &:first-child{
          margin-left: 0px;
        }
        margin-left: 15px;


      }
      .filter{
        padding: 0px;
        min-width: 42px;
        min-height: 42px;
        max-width: 42px;
        max-height: 42px;
        border: 1px solid #D5E1E4;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
          background: $hover;
        }
      }
      div{
        padding: 0 20px;
        min-width: 85px;
        height: 46px;
        border-radius: 24px;
      }
    }
    .resume{
      display: flex;
      width: 100%;
      height: 108px;

      > div{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding-top: 5px;
        &.cost{
          position: relative;
          .description{
            font-size: 10px;
          }
          &::before{
            width: 1px;
            height: 70%;
            background: $standardborder;
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
          }
        }
        &.duration{
          .description{
            font-size: 10px;
          }
        }
        img{
          height: 20px;
        }
        .title{
          margin-top: 10px;
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $blackclassic;
          letter-spacing: 0;
        }
        .description{
          margin-top: 7px;
          font-weight: 600;
          opacity: 0.45;
          font-style: normal;
          font-size: 10px;
          color: $blackclassic;
          letter-spacing: 0;
        }

      }
    }




  }
  .invitation{
    min-height: 433px;
    max-height: 433px;
    border-radius: 16px;
    background: $white;
    width: 100%;
    background-image: url(/images/background/StarsTwo.svg);
    background-position: 50% 25%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    img{
      background: $blueclassic4;
      width: 23px;
      padding: 17px 20.5px;
      height: 30px;
      margin-top: 120px;
      border-radius: 18px;
      box-shadow: 0px 0px 59px 38px #1B79DF30;
    }
    span{
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      color: $blackclassic;
      text-align: center;
      max-width: 200px;
      line-height: 24px;
      margin-top: 50px;
    }
    .action{
      position: absolute;
      bottom: 0;
      height: 96px;
      width: calc(100% - 50px);
      display: flex;
      justify-content: space-between;
      padding: 0 25px;
      align-items: center;
      border-top: 1px solid $borderproject;
      border-bottom: 1px solid $borderproject;
      div{
        width: 170px;
        height: 46px;
        border-radius: 24px;
        border: 1px solid #D5E1E4;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        letter-spacing: 0;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
          background: $shadow;
        }
        &:first-child{
          margin-right: 15px;
        }
      }
    }
  }

  .body:first-child{
    margin-bottom: 12px;
  }

  .body{
    width: calc(100% - 4px);
    margin-right: 4px;
    height: 100%;


    //height: 200px;
    .break{
      min-height: 15px;
      width: 100%;
    }
    background: $white;
    overflow: auto;
    display: flex;
    order: 5;
    flex-direction: column-reverse;
  /*  &.long{
      height: 200px;
      &.ss{
        height: 250px;
      }
    }
    &.short{
      height: 550px;
      max-height: calc(94% - 260px);
    }*/
    &.empty{
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        text-align: center;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        color: $blackclassic;
        line-height: 18px;
        max-width: 174px;
        margin-top: 25px;
      }
      img{

      }
    }
    .message{
      display: flex;
      font-size: 15px;
      letter-spacing: 0;
      min-height: fit-content;
      font-weight: 500;
      font-style: normal;
      line-height: 20px;
      align-items: flex-end;
      position: relative;
      -webkit-animation : pulse 1s infinite;
      -moz-animation    : pulse 1s infinite;
      -o-animation      : pulse 1s infinite;
      animation         : pulse 1s infinite;
      &.activity{
        background: $graychatsent;
        width: fit-content;
        border-radius: 12px;
        align-self: center;
        align-items: center;
        margin: 10px;
        img{
          border-radius: 8px;
          height: 24px;
          width: 24px;
          margin-left: 8px;
          margin-right: 10px;
        }
        .text {
          padding-right: 20px;
          padding-left: 0;
          font-size: 14px;
          font-weight: bold;
          color: $blackclassic;
          max-width: 90%;
          word-break: break-word;
        }
      }
      &.sent{
        justify-content: flex-end;
        margin: 2px 15px 2px 90px;
        &.last{
          .text, .file, .image {
            border-top-right-radius: 2px;
          }
        }
        &.middle{
          .text, .file, .image {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
        &.first{
          .text, .file, .image {
            border-bottom-right-radius: 2px;
          }
        }
        .text, .file {
          background: $graychatsent;
          color: $blackclassic;
          max-width: 90%;
          word-break: break-word;
        }
        .time{
          position: absolute;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          color: $blackclassic;
          opacity: 0.6;
          letter-spacing: 0;
          bottom: 4px;
          right: 10px;
        }
      }
      .name{
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 14px;
        margin-left: 19px;
      }
      .text, .file {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 8px 45px 7px 15px;
        color: $blackclassic;
        position: relative;
        border-radius: 10px;
      }
      .image{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        .bubble{
          padding: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F2F6F7;
          border-radius: 14px;
        }
        &:hover{
          &:after {
            position: absolute;
            pointer-events: none;
            content:"";
            left: 5px;
            right: 5px;
            width: calc(100% - 10px);
            top: 5px;
            bottom: 5px;
            height: calc(100% - 10px);
            border-radius: 12px;
            background: #264a5133;
          }
        }

        .progressRing{
          position: absolute;
          div{
            border: 7px solid #F7F9FB;
            width: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            background: $white;
            border-radius: 30px;
            position: absolute;
            font-size: 16px;
            font-weight: bold;
            color: $black;

          }
        }
        img{
          border-radius: 10px;
          filter: contrast(0.95);
          object-fit: cover;
          width: 195px;
          height: 131px;

        }
      }
      .task{
        justify-content: center;
        background: #F7F9FB;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.15);
        .type{
          width: 20px;
          padding: 14px;
          box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.15);
          border: 1px solid $graychatsent;
          background: $white;
          color: $blackclassic;
          border-radius: 6px;
          margin: 21px;
        }
        .text{
          border: 1px solid $graychatsent;
          background: $white;
        }
        .progress{
          height: 6px;
          width: 164px;
          margin: 0 15px;
        }
      }

      .file{
        height: 64px;
        justify-content: center;
        cursor: pointer;
        .type{
          width: 46px;
        }
        .text{

        }
        .title{
          margin-bottom: 6px;
          margin-left: 15px;
          margin-right: 50px;
          font-size: 15px;
          letter-spacing: 0;
          font-weight: bold;
          font-style: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

        }
        .description{
          margin-left: 15px;
          font-size: 14px;
          opacity: 0.7;
          letter-spacing: 0;
          font-weight: 500;
          font-style: normal;
        }
        .progress{
          height: 6px;
          width: 164px;
          margin: 0 15px;
          border-radius: 9px;
        }
      }
      &.received {
        display: flex;
        justify-content: flex-start;
        margin: 2px 90px 2px 0;
        height: fit-content;
        width: fit-content;
        > img{
          margin: 0 8px 0px 15px;
          height: 36px;
          width: 36px;
          border-radius: 10px;
        }
        &.last{
          .text, .file, .image {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 10px;

          }
        }
        &.middle{
          .text, .file, .image {
            margin-left: 59px;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
        }
        &.first{
          .text, .file, .image {
            margin-left: 59px;
            flex-direction: column;
            border-bottom-left-radius: 2px;
            .bubble{
              position: relative;
            }
            .bubble:hover:after{
              position: absolute;
              pointer-events: none;
              content:"";
              height: 100%;
              width: 100%;
              top:0;
              left:0;
              border-radius: 14px;
              background: #264a5133;
            }
            &:hover:after{
              content: none;
            }
          }
        }
        .image{
          align-items: flex-start;
          .bubble{
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white;
            border-radius: 13px;
            border: 1px solid #dfe4e5a8;
          }
        }
        /*
          &.group {
            .text, .file{
              background: $white;
              border: 1px solid $graychatsent;
              color: $blackclassic;
              font-weight: 500;
              font-style: normal;
              .time{
                color: $blackclassic;
              }
              .name{
                color: $blackclassic;
                font-weight: bold;
                font-style: normal;
                margin-bottom: 6px;
              }
            }
          }
        */
        .text, .file {
          background: $blue;
          background: #FFFFFF;
          border: 1px solid $standardborder;
          border-radius: 10px 10px 10px 2px;
          color: $blackclassic;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 20px;
          max-width: 90%;
          word-break: break-word;
        }
        .time{
          position: absolute;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          color: #47656b96;
          letter-spacing: 0;
          bottom: 4px;
          //bottom: 10px;
          right: 10px;
          opacity: 0.6;
        }
      }
/*

      div.file {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-radius: 10px 2px 2px 10px;
        max-height: 53px;
        &.noimage{
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          img{
            height: 49px;
          }
          .title{
            margin-left: 15px;
            margin-bottom: 3px;
            font-weight: 600;
            font-style: normal;
            font-size: 15px;
            letter-spacing: 0;
            max-width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .description{
            font-weight: 500;
            margin-left: 15px;
            font-style: normal;
            font-size: 14px;
            letter-spacing: 0;
            opacity: 0.7;

          }
          .progress{

          }
        }
        &.image{
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        &.last{
          border-bottom-right-radius: 10px;
        }
        &.middle{

        }
        &.first{
          border-top-right-radius: 10px;
        }
      }*/
    }
  }
  .chatinput{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    order: 6;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-height: 70px;
    background: $white;
    border-top: 1px solid $chatine;
    .complexinput{
      width: 100%;
      resize: none;
      border: 0;
      padding-right: 85px;
      margin: 28px 8px 6px 0;
      background: transparent;
      font-size: 14px;
      color: #47656B;
      letter-spacing: 0;
      font-weight: 500;
      line-height: 24px;
      &::placeholder{
        font-weight: 500;
        font-style: normal;
        opacity: 0.6;
        color: $black;
        letter-spacing: 0.38px;
        font-size: 14px;
      }
    }
    img{
      cursor: pointer;
      &.smile{
        position: absolute;
        right: 65px;
      }
      &.send{
        position: absolute;
        right: 25px;
      }
    }
    .file{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 17px 15px;
      /*border: 1px solid $chatine;*/
      cursor: pointer;
      border-radius: 10px;
      width: 36px;
      min-width: 36px;
      height: 36px;
      img{
        width: 16px;
        height: 16px;
      }
    }
    .emoticons{
      position: absolute;
      bottom: 85px;
      //height: calc(100% - 139px);
      right: 0px;
      //width: 100%;
      overflow: hidden;
      .emoji-mart-scroll{
        padding: 0px;
      }
      .emoji-mart .emoji-mart-emoji{
        cursor: pointer;
        outline: none;
      }
      .emoji-mart.emoji-mart-light{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        //border: 1px solid #DBE3E5;
      //  box-shadow: 4px 10px 15px 0 rgba(206,213,220,0.25);
        border-radius: 16px 16px 0 0;
        .emoji-mart-category-list{
          margin: 0 34px;
        }
      }
      .emoji-mart-category .emoji-mart-emoji:hover:before{
        opacity: 0.8;
        background: #EDF1F3;
        border-radius: 8px;
      }
      .emoji-mart-preview{
        display: none;
      }
      .emoji-mart-category .emoji-mart-emoji span{
        width: 30px;
        height: 30px;
      }
      .emoji-mart-category-label span{
        opacity: 0.55;
        text-transform: uppercase;
        padding-left: 39px;
        font-family: Helvetica;
        font-size: 12px;
        color: #47656B;
        letter-spacing: 0;
      }
    }
/*
      .filesPopup{
        border-radius: 10px;
        border: 1px solid $popupborder;
        box-shadow: 2px 4px 8px 0 $shadow;
        position: absolute;
        background: white;
        margin-top: -44px;
        margin-left: 15px;
        >div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 53.5px;
          width: 259px;
          cursor: pointer;
          img{
            margin: 22px 19px 17px 21px;
          }
          span{
            font-size: 14px;
            letter-spacing: 0;
            font-weight: normal;
            font-style: normal;

          }
        }
*/
  }
}

.videocall {
  height: 263px;
  width: 415px;
  right: 310px;
  position: absolute;
  background: $white;
  border: 0.5px solid #DBE3E5;
  box-shadow: 4px 10px 15px 0 #CED5DC26;
  //border-radius: 16px;
  bottom: 353px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  &.static{
    bottom: 403px;
  }
  &.draggable{
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
  }
  &.fullscreen{
    bottom: 0;
    right: 0;
    z-index: 2;
    .remoteMedia{
      height: 100%;
      video{
        height: 100%;
      }
    }
  }
  .receiving{
    height: 100%;
    width: 100%;
    background: #09323ae6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    div.logo{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      border-radius: 43px;
      object-fit: cover;
      border: 5px solid #5B777C;
      box-shadow: 4px 15px 25px 0 #9aafb461;
      z-index: 4;
      object-fit: cover;
      font-size: 50px;
      font-weight: 800;
      font-style: normal;
      color: $white;

    }
    img.customized{
      height: 80px;
      width: 80px;
      border-radius: 43px;
      object-fit: cover;
      border: 5px solid #5B777C;
      box-shadow: 4px 15px 25px 0 #9aafb461;
      z-index: 4;

    }

    > span {
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $white;
      letter-spacing: 0.23px;
      font-weight: 600;
      font-style: normal;
      img{
        margin-bottom: 2px;
        margin-right: 11px;
      }
    }
    .circle{
      border-radius: 50%;
      border: 1px solid #FF94ABA6;
      width: 150px;
      height: 150px;
      position: absolute;
      opacity: 0;
      animation: scaleIn 4s infinite;
    }
    .call{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -80px;
      width: 80%;
      height: fit-content;
      z-index: 3;
      .button{
        height: 50px;
        width: 50px;
        z-index: 3;
        background: #F2F6F763;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.red{
          background-image: linear-gradient(180deg, #FD3F67 0%, #E8365B 100%);
          box-shadow: 3px 10px 20px 0 rgba(253, 63, 103, 0.30);
          border-radius: 25px;
        }
        &.green{
          background-image: linear-gradient(180deg, #0EE7B1 0%, #11CFA0 100%);
          box-shadow: 3px 10px 20px 0 rgba(5, 214, 162, 0.30);
          border-radius: 25px;
        }
      }
      > img{
        height: 80px;
        width: 80px;
        border: 5px solid #F7F9FB;
        box-shadow: 4px 15px 25px 0 rgba(154, 175, 180, 0.38);
        border-radius: 45px;
      }

    }
  }
  .calling{
    height: 100%;
    width: 100%;
    background: #09323ae6;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .circle{
      border-radius: 50%;
      border: 1px solid #FF94ABA6;
      width: 150px;
      height: 150px;
      position: absolute;
      opacity: 0;
      animation: scaleIn 4s infinite;
    }
    > span {
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $white;
      letter-spacing: 0.23px;
      font-weight: 600;
      font-style: normal;
      img{
        margin-bottom: 2px;
        margin-right: 11px;
      }
    }
    div.logo{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      border-radius: 43px;
      object-fit: cover;
      border: 5px solid #5B777C;
      /*box-shadow: 4px 15px 25px 0 #9aafb461;*/
      z-index: 4;
      object-fit: cover;
      font-size: 50px;
      font-weight: 800;
      font-style: normal;
      color: $white;

    }
    img.customized{
      height: 80px;
      width: 80px;
      border-radius: 46px;
      object-fit: cover;
      border: 5px solid #5B777C;
      /*box-shadow: 4px 15px 25px 0 #9aafb461;*/
      z-index: 4;

    }

    >img{
    }
    .hangup{
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;
      height: 50px;
      width: 50px;
      background-image: linear-gradient(180deg, #FD3F67 0%, #E8365B 100%);
      box-shadow: 3px 10px 20px 0 rgba(253, 53, 103, 0.30);
      border-radius: 25px;
      cursor: pointer;
    }
  }
  border-radius: 16px;
  &.fullscreen{
    border-radius: 0px;

    .display{
      border-radius: 0px;
    }
  }
  .display {
    background: $black;
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 16px;
    align-items: flex-end;
    justify-content: center;
    .buttons{
      z-index: 2;
      display: flex;
      .button{
        height: 50px;
        margin: 10px;
        width: 50px;
        z-index: 3;
        background: #F2F6F763;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        //margin-bottom: 22px;
        cursor: pointer;
        &.red{
          background: $redclassic2;
        }
      }
    }
    .loading{
      position: absolute;
      animation: rotation 2s infinite linear;
      width: 70px;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .localMedia{
      position: absolute;
      right: 22px;
      top: 22px;
      z-index: 2;
      height: 60px;
      width: 84px;
      border: 3px solid $white;
      border-radius: 6px;
      background: $blackclassic;
      display: flex;
      justify-content: center;
      align-items: center;
      .loading{
        width: 40px;
      }
      video{
        object-fit: cover;
        object-position: center;
        width: 84px;
        height: 60px;
        z-index: 2;
      }
    }
    .remoteMedia{
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: $blackclassic;
      video{
        object-fit: cover;
        object-position: center;
        width: 100%;
        width: 100%;
      }
    }


  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}
