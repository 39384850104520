.feelings{
  display: flex;
  flex-wrap: wrap;
  width: $singlewidth;
  height: 161px;
  background: $white;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 4px 10px 15px 0 $shadow;
  margin-top: 25px;
  margin-left: 12.5px;
  .feels{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    img{
      height: 50px;
      width: 50px;
      &:first-child{
        margin-left: 30px;
      }
      &:last-child{
        margin-right: 30px;
      }
    }
  }

}
