.edcalendar {
  width: 414px;
  height: auto;
  .calendarheader{
    font-size: 18px;
    font-weight: bold;
    //padding: 10px 10px 5px 10px;
    display: flex;
    justify-content: space-between;
    .calendardate{
      font-weight: 600;
      font-size: 18px;
      color: $blackclassic;
      letter-spacing: 0;
      font-style: normal;
      display: flex;
      align-items: center;
    }
    .calendarbutton{
      border: 1px solid $borderproject;
      border-radius: 18px;
      cursor: pointer;
      padding: 5px 12.5px;
      &:hover{
        background: $shadow;
      }
    }


  }
  .calendarbody{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    grid-template-rows: 46px;
    margin-top: 20px;
    position: relative;
    &::before{
      width: 100%;
      height: 1px;
      background: $standardborder;
      content: '';
      position: absolute;
      top: 45px;
      left: 0;
    }

    .calendarday{
      display: flex;
      height: 36px;
      margin: 5px 5px 5px 10px;
      width: 36px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      font-style: normal;
      color: $black;
      border-radius: 18px;
      &:hover{
        background: $green;
        color: $white;
      }
      &.today{
        border: 1px solid $greenclassic;
        &:hover{
          background: transparent;
        }
      }
      &.past{
        color: #09323A50;
        &:hover{
          background: transparent;
        }
      }
      &.selected{
        background-color: $green;
        color: $white;
        font-weight: bold;
      }
      &.name{
        opacity: 0.35;
        &:hover{
          background: transparent;
          color: $black;
        }

      }
    }
  }
}
