.edworkers{
  display: flex;
  width: $doublewidth;
  background: $white;
  border-radius: 16px;
  min-height: 300px;
  //box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.10);
  .body{
    width: $doublewidth - 50px;
    background: $gray;
    margin: 0 25px;
    height: 288px;
    border-radius: 10px;
    box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.15);
  }
  .empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0;
    padding: 20px;
    left: 0;
    right: 0;
    margin: auto;
    img{
      margin: 20px;
      height: 100px;
    }
    span{
      width: 252px;
      font-size: 13px;
      color: $blackclassic;
      letter-spacing: 0;
      font-weight: 600;
      font-style: normal;
      text-align: center;
      line-height: 20px;
    }
  }
}
.mainScreen div.edworkers-widget.widget{
  flex-wrap: wrap;
  width: $doublewidth;
  margin-bottom: 25px;
  &.preview{
    > .header{
      height: 86px;
      border-bottom: 1px solid transparent;
    }
    .body{
      border-radius: 14px;
      margin: 0px 20px 20px 20px;
      overflow: hidden;
    }
  }
  &.full{
    flex-direction: row;
    height: fit-content;
    min-height: calc(100vh - 128px);
    .edworker{
      width: 223px;
    }
  }
  &.full .header{
    position: relative;
    border-bottom: 1px solid $standardborder;
  }
  .header .filter {
    position: absolute;
    right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover > img{
      background: $shadow;
    }
    span{
      font-size: 14px;
      color: #47656B;
      margin-right: 10px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0;
      margin-right: 0;
    }
    img{
      position: relative;
      border: 1px solid #E8ECED;
      border-radius: 25px;
      height: 14px;
      width: 14px;
      padding: 15px 12px;
      cursor: pointer;
    }
  }
}
.alledworkers{
  width: 100%;
  .ql-toolbar{
    display: none;
  }
  .header{
    .filter {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span{
        font-weight: 500;
        font-size: 14px;
        color: $blackclassic;
      }
      img{
        margin-left: 18px;
        position: relative;
        height: 20px;
        min-width: 20px;
        width: 20px;
        border: 1px solid #E8ECED;
        border-radius: 25px;
        padding: 10px;
      }
    }
  }
  .categories .category{
    border-radius: 0px;
    margin: 0px;
  }
  .body{
    display: flex;
    overflow: auto;
  }
}


.edworker{
  //width: 218px;
  width: 237px;
  height: 247px;
  margin: 10px;
  border: 1px solid $standardborder;
  border-radius: 10px;
  position: relative;
  background: $white;
  cursor: pointer;
  > .tech{
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #CCD6D9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 83px;
    left: 83px;
    img{
      max-height: 25px;
      max-width:: 25px;
    }
  }
  &:hover:before {
    position: absolute;
    pointer-events: none;
    content:"";
    height: 100%;
    width: 100%;
    top:0;
    left:0;
    border-radius: 14px;
    background: #09323A04;
  }
  &.empty{
    .invite{
      background: $loading;
      opacity: 0.07;
    }
    .author{
      background: $loading;
      opacity: 0.1;
      margin: 25px 0 26px 25px;
      width: 80px;
      height: 80px;
      border-radius: 14px;
    }
    .info{
      span{
        height: 10px;
        width: 100px;
        background: $loading;
        opacity: 0.1;
        &.tech{
          opacity: 0.07;
        }
      }
    }
    .edbottom{
      margin-top: 25px;
      span{
        height: 10px;
        width: 20px;
        background: $loading;
        opacity: 0.1;
        border-radius: 15px;
        &.rate{
          height: 10px;
          width: 30px;
          opacity: 0.07;
        }

      }
    }
  }
  .invite{
    position: absolute;
    right: 25px;
    top: 25px;
    height: 42px;
    width: 52px;
    border: 1px solid #ccd6d999;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
      background: $white;
    }

  }
  .leftbar{
    width: 4px;
    height: 80px;
    position: absolute;
    border-radius: 2.5px;
    margin-top: 25px;
    margin-left: -2px;
  }
  img.author{
    margin: 25px 0 16px 25px;
    width: 80px;
    filter: contrast(0.95);
    height: 80px;
    border-radius: 14px;
  }
  .info{
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    width: 188px;
    .name{
      font-weight: bold;
      font-style: normal;
      color: $black;
      letter-spacing: 0;
      font-size: 15px;
      &:hover{
        text-decoration: underline;
      }
    }
    .tech{
      font-weight: 500;
      margin-top: 6px;
      font-style: normal;
      opacity: 0.64;
      color: $black;
      letter-spacing: 0;
      font-size: 13px;
      margin-bottom: 10px;
    }

  }
  .edbottom{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid $borderproject;
    margin-top: 10px;
    span{
      margin-bottom: -4px;
    }
    .rate{
      font-weight: bold;
      font-style: normal;
      color: $greenclassic3;
      letter-spacing: 0;
      font-size: 18px;
    }
    .efficiency{
      font-weight: bold;
      font-style: normal;
      color: $black;
      letter-spacing: 0;
      font-size: 18px;
      img{
        margin-bottom: -2px;
        margin-right: 6px;
      }
    }

  }

}
/*
.edworker{
  margin: 19px;
  width: 218px;
  height: 247px;
  background: $white;
  border-radius: 10px;
  border: 1px solid $borderproject;
  position: relative;
  box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.15);
  .invite{
    position: absolute;
    right: 25px;
    top: 25px;
    height: 42px;
    width: 52px;
    border: 1px solid #ccd6d999;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .leftbar{
    width: 4px;
    height: 80px;
    position: absolute;
    border-radius: 2.5px;
    margin-top: 25px;
    margin-left: -2px;
  }
  img.author{
    margin: 25px 0 36px 25px;
    width: 80px;
    height: 80px;
    border-radius: 14px;
  }
  .info{
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    width: 188px;
    .rate{
      font-weight: bold;
      font-style: normal;
      color: $green;
      letter-spacing: 0;
      font-size: 18px;
    }
    .name{
      margin-top: 15px;
      font-weight: bold;
      font-style: normal;
      color: $black;
      letter-spacing: 0;
      font-size: 15px;
    }
    .thing{
      font-weight: 500;
      margin-top: 8px;
      font-style: normal;
      opacity: 0.64;
      color: $black;
      letter-spacing: 0;
      font-size: 13px;
    }
  }
}
*/

.modal div.userview{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 1075px;
  height: fit-content;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  top: 50px;
  background: $white;
  position: relative;
  > .header{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    max-height: 80px;
    .close2{
      right: 17px;
      img{
        width: 12px;
        padding: 16px;
      }
    }
    .back{
      img{
        width: 12px;
        padding: 17.5px 16px;
      }
      left: 17px;
    }
    .back, .close2 {
      position: absolute;
      display: flex;
      align-items: center;
      img{
        margin: 0;
        border: 1px solid $standardborder;
        border-radius: 30px;
        &:hover{
          background: $hover;
          cursor: pointer;
        }
      }
      span{
        margin-left: 20px;
        font-weight: 600;
        font-size: 15px;
        color: #47656B;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
    > span{
      width: fit-content;
      font-size: 18px;
      color: #09323A;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

  }
  .bodiet{
    display: flex;
  }
  .about{
    width: 320px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    > .tech{
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #CCD6D9;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 205px;
      left: 190px;
      img{
        max-width: 23px;
        max-height: 23px;
      }
    }

    > img{
      margin-top: 40px;
      border-radius: 18px;
      height: 110px;
      width: 110px;
    }
    > .title{
      font-size: 18px;
      color: $black;
      height: 23px;
      letter-spacing: 0;
      margin-bottom: 10px;
      margin-top: 30px;
      font-weight: bold;
      font-style: normal;
    }
    > .description{
      opacity: 0.64;
      font-size: 14px;
      color: $black;
      letter-spacing: 0;
      font-weight: 500;
      font-style: normal;
    }
    .hire{
      width: 260px;
      height: 46px;
      background: $white;
      border: 1px solid #E8ECED;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 35px;
      cursor: pointer;
      span{
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $black;
        letter-spacing: 0;
      }
      img{
        height: 17px;
        margin-right: 15px;
      }
      &:hover{
        background: $hover;
      }
    }

    .features{
      display: flex;
      width: calc(100% - 60px);
      padding: 30px;
      border-bottom: 1px solid $standardborder;
      border-top: 1px solid $standardborder;
      > div{
        width: 120px;
        height: 160px;
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        &.rate {
          margin-right: 20px;
          background: #FFFAEC;
          .circle {
            background: #FFF1CA;
            border-radius: 10px;

          }
          .desc{
            color: #CBA15B;
          }
        }
        &.efficacy {
          background: #FCF2FF;
          .circle {
            background: #F8DFFF;
            border-radius: 10px;
            .efficiency{
              height: 24px;
            }
          }
          .desc{
            color: #9A60AC;
          }
        }
        .circle {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          min-width: 60px;
          min-height: 60px;
          max-width: 60px;
          max-height: 60px;
          margin-top: 21px;
          margin-bottom: 21px;
        }
        .value{
          font-weight: bold;
          font-style: normal;
          font-size: 18px;
          color: $black;
          letter-spacing: 0;
          margin-bottom: 8px;
        }
        .desc{
          opacity: 0.5;
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          color: $black;
          letter-spacing: 0;
        }

      }
    }
    .info{
      width: 250px;
      height: 168px;
      margin: 30px;
      background: #f7f9fbcc;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span.title{
        margin-top: 20px;
        font-weight: bold;
        font-size: 15px;
        color: #09323A;
        letter-spacing: 0;
        line-height: 20px;
        img{
          margin-right: 15px;
          height: 24px;
        }
      }
      span.description{
        font-weight: 500;
        width: 188px;
        opacity: 0.4;
        line-height: 22px;
        font-size: 14px;
        color: #09323A;
        margin-top: 15px;
        letter-spacing: 0;
        text-align: center;
      }
    }
    .technologies{
      display: flex;
      flex-direction: column;
      border-top: 1px solid $standardborder;
      border-bottom: 1px solid $standardborder;
      .title{
        padding: 30px;
        width: calc(100% - 60px);
        text-align: left;
        font-style: normal;
        font-weight: 600;
        color: $black;
        margin: 0;
      }
      .list{
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;
        .technology{
          width: calc(100% - 60px);
          padding: 0 30px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          .tech{
            min-width: 50px;
            max-width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $grayclassic;
            border-radius: 12px;

            img{
              max-width: 30px;
              width: auto;
              max-height: 30px;
              height: auto;
            }
          }
          .name{
            display: flex;
            flex-direction: column;
            width: 100%;
            .value{
              width: calc(100% - 30px);
              margin: 0 15px;
              margin-bottom: 8px;
              font-weight: 600;
              font-size: 15px;
              color: #09323A;
              text-transform: capitalize;
              letter-spacing: 0;
            }
            .key{
              width: calc(100% - 30px);
              margin: 0 15px;
              font-weight: 500;
              opacity: 0.64;
              font-size: 13px;
              color: #09323A;
              letter-spacing: 0;
            }
          }
          >.value {
            background: #FFFFFF;
            min-width: 50px;
            max-width: 50px;
            height: 50px;
            border: 1px solid $standardborder;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 16px;
            color: #09323A;
            letter-spacing: 0;
          }
        }

      }
    }

  }
  .information{
    width: 754px;
    min-height: 100vh;
    height: auto;
    border-left: 1px solid $standardborder;
    .empty{
      width: 100%;
      background: #F8FAFC;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span{
        margin-top: 10px;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
        width: 210px;
      }
    }
    .about{
      margin-left: -1px;
      align-items: flex-start;
      padding: 40px;
      width: calc(100% - 80px);
      border-bottom: 1px solid $standardborder;
      .description{
        opacity: 1;
        min-height: 215px;
        margin-top: 25px;
        font-size: 15px;
        color: #47656be6;
        letter-spacing: 0;
        line-height: 23px;
        &.empty{
          height: 194px;
        }
      }
    }
    .reviews {
      align-items: flex-start;
      margin: 0 40px;
      padding: 40px 0;
      width: calc(100% - 80px);
      position: relative;
      .rvs {
        &.empty {
          height: 760px;
          margin-top: 30px;
          span{
            color: #47656be6;
          }
        }
        .review {
          margin-top: 25px;
          padding-bottom: 30px;
          border-bottom: 1px solid $standardborder;
          position: relative;
          .author {
            display: flex;
            flex-direction: column;
            height: 50px;
            flex-wrap: wrap;
            margin: 25px 0;
            > img{
              padding: 5px;
              height: 40px;
              width: 40px;
              border-radius: 10px;
              margin-right: 15px;
            }
            span.name{
              width: 100%;
              margin-top: 5px;
              font-size: 15px;
              color: $blackclassic;
              letter-spacing: 0.17px;
              font-weight: 600;
              font-style: normal;
            }
            span.role{
              opacity: 0.45;
              font-family: Helvetica;
              font-size: 13px;
              margin-top: 4px;
              font-weight: 500;
              font-style: normal;
              color: $black;
              letter-spacing: 0.13px;
            }
            span.date{
              position: absolute;
              right: 0;
              background: #FFFFFF;
              border: 1px solid $standardborder;
              border-radius: 24px;
              font-size: 14px;
              font-weight: 500;
              color: #47656B;
              letter-spacing: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              max-height: 32px;
              min-height: 32px;
              padding-right: 17px;
              img{
                width: 14px;
                height: 14px;
                margin: 9px 10px 9px 9px;
              }
            }
          }
          .body{
            font-weight: 500;
            font-family: Helvetica;
            font-size: 15px;
            color: #47656B;
            letter-spacing: 0;
            line-height: 23px;
          }
        }

      }
    }
    .title{
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      color: #09323A;
      letter-spacing: 0;
      width: 100%;
      img{
        height: 18px;
        width: 18px;
        margin-bottom: -3px;
        margin-right: 16px;
      }
    }





  }





  /*
  > .top{
    display: flex;
    flex-direction: column;
    align-items: center;
    .close{
      position: absolute;
      border: 1px solid $borderproject;
      border-radius: 15px;
      margin: 0;
      padding: 5px;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    > img{
      margin-top: 50px;
    }
    .title{
      font-size: 18px;
      color: $black;
      letter-spacing: 0;
      margin-bottom: 10px;
      margin-top: 30px;
      font-weight: bold;
      font-style: normal;
    }
    .description{
      opacity: 0.64;
      font-size: 14px;
      color: $black;
      letter-spacing: 0;
      font-weight: 500;
      font-style: normal;
    }
    .hire{
      width: 260px;
      height: 46px;
      background: $white;
      border: 1px solid #E8ECED;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 23px;
      cursor: pointer;
      span{
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $black;
        letter-spacing: 0;
      }
      img{
        height: 17px;
        margin-right: 15px;
      }
    }

  }
  */
  /*
  .middle{
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .header{
      margin-top: 30px;
      font-size: 15px;
      color: $black;
      height: auto;
      letter-spacing: 0;
      border-bottom: 1px solid $borderproject;
      span{
        border-bottom: 2px solid transparent;
        height: 40px;
        width: 50%;
        text-align: center;
        opacity: 0.4;
        font-size: 15px;
        color: $black;
        letter-spacing: 0;
        cursor: pointer;
        &.selected{
          opacity: 1;
          border-bottom: 2px solid $blueclassic;
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $black;
          letter-spacing: 0;
        }
      }
    }
    .general{
      width: 100%;
      .reviews{
        border-bottom: 1px solid #E8ECED;
        padding-bottom: 20px;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          > span{
            font-weight: 600;
            font-style: normal;
            font-size: 15px;
            color: $blackclassic;
            letter-spacing: 0;
          }
          .buttons{
            width: 78px;
            display: flex;
            justify-content: space-between;
            > div{
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 36px;
              height: 30px;
              background: $white;
              border: 1px solid #E8ECED;
              border-radius: 6px;
            }
          }
        }
        .empty{
          background: #f7f9fb;
          width: 260px;
          height: 144px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 10px;
          margin-top: 24px;
          margin-bottom: 24px;
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          color: $black;
          letter-spacing: 0;
          line-height: 18px;
          span{
            text-align: center;
            max-width: 220px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            color: $blackclassic;
          }
        }
        .author{
          display: flex;
          flex-direction: column;
          height: 50px;
          padding: 5px;
          flex-wrap: wrap;
          margin-top: 20px;
          img{
            height: 40px;
            width: 40px;
            border-radius: 10px;
            margin-right: 10px;
          }
          span.name{
            width: 100%;
            font-size: 15px;
            color: $blackclassic;
            letter-spacing: 0.17px;
            font-weight: 600;
            font-style: normal;
          }
          span.role{
            opacity: 0.45;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: 500;
            font-style: normal;
            color: $black;
            letter-spacing: 0.13px;
          }
        }
        .body{
          margin-top: 24px;
          margin-bottom: 24px;
          opacity: 0.4;
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          color: $black;
          letter-spacing: 0;
          line-height: 18px;
          text-justify: inter-word;
          text-align: justify;

        }

      }
    }
    .info{
      .body{
        margin-top: 24px;
        margin-bottom: 24px;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        color: $black;
        letter-spacing: 0;
        line-height: 18px;
        text-justify: inter-word;
        text-align: justify;
        span{
          text-align: center;
          min-width: 170px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          color: $blackclassic;
        }

        >div{
          background: #f7f9fb;
          width: 260px;
          height: 144px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 10px;
        }

      }
      .resume{
        border-top: 1px solid $borderproject;
        border-bottom: 1px solid $borderproject;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        .top{
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          height: 70px;
          width: 250px;
          img{
            height: 58px;
            margin-right: 20px;
          }
          span.text{
            width: 150px;
            height: 20px;
            font-weight: bold;
            font-style: normal;
            color: $black;
            letter-spacing: 0.15px;
            line-height: 14px;
          }
          span.subtext{
            font-weight: 600;
            font-style: normal;
            color: $black;
            letter-spacing: 0.17px;
            font-size: 15px;
            line-height: 14px;
            opacity: 0.4;
          }
        }
      }
    }

  }
*/
}

/*
.usersview{
  width: 1075px;
  height: fit-content;
  display: flex;
  right: 0;
  top: 50px;
  left: 0;
  bottom: 0;
  margin: auto;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  border-radius: 16px;
  background: $white;
  border: 0.5px solid $chatline;
  box-shadow: 5px 20px 35px 10px $shadowmodal;
  height: auto;
  position: relative;
  top: 50px;
  margin-bottom: 20px;
}
*/
