//@include parallax-init;

.landing{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: auto;
  .title{
    margin-top: 0px;
    color: $black;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    line-height: 44px;
    text-align: center;
  }
  .description{
    margin-top: 0px;
    opacity: 0.45;
    color: $black;
    line-height: 24px;
    letter-spacing: 0;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    font-style: normal;
  }
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 85%;
    .logo{
      margin: 36px 20px 36px 20px;
      height: 30px;

    }
    .menu{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      div{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-style: normal;
        opacity: 0.8;
        font-size: 15px;
        letter-spacing: 0;
        color: $black;
        margin: 36px 20px 36px 20px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        &:hover{
          color: $blue;
        }
        &.signin{
          opacity: 1;
          height: 44px;
          width: 112px;
          border-radius: 22px;
          font-weight: bold;
        	font-style: normal;
          background: $graychatsent;
          margin: 36px 6px 36px 20px;
          &:hover{
            color: $black;
            filter: brightness(0.95);
          }
        }
        &.signup{
          opacity: 1;
          color: $white;
          background: $blueclassic;
          border-radius: 22px;
          height: 44px;
          width: 162px;
          font-weight: bold;
          font-style: normal;
          margin: 36px 20px 36px 6px;
          &:hover{
            background: #02BBE2;
          }
        }
      }
    }
  }
  .intro{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url(/images/landing/LeftBack.svg), url(/images/landing/RightBack.svg);
    background-position: left, right;
    background-repeat: no-repeat, no-repeat;
    border-bottom: 1px solid $dragline;
    width: 100%;
    .title{
      margin-top: 47px;
      width: 514px;
      font-size: 40px;
      line-height: 50px;
      .highlight{
        color: $blue;
      }
    }
    .description{
      width: 620px;
      margin-top: 35px;
      line-height: 30px;
      font-size: 18px;
    }
    .platform{
      box-shadow: 0px -12px 24px -3px #47656b1a;
      position: relative;
      height: 454px;
      width: 800px;
      margin-top: 60px;
      overflow: hidden;
      border-radius: 23px;
      img{
        position: absolute;
        &.mask{
          z-index: 3;
          width: -webkit-fill-available;
        }
        &.front{
          z-index: 2;
          width: 592px;
          margin-left: 59px;
        }
      }
    }
  }
  .summary{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 103px;
    width: 78%;
    div{
      width: 241px;
      text-align: center;
      img{
        width: 241px;
        height: 158px;
      }
      .title{
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 22px;
        margin-top: 10px;
      }
      .description{
        font-size: 15px;
        letter-spacing: 0;
      }

    }
  }
  .promotion{
    margin-top: 35px;
    width: 90%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 22px;
      margin-bottom: 40px;
      line-height: 48px;
    }
    .description {
      line-height: 18px;
      letter-spacing: 0;
      font-size: 15px;
    }
    .banner{
      display: flex;
      margin-bottom: 90px;
      opacity: 0.35;
      width: 105%;
      align-items: center;
      justify-content: center;
      position: relative;
      img.arrow{
        height: 14px;
        padding: 18px;
        border: 1px solid $borderproject;
        border-radius: 27px;
        margin: 0 40px;
        position: absolute;
        cursor: pointer;
        &.right{
          right: -60px;
        }
        &.left{
          left: -60px;
        }
      }
      .logos{
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin-left: 0;
        img{
          margin-right: 40px;
          height: 40px;
          filter: grayscale(1);
        }

      }
    }

  }
  .consultancy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 140px;
    width: 80%;

    .video1{
      width: 100%;
      position: relative;
      cursor: pointer;
      video{
        width: 100%;
        height: 100%;
      }
      .mask{
        display: flex;
        flex-direction: column;
        position: absolute;
        height: calc(100% - 120px);
        width: calc(100% - 120px);
        background: #09323a6b;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 60px;
        z-index: 2;
        img{
          margin-left: -25px;
        }
        .title{
          color: $white;
          font-size: 70px;
        }
        .description{
          color: $white;
          font-size: 15px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: 0.4px;
          margin-top: 20px;
          opacity: 1;
        }
      }
    }
    > .multicolors{
      height: 10px;
      width: 100%;
      background: linear-gradient(90deg, rgba(252,181,4,1) 0%, rgba(252,181,4,1) 25%, rgba(253,63,103,1) 25%, rgba(253,63,103,1) 50%, rgba(10,201,239,1) 50%, rgba(10,201,239,1) 75%, rgba(5,214,162,1) 75%, rgba(5,214,162,1) 100%);
    }
    > .multicolors2{
      height: 10px;
      width: 100%;
      background: linear-gradient(90deg, rgba(252,181,4,1) 0%, rgba(252,181,4,1) 25%, rgba(253,63,103,1) 25%, rgba(253,63,103,1) 50%, rgba(10,201,239,1) 50%, rgba(10,201,239,1) 75%, rgba(5,214,162,1) 75%, rgba(5,214,162,1) 100%);
    }
    .categories{
      height: 108px;
      border-bottom: 1px solid $borderproject;
      .subcategories{
        top: 108px;
        border-radius: 0 0 20px 20px;
      }
    }
    .explanation{
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 100px;
      flex-wrap: wrap;
      > div{
        width: calc(50% - 100px);
        padding: 50px;
        height: 508px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
          height: 240px;
          align-self: center;
        }
        .title{
          text-align: left;
          font-size: 22px;
          line-height: 34px;
          margin-bottom: 26px;
        }
        .description{
          text-align: left;
          line-height: 30px;
          font-size: 15px;
          width: 330px;
        }

        .yellow{
          margin: 25px 0;
          width: 60px;
          height: 6px;
          background: $yellow;
        }

        .red{
          margin: 25px 0;
          width: 60px;
          height: 6px;
          background: $red;
        }

        .blue{
          margin: 25px 0;
          width: 60px;
          height: 6px;
          background: $blue;
        }

        .green{
          margin: 25px 0;
          width: 60px;
          height: 6px;
          background: $green;
        }

      }

    }

  }
  .features{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 140px;
    width: 80%;
  //  .feature:nth-child(even) {.text{margin-left: 100px}}
  //  .feature:nth-child(odd) {.text{margin-right: 100px}}
    > img{
      width: 36px;
    }
    > .title{
      margin-top: 21px;
      font-size: 30px;
      line-height: calc(48/30);
    }
    > .description{
      width: 570px;
      margin-top: 22px;
      line-height: 30px;
      font-size: 15px;
    }
    .feature{
      display: flex;
      height: 600px;
      justify-content: center;
      align-items: center;
      perspective: 1px;
      > div{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .polygon{
        width: 620px;
        > img{
          pointer-events: none;
        }
        .polygon-canvas {
          position: absolute;
          overflow: hidden;
          display: flex;
          width: 680px;
          height: 600px;
          #canvas {
            height: 100%;
            width: 100%;
            z-index: -1;
          }
          .curtain {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            img {
              display: none;
            }
          }
        }
      }
      .text{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 405px;
        //@include parallax(-.2);
        img{
          width: 30px;
          padding: 26px;
          border: 1px solid $borderproject;
          border-radius: 22px;
        }
        .title{
          margin-top: 40px;
          font-size: 30px;
          line-height: 34px;
          text-align: left;
        }
        .description{
          margin-top: 30px;
          line-height: 30px;
          font-size: 15px;
          text-align: left;
        }
      }
    }
  }
  .footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 80px;
    border-top: 1px solid $borderproject;
    .contact{
      font-weight: 500;
      font-style: normal;
      font-size: 15px;
      color: #47656b8c;
      letter-spacing: 0;
      span {
        cursor: pointer;
        margin-left: 15px;
        color: $black;
        font-weight: 600;
        &:hover{
          color: $blue;
        }
      }
    }
    .logo{
      height: 24px;
    }
    .social{
      font-weight: 500;
      font-style: normal;
      color: $blackclassic;
      font-size: 15px;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      img{
        cursor: pointer;
        border-radius: 16px;
        opacity: 1;
        border: 1px solid #c4d0d3;
        width: 24px;
        height: 24px;
        padding: 10px;
        margin: 5px;
        &.instagram{
          &:hover{
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
          }

        }
        &.linkedin{
          &:hover{
            background: #0a66c2;
          }
        }
      }
    }
  }

}


.signinBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 300px;
  height: 300px;
  box-shadow: 4px 15px 35px 10px $shadowsign;
  border-radius: 16px;
  background: $white;
  z-index: 5;
  padding: 55px 30px 35px 30px;
  .textInput{
    margin: 8px;
  }
  .blueButton{
    margin-top: 17px;
    width: 250px;
  }
  .logo{
    width: 36px;
  }
  .sublogo{
    margin-top: 25px;
    margin-bottom: 23px;
    color: $black;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
    font-size: 15px;
  }
  .options{
    border-top: 1px solid #9AAFB440;
    padding-top: 11px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    border-radius: 6px;
    > div{
        padding: 6px;
        border-radius: 5px;
        &:hover{
          color: #09323A;
          opacity: 0.45;
          //background: #0000000d;
        //display: flex;
        }
    }
    .problems{
      cursor: pointer;
    }
    .signup{
      cursor: pointer;
    }

  }
  .blueButton{
    margin-bottom: 25px;
  }
}

/*** handling errors ***/
.no-curtains .curtain {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-curtains .curtain img {
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }
