
.mainScreen div.promotions-widget.widget{
  width: $doublewidth;
  display: flex;
  height: 437px;
  margin-top: 0;
  margin-bottom: 25px;
  .header {
    min-height: 86px;
    .buttons {
      margin: 25px;
      display: flex;
      img {
        cursor: pointer;
        border: 1px solid $borderproject;
        border-radius: 20px;
        padding: 10.5px 12.5px;
        margin-left: 10px;
        &:hover{
          background: $shadow;
        }
      }
    }
  }
  .body{
    display: flex;
    height: 100%;
    //min-width: 1075px;
        margin: 0 25px 25px 25px;
    overflow-x: auto;
    overflow-y: hidden;
    background: $white;
    .left{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      min-width: 505px;
      width: 60%;
      height: calc(100% - 72px);
      padding: 35px;
      margin: 0 20px 25px 0px;
      border: 1px solid $borderproject;
      border-radius: 16px;
      background-image: url(/images/complex/Gift.svg);
      background-size: 32%;
      background-repeat: no-repeat;
      background-position: 90% 30%;
      .close{
        border: 1px solid $borderproject;
        border-radius: 40px;
        padding: 8px;
        position: absolute;
        right: 25px;
        top: 25px;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
      &.invite {
        background-position: 90% 40%;
        background-image: url(/images/complex/Members.svg);
        input{
          width: 90%;
        }
      }
      .title{
        margin-top: 20px;
        font-weight: 600;
        font-style: normal;
        font-size: 24px;
        margin-bottom: 25px;
        letter-spacing: 0;
        color: $black;
        width: 282px;
        span{
          color: $redclassic;
        }
      }

      .description{
        font-weight: 500;
        font-style: normal;
        height: 100%;
        opacity: 0.6;
        line-height: 22px;
        font-size: 14px;
        letter-spacing: 0;
        color: $black;
        width: 50%;
        max-width: 380px;
      }
      .buttons{
        display: flex;
        width: 100%;
        justify-content: space-between;
        .whiteButton{
          position: relative;
          img{
            position: absolute;
            left: 15px;
          }
          &:first-child{
            margin-right: 15px;
            img{
              width: 21px;
              margin-right: 15px;
            }
          }
          &:last-child img{
            width: 14px;
            margin-right: 15px;
          }
        }
      }
    }
    .right{
      //min-width: calc(40% - 35px);
      //max-width: calc(40% - 35px);
      width: 430px;
      img{
        margin-bottom: 8px;

      }
      .email{
        border: 1px solid $borderproject;
        height: 72px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 10px;
        width: calc(100% - 2px);
        &:last-child{
          margin-bottom: 0px;
        }
        .initial{
          font-size: 16px;
          color: #557177;
          letter-spacing: 0.64px;
          font-weight: bold;
          font-style: normal;
          min-width: 44px;
          min-height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $borderproject;
          border-radius: 22px;
          margin: 15px;
        }
        img{
          margin: 15px;
          &.promo{
            border: 1px solid $standardborder;
            //background: #FFFFFF;
            //border: 0 solid rgba(196,208,211,0.75);
            box-shadow: 2px 8px 10px 0 rgba(206,213,220,0.10);
            border-radius: 30px;
            padding: 9px;

          }
        }
        .text{
          color: $black;
          font-size: 14px;
          margin-bottom: -2px;
          max-width: 220px;
          letter-spacing: 0.09px;
          font-weight: 600;
          font-style: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100% - 10px);
          margin: 0 5px;
        }
        .money{
          position: absolute;
          right: 25px;
          font-weight: bold;
          font-style: normal;
          color: $black;
          letter-spacing: 0.12px;
          font-size: 18px;

        }
        .prom{
          padding: 5px 10px;
          background: #AD53C9;
          border-radius: 6px;
          color: $white;
          margin-left: auto;
          font-weight: 600;
          font-style: normal;
          font-size: 13px;
          line-height: 18px;
          margin-right: 25px;
          &.pending {
            background: #9AAFB4;
          }
        }
      }
      .empty{
        background-image: url(/images/complex/MailInvitation.svg);
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: 95% 50%;
        border: 1px solid $borderproject;
        display: flex;
        flex-direction: column;
        height: 242px;
        border-radius: 16px;
        .title{
          margin-top: 60px;
          margin-left: 35px;
          font-weight: 600;
          font-style: normal;
          font-size: 24px;
          letter-spacing: 0;
          color: $black;
          width: 282px;
          span{
            color: $redclassic;
          }
        }

        .description{
          margin-top: 25px;
          font-weight: 500;
          margin-left: 35px;
          font-style: normal;
          opacity: 0.6;
          font-size: 14px;
          letter-spacing: 0;
          color: $black;
          width: 160px;
        }

      }

    }
  }
}
