.mainScreen{
  &::-webkit-scrollbar {
      display: none;
  }
  >.observer{
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
    min-height: fit-content;
  }
  div.widget{
    display: flex;
    position: relative;
    background: $white;
    border-radius: 16px;
    //box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.10);
    box-shadow: 4px 10px 15px 0 rgba(206,213,220,0.10);
    flex-direction: column;
    margin-top: 25px;
    max-width: 1075px;
    min-height: fit-content;
    > .title{
      font-weight: 600;
      font-style: normal;
      color: $black;
      letter-spacing: 0;
      font-size: 18px;
      margin: 35px 0 20px 35px;
      width: calc(100% - 35px);
    }
    > .body{
      //min-height: inherit;
      display: flex;
    //  margin: 35px 0 20px 35px;
      flex-direction: row;
    //  width: 100%;
    //  width: calc(100% - 50px);
    //  height: calc(100% - 77px);

    }
  }
}
.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 96px;
  min-height: 96px;
  align-items: center;
  &.empty{
    .title{
      div{
        margin-left: 30px;
        height: 20px;
        margin-right: 15px;
        width: 20px;
        height: 20px;
        background: $loading;
        opacity: 0.1;
        border-radius: 20px;
      }
      span{
        width: 50px;
        height: 15px;
        background: $loading;
        opacity: 0.08;
        border-radius: 20px;
      }
    }
    .whiteButton{
      border: 1px solid transparent;
      span{
        width: 50px;
        height: 15px;
        background: $loading;
        opacity: 0.08;
        border-radius: 20px;
      }
      &:hover{
        background: $white;

      }
    }
  }

  .title{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    min-height: fit-content;
    img{
      margin-left: 30px;
      height: 24px;
      margin-right: 15px;
    }
    span{
      margin-bottom: -2px;
    }
  }
  .whiteButton{
    width: auto;
    margin-right: 25px;
    padding: 0 25px;
    font-size: 15px;
    font-weight: 600;
    img{
      width: 24px;
    }
    img.go{
      margin-left: 15px;
      width: 12px;
    }
  }
}
.mainScreen .latestupdates{
  width: 60%;
  margin-left: 25px;
  height: 411px;
  &::-webkit-scrollbar {
      width: 4px;
      height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #E1E7E9;
    border-radius: 4px;
  }
  .header{
    width: calc(100% - 58px);
    margin-left: 29px;
    border-bottom: 1px solid $standardborder;
    .title img{
      margin-left: 0;
    }

  }
  .body.empty{
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    height: -webkit-fill-available;
    justify-content: center;
    margin-top: 35px;
    border: 1px solid $standardborder;
    background: $white;
    img{
      margin-top: 70px;
    }
    .title{
      font-weight: 600;
      font-size: 18px;
      color: #09323A;
      letter-spacing: 0;
      margin-top: 30px;
      line-height: 22px;

    }
    .description{
      font-weight: 500;
      font-size: 14px;
      color: #09323A80;
      line-height: 22px;
      letter-spacing: 0;
      text-align: center;
      width: 348px;
      margin-top: 17px;
    }

  }

  .header .badge{
    position: absolute;
    right: 35px;
    font-weight: bold;
    font-style: normal;
    font-size: 13px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 32px;
    background: $blueclassic;
    color: $white;
    border-radius: 12px;
    margin-left: auto;
    &.off{
      background: #E9F1F3;
      color: $blackclassic;
    }
  }
  div.body{
    flex-direction: column;
    height: calc(100% - 77px);
    overflow: auto;
    align-items: center;
    margin: 0 8px;
    &.full{
      padding: 10px 20px 20px 20px;
    }
    &.empty{
      overflow: hidden;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 25px 25px;
      border-radius: 10px;
      box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.15);
      height: 100%;
      width: calc(100% - 50px);
    }
    .title{
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0;
    }
    .description{
      text-align: center;
      margin-top: 25px;
      margin-bottom: 50px;
      font-weight: 600;
      font-size: 15px;
      opacity: 0.5;
      width: 370px;
      max-width: 80%;
      letter-spacing: 0;
      line-height: 18px;
    }
    .update{
      padding: 12px 7px;
      min-height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      border-radius: 12px;
      &:hover{
        background: #EEF2F380;
      }

      &:first-child .icon::after{
        display: none;
      }
      .user{
        margin-right: 15px;
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      .inner{
        min-height: 14px;
        max-height: 90px;
        overflow: hidden;
        max-width: calc(100% - 173px);
        span{
          opacity: 0.8;
          color: $black;
          letter-spacing: 0.16px;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          &.bold{
            font-weight: bold;
            font-style: normal;
            &:hover{
              text-decoration: underline;
            }

          }
        }

      }
      .unread{
        position: absolute;
        right: 100px;
        top: 18px;
        background: #FB8C72;
        border-radius: 4px;
        width: 8px;
        height: 8px;
      }
      .date{
        top: 18px;
        position: absolute;
        right: 18px;
        text-align: right;
        font-weight: 500;
        font-style: normal;
        font-size: 13px;
        opacity: 0.25;
        color: $black;
        letter-spacing: 0.13px;
        width: 82px;
      }
    }
  }
}
