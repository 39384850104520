.stories-widget{
  display: flex;
  flex-wrap: wrap;
  width: $doublewidth;
  min-height: 180px!important;
  background: $white;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 4px 10px 15px 0 $shadow;
  &.preview .header{
    height: 73px;
    .badge{
      position: absolute;
      right: 35px;
      font-weight: bold;
      font-style: normal;
      font-size: 13px;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 32px;
      background: $blueclassic;
      color: $white;
      border-radius: 12px;
      margin-left: auto;
      &.off{
        background: #E9F1F3;
        color: $blackclassic;
      }
    }

  }
  .body{
    height: 84px;
    max-width: calc(100% - 50px);
    margin: 0 25px;
    overflow-y: hidden;
    &.empty{
      .story{
        div{
          width: 40px;
          height: 40px;
          background: $loading;
          opacity: 0.1;
          border-radius: 10px;
        }
      }
    }
  }
  .separator{
    min-width: 1px;
    opacity: 0.1;
    height: 37px;
    margin: 15px 0 35px 0;
    background: $black;
  }
  .story{
    min-width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    margin: 10px 5px 30px 5px;
    border: 2px solid $greenclassic;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
      filter: brightness(0.95);
    }
    &.viewed{
      border: 2px solid $storyviewed;
    }
    &.unviewed{
      cursor: pointer;
      border: 2px solid $greenclassic;
    }
    &.none{
      border: 2px solid $storyviewed;
      img{
        height: 20px;
        width: 20px;
        padding: 10px;
        border-radius: 10px;
        background: #DBEAED80;
      }
      &:hover{
        filter: brightness(1);
      }
    }
    &:first-child {
      margin: 10px 16px 30px 0px;
      background: white;
      border: 2px solid #a2e7d6;
      cursor: pointer;
      &:hover{
        filter: brightness(1);
        border: 2px solid #43d8b3;
        img{
          padding: 16px;
        }
      }
      &:active {
        transform: scale(0.95);
      }
      img {
        transition: 0.5s;
        width: 12px;
        padding: 14px;
        height: 12px;
        background: $greenclassic2;
        border-radius: 10px;
      }
    }
    &:nth-of-type(2) {
      margin: 10px 5px 30px 15px;
      &::before{
        content: '';
        position: absolute;
        width: 1px;
        margin-right: 82px;
        height: 37px;
        background: $standardborder;
      }

    }
    img{
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }

  }

}


.viewStory{
  width: 800px;
  height: fit-content;
  display: flex;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  border-radius: 16px;
  background: $white;
  border: 0.5px solid $chatline;
  box-shadow: 5px 20px 35px 10px $shadowmodal;
  .emptyvideo{
    height: 450px;
    background: #09323A;
    width: 800px;
  }
  video{
    background: #000000;
    width: 800px;
    height: 450px;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
  }
  circle {
    //transition: stroke-dashoffset 0.05s;
    //transform: rotate(-90deg);
    stroke-linecap: round;
    transform-origin: 50% 50%;
  }
  .panel{
    background: $black;
    height: 108px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .progressRing{
      margin-left: 25px;
      margin-right: 20px;
      .author{
        width: 50px;
        border-radius: 25px;
        margin-left: -65px;
      }
    }
    .info{
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: -webkit-fill-available;
      overflow: hidden;
      span{
        font-size: 16px;
        letter-spacing: 0;
        font-weight: bold;
        font-style: normal;
        color: $white;
        &:last-child{
          margin-top: 10px;
          font-size: 13px;
          opacity: 0.5;
        }
      }

    }
    .button{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f2f6f763;
      margin: 5px;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border-radius: 30px;
      cursor: pointer;
      transition: all .25s linear;
      &:hover{
        background-color: #F7F9FB20;
      }
      //E12D4B
    }
    .button2{
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 76px;
      max-width: 76px;
      height: 100%;
      cursor: pointer;
      background-color: #163F47;
      &:first-child{
        margin-left: 21px;
      }
      &:last-child{
        background-color: #F7F9FB30;
        &:hover{
          background-color: #F7F9FB50;
        }

      }
      &:hover{
        background-color: #F7F9FB40;
        img{
          opacity: 0.6;
        }
      }
    }

  }
}
.newStory{
  width: 800px;
  height: fit-content;
  overflow: hidden;
  video{
    height: fit-content;
    width: 800px;
    z-index: 0;
  }
  .panel{
    position: relative;
    background: $black;
    height: 108px;
    width: calc(100% - 50px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 25px;
    .button{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f2f6f763;
      width: 50px;
      height: 50px;
      border-radius: 30px;
      cursor: pointer;
      transition: all .25s linear;
      &:hover{
        background-color: rgba(255,255,255,0.6);
      }

    }
    .time{
      padding: 4px 10px;
      right: 25px;
      position: absolute;
      border-radius: 12px;
      color: $white;
      background: rgba(255, 255, 255, 0.25);
      margin: auto;
      width: fit-content;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0.64px;
    }
    .middle{
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .center{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background-image: linear-gradient(151deg, #FFFFFF 0%, $gray 95%);
        cursor: pointer;
        .record{
          width: 22px;
          height: 22px;
          border-radius: 11px;
          background-image: linear-gradient(151deg, #BF0028 0%, #FD3F67 95%);
          &.ing{
            img{
              height: 14px;
              width: 60px;
            }
            &::after{
              content: '';
              z-index: 2;
              border-radius: 18px;
              position: absolute;
              animation-name: recording;
              animation-duration: 1.5s;
              animation-direction: alternate;
              animation-iteration-count: infinite;
              animation-play-state: running;
              background-image: linear-gradient(151deg, #BF002866 0%, #FD3F6766 95%);
            }
          }
        }
        &.upload{
          background-image: linear-gradient(151deg, #18B2FF 1%, #0A7BFF 98%);
        }
      }
    }
    .button{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f2f6f763;
      margin: 5px;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border-radius: 30px;
      cursor: pointer;
    }
    .button2{
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 76px;
      max-width: 76px;
      height: 100%;
      cursor: pointer;
      &:hover{
        background: $hover;
        img{
          opacity: 0.6;
        }
      }
    }

  }
  .loading{
    img{
      height: 74px;
      width: 74px;
      margin-bottom: 65px;
      animation: rotation 2s infinite linear;
    }
    .description{
      width: 214px;
    }
  }
  .screen{
    img{
      width: 250px;
      margin-top: -100px;
    }
    .description{
      width: 350px;
    }

  }
  .record{
    background: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 405px;
    .square{
      border: 4px solid #6397A2;
      border-radius: 15px;
      position: absolute;
      margin: auto;
      top: 40px;
      bottom: 40px;
      left: 40px;
      right: 40px;
      &::before {
        content: "";
        position: absolute;
        top: 50px;
        bottom: 50px;
        left: -50px;
        right: -50px;
        background-color: $black;
      }
      &::after {
        content: "";
        position: absolute;
        top: -50px;
        bottom: -50px;
        left: 50px;
        right: 50px;
        background-color: $black;
      }
      img{
        position: absolute;
        z-index: 2;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      .rec{
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 2;
        left: 24px;
        top: 24px;
        font-weight: bold;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 1.78px;
        color: $white;
        .redrec{
          margin-right: 8px;
          height: 16px;
          width: 16px;
          border-radius: 8px;
          background: #FD3F67;
        }
      }
    }
  }

  .loading, .screen{
    background: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .title{
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      letter-spacing: 0;
      color: $white;
      margin-bottom: 20px;
    }
    .description{
      opacity: 0.38;
      font-weight: 500;
      font-style: normal;
      font-size: 15px;
      letter-spacing: 0;
      text-align: center;
      color: $white;

    }
  }

}
@keyframes recording {
  0%, 100% {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  50% {
    left: -5px;
    right: -5px;
    top: -5px;
    bottom: -5px;
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
