.dashboard{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  /*
  &::before {
    content: "";
    position: absolute;
    top: $topbar;
    bottom: 0;
    left: $leftbar;
    right: $rightbar;
    background-color: $white;
  }
  */
  > .create{
    position: fixed;
    bottom: 25px;
    left: 25px;
    padding: 18px;
    background: $white;
    border: 1px solid $borderproject;
    border-radius: 14px;
    box-shadow: 2px 4px 3px 0 #ced5dc26;
    cursor: pointer;
    &:hover{
      background: $hover;
    }
    &:hover + .menuCreate {
      display: flex;
    }

  }
}
.leftBar{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white;
  position: fixed;
  width: $leftbar;
  height: calc(100vh - 78px);
  top: $topbar;
  left: 0;
  .shadow{
    position: absolute;
    width: 90px;
    height: 72px;
    background: #F7F9FB;
    border-radius: 8px;
    transition: all 0.5s ease 0s;
  }
  > div {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: $leftbar;
    min-height: 92px;
    max-height: 92px;
    cursor: pointer;
    &.empty{
      div{
        width: 20px;
        height: 20px;
        background: $loading;
        opacity: 0.1;
        border-radius: 20px;
      }
      span{
        width: 50px;
        height: 15px;
        background: $loading;
        opacity: 0.08;
        border-radius: 20px;
      }
    }
    img{
      width: 20px;
    }
    span{
      margin-top: 15px;
      font-size: 12px;
      color: $graymenu;
      letter-spacing: 0.14px;
    }
    &.selected{
      span{
        font-weight: 600;
      	font-style: normal;
        color: $black;
      }
    }

  }
  .logo{
    justify-content: center;
    display: flex;
    align-items: center;
    height: $topbar + 20px;
    width: $leftbar;
    img{
      width: 42px;
      margin-bottom: 20px;
    }
  }
}

.rightBar{
  position: fixed;
  pointer-events: none;
  width: $rightbar;
  display: flex;
  flex-direction: column;
  height: 100%;
  right: 0;
  border-top-right-radius: 25px;
  border-left: 1px solid $standardborder;
  .upprofile{
    background: $white;
    pointer-events: auto;
    .profile{
      display: flex;
      height: $topbar;
      justify-content: space-between;
      align-items: center;
      img {
        height: 40px;
        width: 40px;
        border-radius: 10px;
        position: absolute;
        right: 35px;
        &.sets{
          position: absolute;
          right: 0;
          width: 12px;
          padding: 12px;
          padding-left: 55px;
          cursor: pointer;
        }
      }
      div {
        margin-left: 35px;
        line-height: 22px;
      }
      &.empty{
        div{
          opacity: 0.08;
        }
        span{
          height: 40px;
          width: 40px;
          margin-right: 35px;
          opacity: 0.1;
          border-radius: 20px;
          background: $loading;
        }
      }
    }
    .settings{
      margin: 0 25px;
      padding: 20px 0;
      border-top: 1px solid $standardborder;
      div {
        height: 50px;
        width: calc(100%);
        padding-left: 20px;
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 10px;
        &:hover {
          background: $hover;
        }
        img{
          margin-right: 21px;
          margin-left: -2px;
        }
        span{
          font-weight: 600;
          font-style: normal;
          font-size: 15px;
          color: $black;
          margin-bottom: -2px;
        }
      }
      &.close{
        display: none;
      }
    }
  }
}
.menuCreate{
  display: none;
  flex-direction: column;
  background-clip: padding-box;
  position: absolute;
  bottom: 85px;
  left: 10px;
  background: $white;
  border: 1px solid $borderproject;
  box-shadow: 2px 4px 3px 0 #ced5dc26;
  border-radius: 14px;
  width: 250px;
  margin: 10px;
  z-index: 2;
  &::after{
    content: '';
    z-index: -1;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }
  &:hover{
    display: flex;
  }
  div{
    &:hover{
      background: $hover;
    }
    img{
      margin-right: 20px;
    }
    border-bottom: 1px solid $chatine;
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 60px;
    cursor: pointer;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: $black;
    letter-spacing: 0;
  }
}

.topBar{
  position: fixed;
  display: flex;
  flex-direction: row;
  background: $white;
  align-items: center;
  height: $topbar;
  left: 0;
  z-index: 10;
  right: $rightbar + 1;
  border-bottom: 1px solid $standardborder;
  .logo{
    margin: 0;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
      height: 22px;
    }
  }
  .help{
    background: $yellowclassic;
    height: $topbarboxh;
    width: $topbarboxh;
    border-radius: $topbarboxh/2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    margin: 0;
  }
  .invite{
    background: $blueclassic;
    border-radius: 25px;
    border: 1px solid $blueclassic;
    height: $topbarboxh;
    padding-right: 25px;
    display: flex;
    margin-left: 10px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &.empty{
      background: $loading;
      opacity: 0.1;
      &:hover{
        background: $loading;
      }
    }
    &:hover{
      background: #02BBE2;
    }

    .whiteCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white;
      border-radius: 25px;
      margin: 10px;
      width: $topbarboxh - 20px;
      height: $topbarboxh - 20px;
    }
    span {
      font-weight: 600;
      font-style: normal;
      color: $white;
    }
  }
  .create{
    height: 16px;
    padding: 14px;
    width: 16px;
    border: 1px solid $borderproject;
    border-radius: 40px;
    cursor: pointer;
    position: absolute;
    right: 80px;
  /*  &:hover{
      background: $hover;
    }
    &:hover + .menuCreate {
      display: flex;
    }*/
  }
  .menuCreate{
    display: flex;
    top: 75px;
    right: -3px;
    bottom: auto;
    left: auto;
    &::after{
      content: '';
      z-index: -1;
      position: absolute;
      top: -30px;
      bottom: -30px;
      left: -30px;
      right: -30px;
    }

  }
  .notifications{
    display: flex;
    align-items: center;
    > .notification{
      height: $topbarboxh;
      width: $topbarboxh;
      cursor: pointer;
      border-radius: $topbarboxh/2;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 20px;/* + $topbarboxh;*/
      margin: 0;
      cursor: pointer;
      background: $white;
      border: 1px solid $borderproject;
      transition: 0.3s;
      &.empty{
        div{
          height: 20px;
          width: 20px;
          opacity: 0.08;
          border-radius: 4px;
          background: $loading;

        }
      }
      &:hover{
        background: $hover;
      }
      &.on{
        background: $redclassic2;
        border: 1px solid $redclassic2;
      }
      &.off{
        background: $white;
        border: 1px solid $borderproject;
      }
      span{
        background: $redclassic;
        height: 22px;
        width: 22px;
        border-radius: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-weight: bold;
        font-style: normal;
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 12px;
      }
    }
  }
  .projects {
    background: $white;
    border: 1px solid $standardborder;
    border-radius: 25px;
    height: $topbarboxh;
    min-width: $topbarboxw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:hover{
      background: $hover;
      border-radius: 25px;
    }
    .project{
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 400px;
      height: 100%;
      cursor: pointer;
      justify-content: center;
      &.empty{
        min-width: 300px;
        justify-content: flex-start;
        &:hover{
          background: $white;
          border-radius: 100px;
        }
        div{
          margin: 0 15px;
          height: 20px;
          width: 20px;
          opacity: 0.1;
          border-radius: 10px;
          background: $loading;
        }
        span{
          margin: 0 5px;
          height: 7px;
          width: 100px;
          opacity: 0.08;
          border-radius: 4px;
          background: $loading;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      img{
        margin-left: 28px;
        margin-right: 22px;
        height: 20px;
        border-radius: 5px;
        &.select{
          height: 10px;
        }
      }
      img.logo{
        border: 1px solid $standardborder;
        background: white;
      }
      .logo{
        height: 20px;
        margin-left: 20px;
        margin-right: 12px;
        border-radius: 5px;
        min-width: 20px;
        max-width: 20px;
        color: white;
        font-weight: 800;
        font-style: normal;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span{
        font-weight: 600;
        font-style: normal;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .projectsList {
      display: flex;
      flex-direction: column;
      background: $white;
      position: absolute;
      max-height: 600px;
      overflow-y: auto;
      overflow-x: hidden;
      top: 86px;
      width: calc(100% - 8px);
      margin-right: 8px;
      max-width: 400px;
      z-index: 2;
      border: 1px solid $borderproject;
      box-shadow: 2px 4px 3px 0 #ced5dc26;
      border-radius: 10px;
    /*  &::after{
        content: '';
        z-index: -1;
        position: absolute;
        top: -20px;
        bottom: -20px;
        left: -20px;
        right: -20px;
      }*/
      &:hover{
        display: flex;
      }
      &:active:hover{
        //display: none; //like click element! Awesome
      }

      > div{
        position: relative;
        min-height: 60px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid $standardborder;
        &:hover{
          background: $hover;/*#0000000a;*/
          //background: #f7f9fb99;
        }
        &:last-child img{
          border: 1px solid transparent;
        }

        .logo{
          height: 24px;
          margin-left: 22px;
          border-radius: 5px;
          margin-right: 21px;
          min-width: 24px;
          max-width: 24px;
          color: white;
          font-weight: 800;
          font-style: normal;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img{
          border: 1px solid $standardborder;
          border-radius: 5px;
          background: white;
        }
        span{
          font-weight: 600;
          font-style: normal;
          margin-bottom: -1px;
          width: 100%;
          font-size: 14px;
          color: $black;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .edit{
          border: 1px solid transparent;
          width: 20px;
          margin: 12px;
          border-radius: 20px;
          padding: 7px;
          transition: 0.3s;
          background: transparent;
          &:hover{
            background: #ffffff;/*#0000000a;*/
          }
        }
      }
    }
  }
}
.mainScreen {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: $leftbar;
  right: $rightbar;
  top: $topbar;
  height: calc(100% - 79px);
  background: $gray;
  overflow-y: auto;
  overflow-x: hidden;
  &.long{
    right: 0px;
  }
  .meetlatest{
    display: flex;
    max-width: 1075px;
    width: calc(100% - 50px);
    min-height: fit-content;
  }
  /*
  header.toptitle{
    height: 128px;
    width: 100%;
    &.row{
      display: flex;
      align-items: center;
      margin-top: 20px;
      height: 50px;
      .name{
        padding: 0;
        margin-left: 25px;
        font-weight: bold;
        font-style: normal;
        font-size: 22px;
      }
    }
    .whiteButton{
      flex-direction: row;
      width: 120px;
      margin-left: 25px;
    }
    .name {
      font-weight: normal;
      font-style: normal;
      font-size: 18px;
      letter-spacing: 0;
      color: $black;
      padding-left: 40px;
      padding-top: 44px;
    }
    .updates{
      font-size: 18px;
      letter-spacing: 0;
      font-weight: bold;
      font-style: normal;
      color: $black;
      width: 100%;
      padding-left: 40px;
      padding-top: 8px;
      padding-bottom: 40px;
    }

  }

  */

}
