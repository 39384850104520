.modal .viewConsult{
  width: 645px;
  height: fit-content;
  top: 50px;
  bottom: auto;
  margin-bottom: 50px;
  .body {
    .top{
      padding: 32px 40px 0px 40px;
    }
    .subtop{
      padding: 20px 40px 20px 40px;
    }
    .title{
      margin: 0px 40px 20px 40px;
    }
    .description{
      padding: 0 40px 0px 40px;
    }
    .bottom{
      padding: 0 40px;
    }
  }
}

.viewConsult{
  border-radius: 16px;
  cursor: pointer;
  &::after{
    content: '';
    position: absolute;
    bottom: -25px;
    height: 25px;
    width: 100%;
    background: transparent;
  }
  .topauthor{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 4px);
    width: 100%;
    padding: 2px 0;
    .author{
      font-size: 14px;
      color: $blackclassic;
      letter-spacing: 0;
      line-height: 18px;
      font-weight: bold;
      font-style: normal;
    }
    .role{
      opacity: 0.45;
      font-size: 13px;
      color: $black;
      letter-spacing: 0.13px;
      font-weight: 500;
      font-style: normal;
    }
  }
  .body{
    display: flex;
    flex-direction: column;
    background: $white;
    .top {
      padding: 32px 30px 0px 30px;
      height: 40px;
      display: flex;
      align-items: center;
      > img{
        margin-right: 15px;
        height: 100%;
        border-radius: 10px;
      }
      .date{
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        img{
          margin-right: 10px;
        }
        align-items: center;
        white-space: nowrap;
        background: #F7F9FB;
        border-radius: 24px;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        color: #47656bcc;
        letter-spacing: 0;
        line-height: 18px;
        min-height: 12px;
        max-height: 12px;
        padding: 10px;
      }
    }
    .subtop{
      display: flex;
      padding: 20px 30px 20px 30px;
      div{
        display: flex;
        background: #e4ecef80;
        border-radius: 6px;
        padding: 5px 10px;
        align-items: center;
        margin-right: 5px;
        .point{
          padding: 0;
          min-width: 6px;
          min-height: 6px;
          max-width: 6px;
          max-height: 6px;
          border-radius: 3px;
          background: $white;
          margin-right: 8px;
        }
        span{
          font-weight: 500;
          font-style: normal;
          font-size: 13px;
          color: $white;
          letter-spacing: 0;
          line-height: 18px;
        }

      }
    }
    .title{
      margin: 0px 30px 20px 30px;
      font-weight: 600;
      font-style: normal;
      font-size: 24px;
      color: $black;
      letter-spacing: 0;
    }
    .description {
      padding: 0 30px 0px 30px;
      min-height: 50px;
      overflow-y: hidden;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #47656bbf;
      letter-spacing: 0;
      line-height: 24px;
    }
    .bottom{
      height: 60px;
      border-top: 1px solid $standardborder2;
      padding: 0 30px;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .element{
        display: flex;
        &:last-child{
          max-width: 95px;
          min-width: 82px;
          justify-content: flex-start;
          cursor: pointer;
        }
        span{
          margin-bottom: -2px;
        }
        img{
          height: 17px;
          margin-right: 12px;
        }
        .text{
          opacity: 0.8;
          font-weight: 500;
          font-size: 14px;
          color: #47656B;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
  }
  .answering{
    background: $white;
    display: flex;
    align-items: center;
    padding: 17px 40px 20px 40px;
    border-bottom: 1px solid $chatine;
    border-top: 1px solid $chatine;
    span{
      margin-left: 8px;
      font-size: 14px;
      color: $blackclassic;
      letter-spacing: 0;
      font-weight: 600;
      font-style: normal;
      width: 100%;
    }
    .authors{
      display: flex;
      flex-direction: row-reverse;
      img{
        margin-left: -8px;
        background: $white;
        border-radius: 6px;
        border: 2px solid $white;
        height: 24px;
        width: 24px;
      }
    }
  }
  .answer{
   border-top: 1px solid #EEF2F3;
   .top{
      display: flex;
      height: 40px;
      margin: 32px 40px 20px 40px;
      width: calc(100% - 80px);
      img{
        border-radius: 10px;
        height: 40px;
        width: 40px;
        margin-right: 15px;
      }
      .date {
        display: flex;
        align-items: center;
        min-width: 100px;
        img{
          height: 14px;
          width: 14px;
          margin-right: 10px;
        }
        span{
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          color: $blackclassic;
          opacity: 0.8;
          line-height: 18px;
        }
      }
    }
    > .middle{
      border-top: 1px solid $standardborder;
      margin: 0px 40px 0 40px;
      padding: 20px 0 30px 0;
      width: calc(100% - 80px);
      .body{
        word-wrap: break-word;
        font-weight: 500;
        font-style: normal;
        opacity: 0.8;
        letter-spacing: 0;
        line-height: 18px;
        color: $blackclassic;
        font-size: 15px;
      }
      .replies{
        margin: 30px 0 0 50px;
        .title{
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          img{
            width: 17px;
            height: 17px;
            margin-right: 12px;
          }
          span{
            font-weight: 600;
            font-style: normal;
            font-size: 15px;
            color: $blackclassic;
            letter-spacing: 0;
          }
        }
        .answerReply{
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding-top: 25px;
          border-top: 1px solid $chatine;
          margin-bottom: 25px;
          img{
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
          > .middle{
            margin-left: 15px;
            .name{
              margin-bottom: 6px;
              font-weight: bold;
              font-style: normal;
              font-size: 15px;
              color: $blackclassic;
              letter-spacing: 0.17px;
            }
            .body{
              width: 450px;
              word-wrap: break-word;
              font-weight: 500;
              font-style: normal;
              opacity: 0.8;
              letter-spacing: 0;
              line-height: 18px;
              color: $blackclassic;
              font-size: 15px;
            }

          }
        }
      }
    }

  }
  .gapGray{
    opacity: 0.3;
    height: 7px;
    width: 100%;
    background: #EAF3F5;
  }
  .end{
    border-radius: 16px;
    border-top: 1px solid $chatine;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    background: $white;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0.13;
    color: #09323a73;
    font-size: 13px;
  }
  .quickreply{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 40px;
    min-height: 80px;
    border-bottom: 1px solid $standardborder;
    border-top: 1px solid $standardborder;
    .input{
      border-radius: 24px;
      background: #eaf3f54d;
      width: 100%;
      min-height: 38px;
      margin: 20px 20px 20px 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      img{
        width: 24px;
        border-radius: 12px;
        margin: 7px;
        &.send{
          border-radius: 0px;
          margin-right: 10px;
          width: 18px;
        }
      }
      .complexinput{
        width: 100%;
        resize: none;
        border: 0;
        margin: 8px 10px -8px 0;
        padding-right: 15px;
        background: transparent;
        &::placeholder{
          font-weight: 500;
          font-style: normal;
          opacity: 0.6;
          color: $black;
          letter-spacing: 0.38px;
          font-size: 14px;
        }
      }
    }
    .useful{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 25px;
      margin-bottom: 20px;
      min-width: 98px;
      max-width: 98px;
      min-height: 38px;
      max-height: 38px;
      border: 1px solid $borderproject;
      cursor: pointer;
      padding: 0 10px;
      span{
        margin-left: 14px;
        font-size: 14px;
        color: $black;
        letter-spacing: 0;
        font-weight: 500;
        font-style: normal;
      }
      img{
        width: 18px;
      }
    }
  }
}

.modal > div.newConsult{
  width: 863px;
  height: auto;
  position: relative;
  top: 50px;
  margin-bottom: 20px;
  align-items: center;
  .errortag{
    top: -50px;
    right: 20px;
  }
  .information{
    width: calc(100% - 54px);
    padding: 25px 27px;
    outline: 0px solid transparent;
    border: 0px solid transparent;
    min-height: 210px;
    resize: vertical;

    opacity: 0.8;
    font-family: Helvetica;
    font-size: 15px;
    color: #47656B;
    letter-spacing: 0;
    line-height: 18px;
  }
  .header{justify-content: flex-start;}
  .categories{
    height: 98px;
    margin: 0;
    border-bottom: 1px solid $chatine;
    .category{
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      &::before{
        margin-left: -125px;
      }
      border-bottom: 2px solid transparent;
      &:hover{
        border-radius: 0;
        background-image: linear-gradient(0deg, #F7F9FB 0%, #FFFFFF 100%);
        border-bottom: 2px solid #16DAf4;
      }
    }
    .subcategories{
      top: 98px;
      z-index: 1;
      .subcategory{
        span{
          margin-left: 10px;
        }
      }
    }
  }
  .technologies{
    width: calc(100% - 50px);
    padding: 25px;
    .newtechnology{
      z-index: 5;
    }
  }
  .input{
    width: 100%;
    input{
      width: 100%;
    }
    span{
      white-space: nowrap;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      line-height: 18px;
      color: #fff;
      border-radius: 6px;
      padding: 5px 8px;
      margin: 4px;
      background: $blackclassic;
      &:last-child{
        margin-right: 25px;
      }
      &.subcategorytag{
        background: #9AAFB4;
      }

    }
  }
  .footer{
    width: 100%;
    position: sticky;
    bottom: 0px;
    z-index: 5;
    background: $white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

}


.mainScreen .consult-widget{
  display: flex;
  width: $doublewidth;
  background: $white;
  border-radius: 16px;
  box-shadow: 2px 4px 3px 0 rgba(206,213,220,0.10);
  flex-direction: column;
  &.none{
    height: 315px;
    background-image: url(/images/background/ConsultLeft.svg), url(/images/background/ConsultRight.svg), url(/images/background/PatternConsult1.svg), url(/images/background/PatternConsult2.svg), ;
    background-position: 25px 25px, calc(100% - 25px) calc(100% - 25px), 40px 40px, calc(100% - 40px) calc(100% - 40px);
    background-repeat: no-repeat, no-repeat;
    background-size: 25%, 25%, 130px, 130px;
    display: flex;
    justify-content: center;
    position: relative;
    .muneco{
      position: absolute;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 250px;
    }
    .body{
      border-radius: 16px;
      padding: 10px;
      margin-left: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-style: normal;
      color: $black;
      letter-spacing: 0;
      width: 300px;
      .title{
        font-weight: bold;
        font-size: 32px;
        width: 200px;
      }
      .description{
        margin-top: 15px;
        margin-bottom: 50px;
        font-weight: 500;
        font-size: 16px;
        width: 350px;
        line-height: 22px;
        opacity: 0.5;
      }
      .blueButton{
        font-weight: 600;
        font-size: 15px;
        width: 192px;
        justify-content: space-between;
        .go{
        }
      }
    }
  }
  &.full{
    background: transparent;
    box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0);
    .fullbody{
      min-width: 1045px;
      column-width: 505px;
      column-gap: 35px;
      column-count: 2;
      > img{
        box-shadow: 2px 8px 10px 0 rgba(206, 213, 220, 0.2);
        margin-bottom: 35px;
      }
      .noconsults{
        height: 376px;
        background-image: url(/images/background/NoConsults.png);
        background-position: 30px 30px;
        background-size: calc(100% - 60px) calc(100% - 60px);
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 290px;
          margin-bottom: 30px;
        }

      }

    }
    > .body{
      //overflow-x: scroll;
    }
    > .header{
      margin-bottom: 35px;
      background: $white;
      box-shadow: 4px 10px 15px 0 rgba(206,213,220,0.10);
      border-radius: 10px;
      .whiteButton{padding: 0 20px 0 10px;}
      .whiteButton img{
        height: 10px;
        padding: 7px 0px;
        border: 1px solid $chatine;
        border-radius: 22px;
        margin-right: 8px;
      }
    }
    .categories{
      height: 97px;
      border-top: 1px solid $chatine;
      border-bottom: 1px solid $chatine;
      margin-bottom: 20px;
      .subcategories{
        top: 97px;
        z-index: 1;
      }
    }
    .edheader{
      .whiteButton{
        font-weight: 600;
        color: $blackclassic;
      }
    }
    .edheader{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      margin: 40px;
      height: 60px;
      width: calc(100% - 80px);
      position: relative;
      .title{
        margin-top: 5px;
        width: 45%;
        color: $black;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
      }
      .description{
        margin-top: 12px;
        width: 60%;
        opacity: 0.4;
        color: $black;
        letter-spacing: 0;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
      }
      .subcat{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 276px;
        height: fit-content;
        border-radius: 25px;
        width: fit-content;
        padding: 10px;
        border: 1px solid #1EE088;
        background: #E7FFEF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: 0;
          color: $black;
          margin: 0 10px;
        }
        img{
          cursor: pointer;
          background: #05D677;
          padding: 5px;
          border-radius: 12px;
        }
      }
      .search{
        position: absolute;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        height: 44px;
        width: 260px;
        border-radius: 25px;
        border: 1px solid $borderproject;
        display: flex;
        img{
          margin-left: 16px;
          margin-right: 11px;
        }
        input{
          width: 100%;
          border: none;
          background: transparent;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          color: $blackclassic;
          font-family: 'Gilroy', Arial, sans-serif;
        }
      }
    }
  }
  &.preview{
    height: 424px;
    background: $white;
    display: flex;
    max-width: 1075px;
    align-items: center;
    justify-content: flex-start;
    >.body{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background: #F9FAFC;
      width: calc(100% - 50px);
      height: 303px;
      margin-bottom: 25px;
      border-radius: 10px;
      overflow: auto;
      &.empty{
        .consult{
          div{
            margin: 20px;
            width: 134px;
            height: 85px;
            background: $loading;
            opacity: 0.1;
            border-radius: 20px;
          }
          span{
            margin-top: 22px;
            width: 171px;
            height: 46px;
            border-radius: 22px;
            background: $loading;
            opacity: 0.08;
          }
        }
      }
      .shadowconsult {
        background: $white;
        min-width: 238px;
        max-width: 238px;
        height: 263px;
        margin-right: 11px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .top{
          width: 188px;
          margin: 25px;
          display: flex;
          align-items: center;
          span{
            font-weight: 500;
            font-style: normal;
            font-size: 14px;
            color: $blackclassic;
            opacity: 0.8;
            line-height: 18px;
            letter-spacing: 0;
          }
          img{
            margin-right: 12px;
          }
        }
        .middle{
          width: 188px;
          height: 136px;
          display: flex;
          flex-direction: column;
          .title{
            height: 18px;
            width: 194px;
            opacity: 0.1;
            margin-bottom: 22px;
            background: $loading;
            border-radius: 8px;
          }
          .body{
            height: 10px;
            width: 194px;
            margin-bottom: 10px;
            opacity: 0.1;
            background: $loading;
            border-radius: 6px;
          }
        }
        .bottom{
          width: 188px;
          height: 58px;
          border-top: 1px solid $chatine;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          .authors{
            display: flex;
            width: 100%;
            justify-content: flex-end;
            img{
              margin-left: -18px;
              border: 2px solid $white;
              width: 24px;
              height: 24px;
              border-radius: 6px;

            }
          }
          img{
            margin-right: 10px;
          }


        }
      }
    }
  }
  .consult{
    background: $white;
    min-width: 238px;
    max-width: 238px;
    height: 263px;
    margin-right: 11px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:hover:before {
      position: absolute;
      pointer-events: none;
      content:"";
      height: 100%;
      width: 100%;
      top:0;
      left:0;
      border-radius: 14px;
      background: #09323A03;
    }
    .top{
      width: 188px;
      margin: 25px;
      display: flex;
      align-items: center;
      span{
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        color: $blackclassic;
        opacity: 0.8;
        line-height: 18px;
        letter-spacing: 0;
      }
      img{
        margin-right: 12px;
      }
    }
    .middle{
      width: 188px;
      height: 136px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .title{
        font-weight: bold;
        font-style: normal;
        color: $black;
        letter-spacing: 0;
        font-size: 15px;
      }
      .body{
      //  width: calc(100% - 54px);
      //  padding: 25px 27px;

          line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        font-weight: 500;
        font-style: normal;
        opacity: 0.8;
        color: $blackclassic;
        letter-spacing: 0;
        font-size: 14px;
        margin-top: 15px;
        .ql-editor{
          margin: 0;
          padding: 0;
          overflow: hidden;
        }
      }
    }
    .bottom{
      width: calc(100% - 50px);
      padding: 0 25px;
      height: 58px;
      border-top: 1px solid $chatine;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      .authors{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        img{
          margin-left: -18px;
          border: 2px solid $white;
          width: 24px;
          height: 24px;
          border-radius: 6px;

        }
      }
      img{
        margin-right: 10px;
      }


    }
    >img{
      margin-left: 15px;
    }
    .blueButton{
      width: 171px;
      height: 46px;
      margin-top: 22px;
      padding: 0;
      justify-content: flex-start;
      .whiteCircle{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 25px;
        margin: 10px;
        width: 26px;
        height: 26px;
      }
    }
    &:first-child{
      margin-left: 20px;
    }
    &:last-child{
      margin-right: 20px;
    }
  }
  .more{
    margin-bottom: 45px;
    margin-left: 60px;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: $black;
    letter-spacing: 0;
  }

}

.viewConsult.fullconsult{
  min-width: 520px;
  max-width: 520px;
  display: inline-grid;
  height: fit-content;
  margin-bottom: 35px;
  overflow: hidden;
  bottom: auto;
  background: $white;
  box-shadow: 2px 8px 10px 0 rgba(206, 213, 220, 0.20);
  border-radius: 10px;
  position: relative;
  &:hover:before {
    position: absolute;
    pointer-events: none;
    content:"";
    height: 100%;
    width: 100%;
    top:0;
    left:0;
    border-radius: 14px;
    background: #09323A04;
  }
  .body {
    .description{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
