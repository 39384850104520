@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/Gilroy-Medium/font.woff2') format('woff2'), url('/fonts/Gilroy-Medium/font.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: bold;
  font-style: normal;
  src: url('/fonts/Gilroy-Bold/font.woff2') format('woff2'), url('/fonts/Gilroy-Bold/font.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/Gilroy-SemiBold/font.woff2') format('woff2'), url('/fonts/Gilroy-SemiBold/font.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Gilroy-Regular/font.woff2') format('woff2'), url('/fonts/Gilroy-Regular/font.woff') format('woff');
}
.appleButton{
  overflow: hidden;
  border-radius: 22px;
  cursor: pointer;
  margin-left: 10px;
}  

input:focus, textarea:focus, select:focus{
  outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@keyframes jump {
  0% { transform: scaleY(.8); }
  100% { transform: translateY(-30px); }
}

@keyframes shrink {
  100% { transform: scaleX(.5); opacity: .01; }
}
.onlymobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(/images/landing/Pattern2.png);
  background-repeat: no-repeat;
  background-position: top right;
  height: 100vh;
  width: 100vw;
  .pattern{
    position: absolute;
    top: 25px;
    left: 25px;
    height: 60px;
  }
  .desktop{
    width: 90vw;
    margin-bottom: 40px;
    max-width: 500px;
  }
  .title {
    margin-bottom: 20px;
    margin-top: 0px;
    color: #09323A;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    line-height: 34px;
    text-align: center;
    width: 80vw;
  }
  .description {
    margin-top: 0px;
    color: #09323a73;
    line-height: 30px;
    max-width: 300px;
    letter-spacing: 0;
    font-size: 15px;
    text-align: center;
    font-weight: normal;
    font-style: normal;
    width: 80vw;
  }
}
.loadmore{
  min-height: 80px;
  max-height: 80px;
  width: 100%;
  display: flex;
}
.processingbar{
  width:180px;
  height:2px;
  .line{
    position: absolute;
    background:$greenclassic3;
    width:180px;
    height:2px;
  }
  .break{
    position: absolute;
    background:$white;
    width:6px;
    height:2px;
  }

  .dot1{ animation: loading 2s infinite;}
  .dot2{ animation: loading 2s 0.5s infinite; }
  .dot3{ animation: loading 2s 1s infinite; }

  @keyframes loading {
   from { margin-left: 0px; }
   to { margin-left: 180px; }
  }
}
.googlebutton{
    width: 300px;
    height: 44px;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 20px;
    border-radius: 22px!important;
    border: 1px solid #d5e1e4d9 !important;
    background: #ffffff;
    box-shadow: none!important;
    &:hover{
      background: $shadow!important;
    }
    div{
      background: transparent!important;
      margin-right: 10px!important;
      margin-top: 3px!important;
      margin-left: 2px!important;
    }
    span{
      font-family: 'Gilroy';
      font-weight: 600;
      font-size: 15px;
    }
    &::before{
      position: absolute;
      content: 'OR SIGN IN WITH';
      z-index: 1;
      text-align: center;
      color: #547176b3;
      left: 0;
      right: 0;
      margin: auto;
      margin-top: -107px;
      width: 160px;
      height: 20px;
      background: white;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.5538462px;

    }
    &::after{
      position: absolute;
      content: '';
      z-index: 0;
      border-top: 1px solid #547176b3;
      width: 300px;
      left: 0;
      right: 0;
      margin: auto;
      margin-top: -114px;
      height: 1px;
      background: white;
    }
}


.loader {
  width: 60px;
  height: 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  .ball {
    width: 10px;
    position: relative;
    display: inline-block;
    margin: 2px;
    height: 10px;
    border-radius: 50%;
    z-index: 9;
    &.ball-one {
      background-color: $yellowclassic;
      animation: jump .5s ease 0s infinite alternate;
    }
    &.ball-two {
      background-color: $redclassic;
      animation: jump .5s ease .15s infinite alternate;
    }
    &.ball-three {
      background-color: $greenclassic;
      animation: jump .5s ease .25s infinite alternate;
    }
    &.ball-four {
      background-color: $blueclassic;
      animation: jump .5s ease .35s infinite alternate;
    }
  }
  .shadow {
    position: relative;
    opacity: .1;
    bottom: 10px;
    width: 10px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    display: inline-block;
    margin: 2px;
    &.shadow-one {
        animation: shrink .5s ease .0s infinite alternate;
    }
    &.shadow-two {
        animation: shrink .5s ease .15s infinite alternate;
    }
    &.shadow-three {
        animation: shrink .5s ease .25s infinite alternate;
    }
    &.shadow-four {
        animation: shrink .5s ease .35s infinite alternate;
    }
  }
}


.loadingit{
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  align-items: center;
  .loading-bar{
    height: 3px;
    width: 120px;
    background: #e8e8e8;
    margin-top: 30px;
    .blue-bar {
      width: 100%;
      height: inherit;
      background: linear-gradient(90deg, rgba(252,181,4,1) 0%, rgba(252,181,4,1) 25%, rgba(253,63,103,1) 25%, rgba(253,63,103,1) 50%, rgba(10,201,239,1) 50%, rgba(10,201,239,1) 75%, rgba(5,214,162,1) 75%, rgba(5,214,162,1) 100%);
      animation: initial-loading 2s ease-in-out infinite;
    }
  }
}
@keyframes initial-loading{
  0%{
    transform-origin: left;
    transform: scalex(0.3);
  }
  //12.5%{
    //transform-origin: left;
    //transform: scalex(0.2);
  //}
  25%{
    transform-origin: left;
    transform: scalex(1);
  }
  26%{
    transform-origin: right;
    transform: scalex(1);
  }
  50%{
    transform-origin: right;
    transform: scalex(0.3);
  }
  //62.5%{
    //transform-origin: right;
    //transform: scalex(0.2);
  //}
  75%{
    transform-origin: right;
    transform: scalex(1);
  }
  76%{
    transform-origin: left;
    transform: scalex(1);
  }
  100%{
    transform-origin: left;
    transform: scalex(0.3);
  }
}

.ql-toolbar{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
}
.dashboard .ql-editor.ql-blank::before{
  font-style: normal;

}
.dashboard .ql-editor{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  margin-left: -15px;
  &::before {
    margin-left: -15px;
    font-size: 15px;
    opacity: 0.5;
    font-weight: 500;
    font-style: normal;
  }
  &.ql-bank::before{
    font-style: normal;
  }

}
.ql-snow{
  display: flex;
  justify-content: center;
  img{
    border-radius: 16px;
  }
}
.emoticons {
  width: 100%;
  .emoji-mart-search input{

    font-size: 14px;
    color: #09323a99;
    letter-spacing: 0.38px;
    border: 1px solid transparent;
    background: #F7F9FB;
    border-radius: 21px;
    height: 42px;
    width: calc(100% - 30px);
    margin: 15px;
    padding: 15px;
    padding-left: 45px;
  }
  .emoji-mart-search-icon {
    top: 13px;
    left: 32px;
    opacity: 0.4;
  }

  .emoji-mart.emoji-mart-light .emoji-mart-category-list{
    max-width: 328px;
    overflow: hidden;
  }
  .emoji-mart-anchor{
    opacity: 0.5;
  }
}
.informationbox{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  top: 10px;
  position: absolute;
  right: 10px;
  color: $standardborder;
  border: 1px solid $standardborder;
  padding: 3px;
  border-radius: 17px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover  .terms{
    display: flex;
  }
  .terms{
    color: $black;
    width: 256px;
    right: 25px;
    top: 25px;
    text-align: center;
    display: none;
    position: absolute;
    background: $white;
    border: 0.5px solid $standardborder;
    box-shadow: 5px 20px 35px 10px $shadowmodal;
    padding: 10px;
    border-radius: 10px;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

}
.progressRing{
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    circle{
      //transition: 0.9s;

    }
  }
}
.livenoti{
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 20px;
  left: 110px;
  z-index: 10;
  width: fit-content;
  height: fit-content;
  .notification {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    width: 290px;
    padding: 20px;
    margin-top: 20px;
    border-radius: 16px;
    background: #09323Ad9;
    font-weight: 600;
    font-style: normal;
    color: $white;
    backdrop-filter: blur(2px);
    /*
    &.light{
      background: $white;
      box-shadow: 5px 20px 35px 10px $shadowmodal;
      color: $black;
      padding: 15px 0;
      .header .name, .header .date{
        color: $black;
      }
      img.close{
        background: rgba(0, 0, 0, 0.2);
      }
    }*/
    img{
      width: 40px;
      height: 40px;
      border-radius: 10px;
      margin-right: 15px;
    }
    .text{
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFFB0;
      letter-spacing: 0.16px;
      line-height: 20px;
      .strong{
        color: #FFFFFF;
        font-weight: bold;
      }

    }
    .buttons{
      display: flex;
      margin-top: 20px;
      width: 100%;
      justify-content: space-between;
      > div{
        border: 0px solid;
        width: 50%;
        &.greenButton{
          margin-right: 10px;
        }
      }
    }

    /*
    .header{
      height: 40px;
      width: 250px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      .name{
        color: $white;
        min-width: 150px;
        max-width: 150px;
        margin-left: -20px;
        overflow: hidden;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        letter-spacing: 0.17px;
        max-height: 20px;
        overflow: hidden;
      }
      .date{
        max-height: 15px;
        max-width: 150px;
        margin-left: -20px;
        overflow: hidden;
        color: $white;
        opacity: 0.6;
        font-weight: 500;
        font-style: normal;
        font-size: 13px;
        letter-spacing: 0.15px;
      }
    }
    .body{
      padding: 0 20px 20px 20px;
      width: calc(100% - 40px);
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      height: 12px;
      padding: 8px;
      cursor: pointer;
    }
    */
  }
}
.errors{
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 250px;
  z-index: 10;
  > div{
    display: flex;
    align-items: center;
    width: 290px;
    max-height: 180px;
    margin-top: 20px;
    border-radius: 16px;
    background: #09323AA6;
    font-weight: 600;
    font-style: normal;
    color: $white;
    position: relative;
    img{
      position: absolute;
      right: 20px;
      top: 20px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      height: 12px;
      padding: 8px;
      cursor: pointer;
    }
    div{
      margin: 20px;
      margin-right: 60px;
    }
  }
}
.topand{
  position: relative;
  &::after{
    content: 'AND';
    z-index: 0;
    position: absolute;
    text-align: center;
    color: $blackclassic;
    top: -10px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 35px;
    height: 20px;
    background: white;
    font-weight: 800;
    font-style: normal;
    font-size: 14px;
  }
}
.topor{
  position: relative;
  &::after{
    content: 'OR';
    z-index: 0;
    position: absolute;
    text-align: center;
    color: $blackclassic;
    top: -10px;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 35px;
    height: 20px;
    background: white;
    font-weight: 800;
    font-style: normal;
    font-size: 14px;
  }
}
.jjaa{
  opacity: 0.2;
}
.gothrough{
  //position: absolute;
  z-index: 20;
  background: rgba(9,50,58,0.85);
//  box-shadow: 4px 15px 20px 10px rgba(206,213,220,0.50);
  border-radius: 16px;
  width: 230px;
  height: 286px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  .top{
    background-image: url(/images/dark/Gothrough.svg);
    background-repeat: no-repeat;
    background-position: center 5px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 160px;
    img{
      height: 50px;
    }
  }
  .title{
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.16px;
    text-align: center;
    line-height: 18px;
  }
  .description{
    opacity: 0.55;
    font-weight: 500;
    margin-top: 5px;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 21px;
  }

  .darkButton{
    padding: 0;
    width: 94px;
    background: transparent;
    border: 1px solid rgba(255,255,255,0.25);
    border-radius: 24px;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
    &:hover{
      background: rgba(255,255,255,0.10);
      border: 1px solid rgba(255,255,255,0.25);
      border-radius: 24px;
    }
  }
}

.leftBar div.gothroughfreelancer{
  position: fixed;
  min-height: 645px;
  max-height: 645px;
  min-width: 665px;
  max-width: 665px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #DBE3E5;
  box-shadow: 4px 10px 15px 0 rgba(206,213,220,0.25);
  border-radius: 16px;
  cursor: default;
  /*
  &::before{
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFFFFFB0;
  }
  */
  .close{
    position: absolute;
    top: 30px;
    right: 30px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid $standardborder;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
      background: $shadow;
    }
    img{
      width: 12px;
    }
  }
  span.title{
    margin-top: 50px;
    font-weight: bold;
    color: $blackclassic;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    font-size: 20px;
    .color{
      margin: 14px;
      font-size: 24px;
      color: #0AC9EF;
    }
  }
  .blueButton{
    min-height: 46px;
    max-height: 46px;
  }
  span.subtitle{
    margin-top: auto;
    font-weight: bold;
    font-size: 20px;
    color: #47656B;
    letter-spacing: 0;
    text-align: center;
    line-height: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid #FD8048;
  }
  span.description{
    opacity: 0.9;
    font-size: 15px;
    color: #47656B;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
    margin-top: 25px;
    max-width: 520px;
    margin-bottom: 45px;
  }
  .design2{
    width: 394px;
    height: 260px;
  }

  .design{
    margin-bottom: 76px;
    height: 150px;
    width: 450px;
    position: relative;
    background-image: url(/images/background/DesignConnection.svg);
    background-position: 50% 62%;
    background-repeat: no-repeat;
    div{
      position: absolute;
      display: flex;
      flex-direction: column;
      img.circle{
        height: 50px;
        width: 50px;
        border-radius: 35px;
      }
      img.check{
        right: -5px;
        bottom: -5px;
        position: absolute;
        width: 17px;
        height: 12px;
        padding: 6.5px 4px;
        border-radius: 14px;
        background: $greenclassic;

      }

      span{
        position: absolute;
        bottom: -25px;
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        color: $blackclassic;
        text-align: center;
        line-height: 15px;
        left: 0;
        right: 0;
      }
      &.myself{
        bottom: 15px;
        left: 15px;
        .circle{
          border: 8px solid #E7FBFF;
        }

      }
      &.edworking{
        margin: auto;
        width: fit-content;
        left: 0;
        right: 0;
        top: 15px;

        img{
          width: 109px;
          height: 133px;

        }
      }
      &.freelancer{
        bottom: 15px;
        right: 15px;
        .circle{
          border: 8px solid #F2F7F8;
          &.green{
            border: 8px solid #E7FBFF;
          }


        //  box-shadow: 5px 10px 25px 0 rgba(5, 214, 119, 0.25);
        }
        img.check{
          width: 17px;
          height: 12px;
          padding: 6.5px 4px;
          background: $yellowclassic;
          &.green{
            background: $greenclassic;
          }
        }
      }
    }

  }

  .footer{
    display: flex;
    align-items: center;
    bottom: 36px;
    height: 106px;
    min-height: 106px;
    width: calc(100% - 50px);
    padding: 0 30px;
    justify-content: space-between;
    border-top: 0.5px solid rgba(154,175,180,0.30);
    .steps{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span{
        font-weight: 600;
        font-style: normal;
        font-size: 15px;
        letter-spacing: 0;
        margin-bottom: 9px;
      }
      .boxes{
        display: flex;
        justify-content: space-between;
        div{
          margin: 5px;
          width: 18px;
          height: 5px;
          border-radius: 3.5px;
          &.boxblue{
            background: #FD8048;
          }
          &.boxgray{
            opacity: 0.20;
            background: $footerline;
          }
        }
      }
    }

    .blueButton {
      width: 124px;
      height: 50px;
    }
  }

}

.modal .registration {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 845px;
  height: 558px;
  background: #FFFFFF;
  border: 0px solid transparent;
  box-shadow: 4px 10px 15px 0 rgba(206,213,220,0.25);
  border-radius: 16px;
  flex-direction: row;
  .close{
    position: absolute;
    right: 40px;
    z-index: 10;
    top: 40px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    background: #FFFFFF;
    border: 1px solid #E8ECED;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:before{
      content: '';
      position: absolute;
      left: -20px;
      right: -20px;
      top: -20px;
      bottom: -20px;

    }
    &:hover{
      background: $shadow;
    }
  }
  .left{
    background: rgba(10,201,239,0.05);
    border-radius: 16px 0 0 16px;
    min-width: 261px;
    width: 261px;
    min-height: calc(100% - 194px);
    padding: 92px 69px 102px 73px;
    img{
      width: 212px;
      margin-bottom: 60px;
    }
    .title{
      font-weight: bold;
      font-size: 36px;
      color: #0AC9EF;
      letter-spacing: 0;
      line-height: 29px;
    }
    .description{
      font-weight: bold;
      margin-top: 20px;
      font-size: 24px;
      color: #2C525A;
      letter-spacing: 0;
      line-height: 29px;
    }
  }

  .right{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-direction: column;
    img{
      width: 220px;
    }
    .title{
      margin-top: -30px;
      opacity: 0.85;
      font-size: 14px;
      color: #2C5A63;
      letter-spacing: 0;
      text-align: center;
      line-height: 22px;
      max-width: 167px;
    }
    .textInput{
      margin: -30px 0 20px 0;
      width: 300px;
    }
    .blueButton{
      width: 250px;
    }

  }
}
