.mainScreen div.info-widget.widget{
  background: $white;
  width: calc(100% - 100px);
  border-radius: 16px;
  padding: 25px;
  box-shadow: 2px 4px 3px 0 rgba(206, 213, 220, 0.1);
  > div{
    //min-width: 1025px;
  }
  .welcome{
    left: 0;
    position: sticky;
    display: flex;
    flex-direction: column;
    min-height: 235px;
    background: #F8FAFB;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url(/images/collages/Ornament.svg);
    background-position: calc(100% - 40px) 50%;
    background-repeat: no-repeat;
    .title{
      margin: 0 60px;
      font-size: 40px;
      font-weight: bold;
      color: #09323A;
      letter-spacing: 0;
      margin-bottom: 20px;
    }
    .subtitle{
      margin: 0 60px;
      font-weight: 600;
      font-size: 20px;
      color: #09323A;
      letter-spacing: 0;
    }
  }
  .income{
    left: 0;
    position: sticky;
    border: 1px solid #E8ECED;
    border-radius: 10px;
    min-height: 316px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    .title{
      font-weight: bold;
      font-size: 18px;
      color: $black;
    }
    .graph{
      margin: 20px 15px 0 50px;
      .bars{
        margin: 40px 5px 0 5px;
        position: absolute;
        height: 200px;
        width: 877px;
        display: flex;
        justify-content: space-between;
        .bar{
          position: relative;
          width: 12px;
          border-radius: 12px;
          z-index: 2;
          background: #CDD6D7;
          cursor: pointer;
          &:hover{
            background: #2E5057;
          }
          &:hover > .box{
            display: flex;
          }
          .box{
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 14px 20px 15px;
            height: 32px;
            width: 61px;
            background: $black;
            border-radius: 8px;
            box-shadow: 4px 5px 10px 2px rgba(206, 213, 220, 0.20);
            position: absolute;
            top: -75px;
            left: -38px;
            span.date{
              font-weight: 400;
              opacity: 0.6;
              color: $white;
              letter-spacing: 0.12px;
              font-size: 12px;
            }
            span.money{
              font-weight: bold;
              color: $white;
              letter-spacing: 0.15px;
              font-size: 15px;
            }
          }
        }
      }

      .grid{
        position: relative;
        width: 100%;
        height: 39px;
        border-bottom: 1px dashed #C2CCCE;
        &::before{
          content: attr(grid-data);
          color: #09323A;
          opacity: 0.4;
          letter-spacing: 1px;
          font-size: 14px;
          left: -45px;
          bottom: -7px;
          position: absolute;
        }
      }
      .xaxis{
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        span{
          color: #09323A;
          opacity: 0.4;
          letter-spacing: 1px;
          font-size: 14px;
        }
      }

    }









  }
  .row-wids{
    margin: 20px 0;
    display: flex;
    min-width: 1025px;
    padding-right: 25px;
    .wid{
      margin-right: 20px;
      height: 118px;
      min-width: 239px;
      background: #FFFFFF;
      border: 1px solid #E8ECED;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:last-child{
        margin-right: 0;
      }
      img {
        margin-right: 12px;
      }
      span.number {
        margin-left: 35px;
        font-weight: bold;
        font-size: 36px;
        color: #09323A;
        letter-spacing: 0;
        margin-bottom: 17px;
      }
      span.name {
        align-items: center;
        display: flex;
        margin-left: 35px;
        font-weight: 500;
        opacity: 0.5;
        font-size: 14px;
        color: #09323A;
        letter-spacing: 0;
      }
    }

  }

}
