.mainScreen .meets-widget{
  width: 40%;
  height: 411px;
  margin-bottom: 25px;
  div.body{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 25px);
    margin: 0 25px 25px 25px;
    border: 1px solid $standardborder;
    border-radius: 10px;
    img{
      margin-left: 15px;
    }
    .blueButton{
      margin-top: 20px;
      width: 182px;
      height: 46px;
    }
  }
}
div.meetingroomscreen{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #21464E;
  .turnsound{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(242,246,247,0.40);
    border-radius: 25px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 40px;
    right: 40px;
    &:hover:before {
      position: absolute;
      content:"";
      height: 100%;
      width: 100%;
      top:0;
      left:0;
      background: #09323A40;
    }

  }
  > div.preview{
    display: flex;
    max-height: 900px;
    max-width: 1366px;
    background: $black;
    box-shadow: 10px 35px 75px 0 rgba(5,32,38,0.30);
    border-radius: 16px;
    overflow: hidden;
    .header{
      height: fit-content;
    }
    &.preview{
      .column{
        display: flex;
        justify-content: space-between;
        background: $black;
        z-index: 10;
        flex-direction: column;
        background: #082c33bf;
        padding: 80px 0 80px 0;
        &:first-child{
          align-items: center;
          background: #09323A;
          width: 663px;
        }
        &:last-child{
          max-width: 545px;
          padding: 80px;

        }
        > .blueButton{
          margin-top: 40px;
        }
        .violetButton {
          border: 1px solid transparent;
          left: 10%;
        }
        &.dark .buttons{
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-bottom: 30px;
          .darkButton{
            border: 1px solid transparent;
          }
          .darkButton{
            margin: 0 20px;
          }
        }
        &.dark .userslist {
          background: transparent;
          border-top: 1px solid transparent;
          margin: 0px;
          width: 100%;
          .userslistheader{
            height: 0;
            margin-bottom: 47px;
            span {
              color: $white;
              opacity: 0.7;
            }
            input{
              background: rgba(255,255,255,0.20);
              border: 1px solid transparent;
              opacity: 0.5;
              color: $white;
              &::placeholder{
                color: $white;
                letter-spacing: 0;
              }
            }
          }
          .list{
            margin-bottom: 35px;
            max-width: 565px;
            min-width: 565px;
            flex-wrap: wrap;
            max-height: 260px;
          }
          .user{
            background: #2C4E55;
            background: #2C4E55;
            width: 99px;
            height: 118px;
            margin: 0 10px 10px 0;
            border: 1px solid transparent;
            &:hover{
              background: #2C4E55;
            }
            &:hover:before {
              position: absolute;
              content:"";
              height: 100%;
              width: 100%;
              top:0;
              left:0;
              background: #09323A40;
            }
            &:first-child{
              margin-left: 0px;
            }
            &:last-child{
              margin-right: 0px;
            }
            &.included{
              border: 1px solid #ffffffb3;
              box-shadow: 2px 4px 7px 0 transparent;
            }
            span{
              color: $white;
            }
          }
        }
        &.dark .buttons {

        }

        &.dark .invitationList {
          border-top: 1px solid #e8eced33;
          border-bottom: 1px solid #e8eced33;
          width: 100%;
          margin: 0px;
          .invite{
            input{
              background: #2C4E55;
              border: 1px solid transparent;
              color: #ffffff80;
              &:hover{
                box-shadow: 0 0 0 2px rgba(213, 225, 228, 0.2);
              }
              &::placeholder{
                color: #ffffff80;
                letter-spacing: 0;
              }
            }
            .whiteButton{
              margin-left: 20px;
              background: rgba(255,255,255,0.10);
              border: 1px solid transparent;
              color: #ffffffb3;
              font-size: 15px;
              font-weight: bold;
              &::placeholder{
                color: $white;
                letter-spacing: 0;
              }
            }

          }
          .invitedBox{
            background: #2C4E55;
            border: 1px solid transparent;
            color: $white;
            span{
              color: $white;
            }
            .email{
              background: #1C4046;
              color: $white;
              border: 1px solid transparent;
            }
            &::placeholder{
              color: $white;
              letter-spacing: 0;
            }

          }

        }
      }
      .header{
        min-height: auto;
        width: 284px;
        color: $white;
        font-weight: bold;
        justify-content: center;
        font-style: normal;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 0;
      }
      .subheader{
        text-align: center;
        width: 349px;
        color: $white;
        line-height: 24px;
        opacity: 0.38;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
        font-style: normal;
        margin-top: 16px;
        margin-bottom: 50px;
      }
      video{
        min-height: 375px;
        max-height: 375px;
        object-fit: cover;
        max-width: 532px;
        background-color: $black;
        background-image: url(/images/dark/Videocamera.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70px;
        border: 4px solid #5B777C;
        box-shadow: 8px 25px 35px 0 rgba(5,31,37,0.35);
        border-radius: 6px;
      }
      .buttons{
        display: flex;
        margin: 30px 0 0px 0px;
        .button{
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(242,246,247,0.39);
          border-radius: 25px;
          width: 50px;
          height: 50px;
          margin: 15px;
          &:hover{
            background-color: rgba(255,255,255,0.6);
          }

        }
      }
      .blueButton{
        font-size: 15px;
        color: #FFFFFF;
        letter-spacing: 0;
      }
    }

  }
  &.live{
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    z-index: 10;
    border-radius: 0px;/*16px;*/
    flex-direction: column;
    overflow: hidden;
    .naranjito{
      min-width: 100vw;
      height: 100vh;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .explanation{
      position: absolute;
      z-index: 4;
      width: 300px;
      height: 446px;
      border-radius: 26px;
      right: 50px;
      bottom: 40px;
      background: $black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 20px 0;
      .points{
        display: flex;
        align-items: center;
        .point{
          height: 8px;
          width: 8px;
          margin: 5px;
          cursor: pointer;
          border-radius: 4px;
          &.filled{
            background: #ADC4C8;
          }
          &.unfilled{
            height: 6px;
            width: 6px;
            border: 1px solid #ADC4C8;
          }
        }
      }
      img{
        height: 150px;
      }
      span.title{
        font-weight: bold;
        font-size: 18px;
        color: $white;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 20px;
        max-width: 200px;
      }
      span.description{
        max-width: 215px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #849699;
        text-align: center;
        margin-bottom: 24px;
      }

    }

    .header {
      position: absolute;
      width: 100%;
      min-width: 100vw;
      border-radius: 0;
      height: 80px;
      backdrop-filter: blur(20px);
      text-align: center;
      background: #09323ad9;
      z-index: 2;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      .name{
        display: flex;
        flex-direction: column;
        img{
          width: 13px;
          height: 14px;
          margin-right: 12px;
        }
        span{
          display: flex;
          align-items: flex-start;
          &:first-child{
            font-weight: bold;
            font-size: 14px;
            color: $white;
            letter-spacing: 0;
            margin-bottom: 2px;
          }
          &:last-child{
            opacity: 0.5;
            font-size: 12px;
            font-weight: 500;
            color: $white;
            letter-spacing: 0;
          }
        }
      }
      .darkButton{
        border: 1px solid transparent;
        margin-left: auto;
        margin-right: 19px;
      }
      .darkButton.type1{
        background: #09323Ad9;
        border: 1px solid transparent;
        margin-left: auto;
        margin-right: 19px;
        &.unclickable{
          cursor: default;
        }
        img{
          margin-right: 12px;
          width: 16px;
          height: 16px;
          -webkit-animation: rotating 1s linear infinite;
          -moz-animation: rotating 1s linear infinite;
          -ms-animation: rotating 1s linear infinite;
          -o-animation: rotating 1s linear infinite;
          animation: rotating 1s linear infinite;

        }
      }

      span.title{
        color: $white;
        width: fit-content;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
      }

      img.logo{
        margin-left: 21px;
        margin-right: 22px;
        height: 22px;
      }
      .blueButton{
        margin-right: -30%;
      }
      .chat{
        display: flex;
        align-items: center;
        width: 186px;
        z-index: 1;
        cursor: pointer;
        border-left: 1px solid rgba(154, 175, 180, 0.36);
        img:first-child{
          margin-left: 25px;
          margin-right: 16px;
        }
        img:last-child{
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 30px;
        }
        span{
          color: $white;
          font-size: 15px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 500;
          font-style: normal;
        }
      }
    }
    > .chat{
      top: 80px;
      backdrop-filter: blur(20px);
      border-top: 1px solid rgba(154, 175, 180, 0.36);
      height: calc(100% - 80px);
      width: 386px;
      max-height: 100vh;
      background: #093139d9;
      z-index: 1;
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      animation: 0.3s ease-out 0s 1 slideInFromLeft;
      .break{
        height: 15px;
        width: 100%;
      }
      @keyframes slideInFromLeft {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(0);
        }
      }

      .inputField{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        min-height: 70px;
        border-top: 1px solid rgba(154, 175, 180, 0.36);
        .emoticons{
          position: absolute;
          bottom: 90px;
          right: 0px;
          overflow: hidden;
          z-index: 2;
          .emoji-mart.emoji-mart-dark{
            width: 100%;
            height: 100%;
          }
          .emoji-mart-preview{
            display: none;
          }
          .emoji-mart-scroll{
            padding: 0px;
          }
          .emoji-mart .emoji-mart-emoji{
            cursor: pointer;
          }
          .emoji-mart.emoji-mart-dark{
            width: 100%;
            height: 100%;
            background: $black;
            border-radius: 16px 16px 0 0;
            .emoji-mart-category-list{
              margin: 0 34px;
            }
          }
          .emoji-mart-category .emoji-mart-emoji:hover:before{
            opacity: 0.8;
            background: #EDF1F3;
            border-radius: 8px;
          }
          .emoji-mart-preview{
            display: none;
          }
          .emoji-mart-dark .emoji-mart-search input{
            background: $black;

          }
          .emoji-mart-dark .emoji-mart-category-label span{
            background: #FFFFFF20;
            color: $white;
          }
          .emoji-mart-category .emoji-mart-emoji span{
            width: 30px;
            height: 30px;
          }
          .emoji-mart-category-label span{
            opacity: 0.55;
            text-transform: uppercase;
            padding-left: 39px;
            font-family: Helvetica;
            font-size: 12px;
            color: #47656B;
            letter-spacing: 0;
          }

        }
        .complexinput{
          width: 100%;
          resize: none;
          border: 0;
          padding-right: 85px;
          margin: 27px 8px 10px 25px;
          background: transparent;
          font-size: 14px;
          color: $white;
          letter-spacing: 0;
          font-weight: 500;
          line-height: 24px;
          &::placeholder{
            color: $white;
            background: transparent;
            border: transparent;
            font-size: 15px;
            letter-spacing: 0;
            font-weight: 500;
            font-style: normal;
          }
        }
        img{
          cursor: pointer;
          &.smile{
            position: absolute;
            right: 65px;
          }
          &.send{
            position: absolute;
            right: 25px;
          }
        }
      }
      .message{
        display: flex;
        font-size: 15px;
        letter-spacing: 0;
        font-weight: 600;
        font-style: normal;
        line-height: 18px;
        align-items: center;
        position: relative;
        color: $white;
        &:first-child:after{
            position: absolute;
            content:"";
            bottom: -15px;
            height: 15px;
            width: 100%;
            background-image: transparent;
        }
        &:last-child:after{
            position: absolute;
            content:"";
            top: -15px;
            height: 15px;
            width: 100%;
            background-image: transparent;
        }
        &.received {
          display: flex;
          justify-content: flex-start;
          margin: 2px 90px 2px 0;
          height: fit-content;
          align-items: flex-end;
          width: fit-content;
          > img{
            margin: 0 8px 0px 15px;
            height: 34px;
            width: 34px;
            border-radius: 10px;
          }
          &.last .text {
            border-top-left-radius: 2px;
          }
          &.middle .text {
            margin-left: 57px;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
          &.first .text {
            margin-left: 57px;
            border-bottom-left-radius: 2px;
          }
          .text {
            color: $white;
            background: $darkclassic;
            font-weight: 500;
            font-style: normal;
            .time{
              color: $blackclassic;
            }
            .name{
              color: $grayclassic2;
              font-weight: bold;
              font-style: normal;
              margin-bottom: 6px;
            }
          }
          .time{
            position: absolute;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            color: $white;
            letter-spacing: 0;
            bottom: 10px;
            right: 10px;
            opacity: 0.8;
          }
        }

        &.sent{
          justify-content: flex-end;
          margin: 2px 15px 2px 90px;
          &.last .text, .file, .image {
            border-top-right-radius: 2px;
          }
          &.middle .text, .file, .image {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
          &.first .text {
            border-bottom-right-radius: 2px;
          }
          .text {
            color: $white;
            background: $darkclassic;
          }
          .time{
            position: absolute;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            color: $blackclassic;
            letter-spacing: 0;
            bottom: 10px;
            right: 10px;
          }
        }
        .text, .file {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          padding: 11px 50px 9px 15px;
          color: $blackclassic;
          position: relative;
          border-radius: 10px;
          max-width: 90%;
          word-break: break-word;
        }
        .name {
          margin-bottom: 6px;
          font-weight: 600;
          font-size: 14px;
          margin-left: 19px;
        }
      }
    }
    .media{
      margin: auto;
      left: 0;
      right: 0;
      bottom: 185px;
      position: absolute;
      //height: 134px;
      width: 90vw;
      display: flex;
      align-items: center;
      justify-content: center;
      .remoteMedia{
        margin: 10px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        video{
          cursor: pointer;
          border-radius: 6px;
          border: 4px solid #ffffff33;
          background-image: url(/images/basic/Speaker.svg);
          height: 100px;
          width: 100px;
          min-height: 100px;
          min-width: 100px;
          object-fit: cover;

        }
        span{
          color: $white;
          font-weight: bold;
          font-style: normal;
          margin-top: 20px;
          opacity: 0.6;
          font-size: 13px;
          letter-spacing: 0.15px;
        }
        &:first-child{
          cursor: default;
        }
      }
      .remoteMedia:last-child {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 0;
        margin: 0;
        background: black;
        &:after {
          position: absolute;
          content:"";
          height: 100%;
          width: 100%;
          top:0;
          left:0;
          background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.35) 100%);
        }
        video{
          border-radius: 0px;
          border: 0px;
          height: 100vh;
          width: 100vw;
          background: black;
          object-fit: contain;
          cursor: default;

        }
        span{
          display: none;
        }
      }

    }
    > .buttons{
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 85px;
      background: transparent;
      margin: auto;
      width: fit-content;
      box-shadow: 0px 0px 0px 0px white;
      .button{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(242,246,247,0.39);
        border-radius: 25px;
        width: 50px;
        height: 50px;
        margin: 15px;
        &.chat{
          position: fixed;
          right: 85px;
        }
        &.red{
          background: #E12D4B;
        }
        &:hover{
          background-color: rgba(255,255,255,0.6);
        }
      }
    }
    .blueButton{
      font-size: 15px;
      color: #FFFFFF;
      letter-spacing: 0;
    }
  }
  @keyframes mymove {
    from {left: 0px;}
    to {left: 300px;}
  }

  .block{
    animation: mymove 5s infinite;

  }
  .outcircle{
    position: absolute;
    z-index: 9;
    border-radius: 131px;
    transition: 1s;
    &.blue{
      left: 120px;
      top: 107px;
      width: 234px;
      height: 234px;
      .incircle{
        background-color: #FDBB17;
        width: 206px;
        height: 206px;
        border: 14px solid $blue;
        animation: figure1 1s infinite;
        animation-direction: alternate;
        .patch{
          background-color: #FDBB17;
          right: 0;
          bottom: 0;
        }
      }
      .stripes{
        animation: stripesmovement1 1s infinite;
        animation-direction: alternate;
      }
      .dots{
        animation: dotsmovement1 1s infinite;
        animation-direction: alternate;
      }
      .user{
        width: 90%;
        bottom: 0px;
        left: 14px;
      }


    }
    &.red{
      left: 185px;
      bottom: 90px;
      height: 192px;
      width: 192px;
      .incircle{
        background-color: $green;
        height: 172px;
        width: 172px;
        border: 10px solid $red;
        animation: figure2 1.5s infinite;
        animation-direction: alternate;
        .patch{
          background-color: $green;
          right: 0;
          top: 0;
        }
      }
      .stripes{
        transform: rotate(60deg);
        animation: stripesmovement2 1.5s infinite;
        animation-direction: alternate;
      }
      .dots{
        animation: dotsmovement2 1.5s infinite;
        animation-direction: alternate;
      }
      .user{
        height: 90%;
        left: 2px;
        right: -2px;
        bottom: 0px;
        margin: auto;
      }


    }
    &.yellow{
      height: 206px;
      width: 206px;
      top: 180px;
      right: 162px;
      .incircle{
        background-color: $red;
        height: 188px;
        width: 188px;
        animation: figure2 1.8s infinite;
        animation-direction: alternate;
        border: 9px solid #FDBB17;
        .patch{
          background-color: $red;
          left: 0;
          bottom: 0;
        }

      }
      .stripes{
        transform: rotate(60deg);
        animation: stripesmovement2 1.8s infinite;
        animation-direction: alternate;
      }
      .dots{
        animation: dotsmovement2 1.8s infinite;
        animation-direction: alternate;
      }
      .user{
        left: 6px;
        bottom: 0px;
        width: 85%;
      }

    }
    &.green{
      height: 154px;
      width: 154px;
      bottom: 85px;
      right: 226px;
      .stripes{
        animation: stripesmovement1 1.2s infinite;
        animation-direction: alternate;
      }
      .dots{
        animation: dotsmovement1 1.2s infinite;
        animation-direction: alternate;
      }
      .user{
        width: 78%;
        left: 9px;
        bottom: 0px;
        margin: auto;
      }
      .incircle{
        background-color: $blue;
        height: 134px;
        width: 134px;
        animation: figure1 1.2s infinite;
        animation-direction: alternate;
        border: 10px solid $green;
        .patch{
          background-color: $blue;
          left: 0;
          top: 0;
        }
      }
    }
    .incircle{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 8;
      margin: auto;
      border-radius: 131px;
      .patch{
        height: 50%;
        width: 50%;
        position: absolute;
        border-radius: 12px;
      }
      /*
      &:before, &:after {
      	content: '';
      	position: absolute;
      	top: -1px;
      	left: -1px;
      	right: 0;
      	bottom: 0;
      }
      &:before {
      	border-radius: 43% 45% 40% 42%;
        animation: wave 10s infinite linear;
      }
      &:after {
      	border-radius: 43% 45% 40% 42%;
        animation: wave 4s infinite linear;
      }
      */
    }
    .user{
      z-index: 49;
      position: absolute;
    }
    .dots{
      z-index: 4;
      position: absolute;
      animation-direction: alternate
    }
    .stripes{
      z-index: 10;
      position: absolute;
      animation-direction: alternate
    }
    @keyframes figure1 {
      from {
        margin-left: 2px;
        margin-right: -2px;
        margin-bottom: -2px;
        margin-top: 2px;
      }
      to {
        margin-left: -2px;
        margin-bottom: 2px;
        margin-right: 2px;
        margin-top: -2px;
      }
    }
    @keyframes figure1i {
      from {
        margin-left: -2px;
        margin-right: 2px;
        margin-bottom: 2px;
        margin-top: -2px;
      }
      to {
        margin-left: 2px;
        margin-bottom: -2px;
        margin-right: -2px;
        margin-top: 2px;
      }
    }
    @keyframes figure2 {
      from {
        margin-left: -2px;
        margin-right: 2px;
        margin-bottom: -2px;
        margin-top: 2px;
      }
      to {
        margin-left: 2px;
        margin-bottom: 2px;
        margin-right: -2px;
        margin-top: -2px;
      }
    }


    @keyframes dotsmovement1 {
      from {
        margin-left: -2px;
        margin-right: 2px;
        margin-bottom: -2px;
        margin-top: 2px;
      }
      to {
        margin-left: 2px;
        margin-bottom: 2px;
        margin-right: -2px;
        margin-top: -2px;
      }
    }

    @keyframes dotsmovement2 {
      from {
        margin-left: -2px;
        margin-right: 2px;
        margin-bottom: 2px;
        margin-top: -2px;
      }
      to {
        margin-left: 2px;
        margin-bottom: -2px;
        margin-right: -2px;
        margin-top: 2px;
      }
    }
    @keyframes stripesmovement1 {
      from {
        margin-left: 2px;
        margin-right: -2px;
        margin-bottom: 2px;
        margin-top: -2px;
      }
      to {
        margin-left: -2px;
        margin-bottom: -2px;
        margin-right: 2px;
        margin-top: 2px;
      }
    }
    @keyframes stripesmovement2 {
      from {
        margin-left: 2px;
        margin-right: -2px;
        margin-bottom: -2px;
        margin-top: 2px;
      }
      to {
        margin-left: -2px;
        margin-bottom: 2px;
        margin-right: 2px;
        margin-top: -2px;
      }
    }
    @keyframes wave {
      0% { transform: rotate(0deg);}
      50% { transform: rotate(180deg);}
      100% { transform: rotate(360deg);}
    }

  }
  .abra{
    display: flex;
    justify-content: center;
    min-width: 50vw;
    z-index: 10;
    border-radius: 26px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(/images/abra/BackgroundMeeting.svg), linear-gradient(-29deg, #06252B 0%, #09323A 61%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 10;
    min-height: 900px;
    min-width: 1366px;
    position: relative;
    .header{
      img{
        margin-right: 12px;
      }
    }
    .logo{
      margin-bottom: 30px;
    }
    .letters{
      margin-bottom: 25px;
      width: 160px;
    }
    .connect{
      font-size: 12px;
      color: #abcbd159;
      letter-spacing: 8px;
      font-weight: 500;
      margin-bottom: 71px;

      background: linear-gradient(to right, #abcbd1b3 20%, #abcbd159 40%, #abcbd159 60%, #abcbd1b3 80%);
      //background: linear-gradient(to right, #09323A 5%, #ADCDD3 40%, #ADCDD3 60%, #09323A 95%);
      background-size: 200% auto;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shine 2s linear infinite;
      //animation-direction: alternate;
      @keyframes shine {
        from {
          background-position: 200% center;
        }
      }

    }
    .violetButton {
      border: 1px solid transparent;
      left: 10%;
      margin-top: 40px;
    }
    .nickname{
      margin-bottom: 40px;
    }
    video{
      &.novideo{
        display: none;
      }
        min-height: 383px;
        max-height: 383px;
        max-width: 540px;
        background-color: $black;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70px;
        border: 4px solid #5B777C;
        box-shadow: 8px 25px 35px 0 rgba(5,31,37,0.35);
        border-radius: 16px;

    }
    .title{
      font-size: 18px;
      color: #FFFFFF;
      letter-spacing: 0;
      font-weight: bold;
      margin-bottom: 12px;
      animation: fadeInAnimation ease 0.8s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      @keyframes fadeInAnimation {
          0% {
            margin-top: 2000px;
          }
          100% {
            margin-top: 0px;
           }
      }

    }
    .subtitle{
      opacity: 0.38;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 50px;
      max-width: 360px;
    }
    .panel{
      display: flex;
      margin: 50px 0;
      .button{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(242,246,247,0.39);
        border-radius: 25px;
        width: 50px;
        height: 50px;
        margin: 15px;
        &:hover{
          background-color: rgba(255,255,255,0.6);
        }

      }
    }
    .blueButton{
      font-size: 15px;
      color: #FFFFFF;
      letter-spacing: 0;
    }
    .nickname {
      width: 400px;
      input{
          background: rgba(255,255,255,0.20);
          border: 1px solid transparent;
          opacity: 0.5;
          color: $white;
          &::placeholder{
            color: $white;
            letter-spacing: 0;
          }
      }
    }
/*    &:first-child{
      align-items: center;
      border-right: 1px solid #e8eced33;

    }*/
    &.dark .buttons{
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 10%;

      .violetButton {
        border: 1px solid transparent;
        left: 10%;
      }
      .darkButton{
        border: 1px solid transparent;

      }
      .darkButton{
        margin: 0 20px;
      }
    }

  }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
